import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpEvent,
  HttpHandler,
  HttpRequest
} from "@angular/common/http";
import { Observable } from "rxjs";
import { LocalStorageService } from "ngx-localstorage";

@Injectable({ providedIn: "root" })
export class HeaderInterceptor implements HttpInterceptor {
  constructor(private ls: LocalStorageService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.ls.get("token");
    const contentType = req.headers.get("Content-Type");
    const authReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
        // "Content-Type": "application/json"
      }
    });
    return next.handle(authReq);
  }
}
