import { BrowserModule } from "@angular/platform-browser";
import { NgModule, NO_ERRORS_SCHEMA, enableProdMode } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./pages/header/header.component";
import { HomeComponent } from "./pages/home/home.component";
import { PlanComponent } from "./pages/plan/plan.component";
import {
  AboutUsComponent,
  TeamMemberDialog
} from "./pages/about-us/about-us.component";
import { ContactUsComponent } from "./pages/contact-us/contact-us.component";
import { ServicesComponent } from "./pages/services/services.component";
import { FooterComponent } from "./pages/footer/footer.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatModule } from "./shared/mat-module";
import { ConsultationComponent } from "./pages/consultation/consultation.component";
import {RegisterComponent,RegTermsDialog } from "./pages/register/register.component";
import { PasswordComponent } from "./pages/password/password.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { CountdownModule } from "ngx-countdown";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { LoginComponent } from "./pages/login/login.component";
import { NgxLocalStorageModule } from "ngx-localstorage";
import { ExternalLibraryService } from "./providers/util";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { CreditCardDetailsComponent } from "./pages/credit-card-details/credit-card-details.component";
import { UserPageComponent } from "./pages/user-page/user-page.component";
import { ScoreTrendComponent } from "./pages/score-trend/score-trend.component";
import { ChartsModule } from "ng2-charts";
import { ScoreFactorComponent } from "./pages/score-factor/score-factor.component";
import { AuthService } from "./providers/auth/auth.service";
import { HeaderInterceptor } from "./providers/http-interceptor/http-interceptor";
import { PersonalProfileComponent } from "./pages/personal-profile/personal-profile.component";
import { UserSidebarComponent } from "./pages/user-sidebar/user-sidebar.component";
import { DatePipe } from "@angular/common";
// import { NgxMaskModule } from 'ngx-mask'

import { SubmitQueryComponent } from "./pages/submit-query/submit-query.component";
import { PaymentHistoryComponent } from "./pages/payment-history/payment-history.component";
import { LoanAccountComponent } from "./pages/loan-account/loan-account.component";
import { ForgotPasswordComponent } from "./pages/forgot-password/forgot-password.component";
import { ChangePasswordComponent } from "./pages/change-password/change-password.component";
import { ResetPasswordComponent } from "./pages/reset-password/reset-password.component";
import { TotalAccountComponent } from "./pages/total-account/total-account.component";
import { CreditHistoryComponent } from "./pages/credit-history/credit-history.component";
import { CreditUtilisationComponent } from "./pages/credit-utilisation/credit-utilisation.component";
import { ScorePaymentHistoryComponent } from "./pages/score-payment-history/score-payment-history.component";
import { DisputeComponent } from "./pages/dispute/dispute.component";
import { AdvisoryPageComponent } from "./pages/advisory-page/advisory-page.component";
import { AdvisorySidebarComponent } from "./pages/advisory-sidebar/advisory-sidebar.component";
import { AdvisoryAllCustomersComponent } from "./pages/advisory-all-customers/advisory-all-customers.component";
import { AdvisoryCustomerViewComponent } from "./pages/advisory-customer-view/advisory-customer-view.component";
import { SubscriptionPopupDialog } from "./pages/dashboard/dashboard.component";
import { AdvisoryRegistrationComponent } from "./pages/advisory-registration/advisory-registration.component";
import { AdminSidebarComponent } from "./pages/admin-sidebar/admin-sidebar.component";
import { AdvisoryPaymentHistoryComponent } from "./pages/advisory-payment-history/advisory-payment-history.component";
import { QueryFormComponent } from "./pages/query-form/query-form.component";
import { SendQueryComponent } from "./pages/send-query/send-query.component";
import { AdminPageComponent } from "./pages/admin-page/admin-page.component";
import { AdminAllCllCustomersComponent } from "./pages/admin-all-customers/admin-all-customers.component";
import { TermsAndConditionComponent } from "./pages/terms-and-condition/terms-and-condition.component";
import { RefundConditionsComponent } from "./pages/refund-conditions/refund-conditions.component";
import { AdvisoryMyCustomerComponent } from "./pages/advisory-my-customer/advisory-my-customer.component";
import { AdminAllAdvisorsComponent } from "./pages/admin-all-advisors/admin-all-advisors.component";
import { RegisterTermsComponent } from "./pages/register-terms/register-terms.component";
import { scorocketTermsComponent } from "./pages/scorocket-terms/scorocket-terms.component";
import { AdminCustomerViewComponent } from "./pages/admin-customer-view/admin-customer-view.component";
import { NgxPaginationModule } from "ngx-pagination";
import { CustomerQueryComponent } from "./pages/customer-query/customer-query.component";
import { AnalystPageComponent } from "./pages/analyst-page/analyst-page.component";
import { AnalystSidebarComponent } from "./pages/analyst-sidebar/analyst-sidebar.component";
import { AnalystAllCustomerComponent } from "./pages/analyst-all-customer/analyst-all-customer.component";
import { AnalystMycustomerComponent } from "./pages/analyst-mycustomer/analyst-mycustomer.component";
import { AnalystPaymentHistoryComponent } from "./pages/analyst-payment-history/analyst-payment-history.component";
import { AnalystSendQueryComponent } from "./pages/analyst-send-query/analyst-send-query.component";
import { AnalystCustomerViewComponent } from "./pages/analyst-customer-view/analyst-customer-view.component";
import { AdminAnalystViewComponent } from "./pages/admin-analyst-view/admin-analyst-view.component";
import { AdminAllAnalystComponent } from "./pages/admin-all-analyst/admin-all-analyst.component";
import { ViewUsersComponent } from "./components/view-users/view-users/view-users.component";
import { CustomerTableComponent, statusmodal } from "./components/customer-table/customer-table/customer-table.component";
import { EnquiriesComponent } from './pages/enquiries/enquiries.component';

import { scorocketPaymentHistoryComponent } from "./components/payment-history/payment-history/payment-history.component";
import { AdminAdvisorViewComponent } from './pages/admin-advisor-view/admin-advisor-view.component';
import { scorocketPrimaryButtonComponent } from './components/scorocket-primary-button/scorocket-primary-button/scorocket-primary-button.component';
import { ConsentComponent } from './pages/consent/consent.component';
import { DashboardOtpComponet } from './pages/dashboard/dashboard-otp/dashboard-otp.component';
import { SubscritionPlansComponent } from './pages/subscrition-plans/subscrition-plans.component';
import { CustomDatePipe } from './providers/custom-date.pipe';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    PlanComponent,
    AboutUsComponent,
    ContactUsComponent,
    ServicesComponent,
    FooterComponent,
    ConsultationComponent,
    RegisterComponent,
    RegTermsDialog,
    statusmodal,
    TeamMemberDialog,
    PasswordComponent,
    LoginComponent,
    DashboardComponent,
    CreditCardDetailsComponent,
    UserPageComponent,
    ScoreTrendComponent,
    ScoreFactorComponent,
    PersonalProfileComponent,
    UserSidebarComponent,
    SubmitQueryComponent,
    PaymentHistoryComponent,
    LoanAccountComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    ResetPasswordComponent,
    TotalAccountComponent,
    CreditHistoryComponent,
    CreditUtilisationComponent,
    ScorePaymentHistoryComponent,
    DisputeComponent,
    AdvisoryPageComponent,
    AdvisorySidebarComponent,
    AdvisoryAllCustomersComponent,
    AdvisoryCustomerViewComponent,
    AdvisoryPaymentHistoryComponent,
    SubscriptionPopupDialog,
    QueryFormComponent,
    SendQueryComponent,
    AdvisoryRegistrationComponent,
    AdminSidebarComponent,
    AdminPageComponent,
    AdminAllCllCustomersComponent,
    TermsAndConditionComponent,
    RefundConditionsComponent,
    AdvisoryMyCustomerComponent,
    AdminAllAdvisorsComponent,
    RegisterTermsComponent,
    scorocketTermsComponent,
    AdminCustomerViewComponent,
    CustomerQueryComponent,
    AnalystPageComponent,
    AnalystSidebarComponent,
    AnalystAllCustomerComponent,
    AnalystMycustomerComponent,
    AnalystPaymentHistoryComponent,
    AnalystSendQueryComponent,
    AnalystCustomerViewComponent,
    AdminAnalystViewComponent,
    AdminAllAnalystComponent,
    ViewUsersComponent,
    CustomerTableComponent,
    EnquiriesComponent,
    scorocketPaymentHistoryComponent,
    AdminAdvisorViewComponent,
    scorocketPrimaryButtonComponent,
    ConsentComponent,
    DashboardOtpComponet,
    SubscritionPlansComponent,
    CustomDatePipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    MatModule,
    HttpClientModule,
    CountdownModule,
    ChartsModule,
    NgxLocalStorageModule.forRoot(),
    NgxPaginationModule
    // NgxMaskModule.forRoot(),
  ],
  entryComponents: [TeamMemberDialog, RegTermsDialog, SubscriptionPopupDialog, statusmodal,SubscritionPlansComponent],
  providers: [
    ExternalLibraryService,
    AuthService,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true }
  ],
  schemas: [NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {}
