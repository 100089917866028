import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
export interface TeamData {
  name: string; designation: string; image: string;
}

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
  teamData = [];
  constructor(public dialog: MatDialog) { }

  ngOnInit() {
    this.teamData = [
      [
        {
          name: 'Kamal Bedi',
          designation: 'Director',
          image: '../../../assets/team/kamalBedi.jpg',
          info: 'A Master of Business Administration (M.B.A.) focused in Banking, Corporate, Finance, and Securities Law from Newport University having over 18 years demonstrated history of working in the banking industry with organizations like HDFC Bank, Standard Chartered Bank, ICICI Bank and Indus Ind Bank, Kamal is a strong finance professional and comes with vast understanding and expertise in Banking, Sales Management, People Management, and Commercial Banking.'
        },

        {
          name: 'Apurva Vora',
          designation: 'Director',
          image: '../../../assets/team/Apurva.jpg',
          info: 'Mr.Apurva Vora holds Bachelor’s Degree in Commerce and Masters in Management having 20+ years professional experience. He is a Serial Entrepreneur & has worked with giants like Coca Cola, he was the first co-bottler for manufacturing Kinley mineral water in INDIA, As an Entrepreneur he has successfully built businesses in India as well as International markets, he has business interests in FMCG markets, metals & minerals industries with wings spread in  India, Africa, Middle East & China markets.'
        },

        {
          name: 'Nilesh Tribhuvann',
          designation: 'Legal Advisor',
          image: '../../../assets/team/Nilesh[487].jpg',
          info: 'With almost a decade of experience, Nilesh has three sixty degree exposure in the field of litigation, dispute resolution and support on all contentious matters to the clients. Trained under the tutelage of Senior Counsel Mr. Mahesh Ram Jethmalani, Nilesh has been at the forefront of advising a variety of clients that include large business houses, multi-national corporations, media houses and government bodies. Besides, he has also been retained in the panel of legal experts with several Conglomerates, Aviation Companies, Real Estate Giants and Mass Communication Corporations.'
        },
        {
          name: 'Murrali RB',
          designation: 'Technology Advisor',
          image: '../../../assets/team/murrali.jpg',
          info: 'Murrali RB is the CEO of SigniTeq Services Private Limited, he is an optimist and progressive leader with over 15 years of experience in technology and business services,  Prior to founding SigniTeq, Murrali RB served as a CEO in mid-sized technology services company, and has earlier driven revenues exceeding Rs 1.25 Billion (INR 125 Crores) in a Large Indian IT MNC, he enthusiastically leads several Startups by handholding technology enablement, right from conceptualization to culmination, transforming their innovative ideas into sustainable solutions, for next-level expansion in full swing to market success.'
        }
      ],
      [
        {
          name: 'Ashish Chandorkar',
          designation: 'Director & CEO',
          image: '../../../assets/team/Ashish[484].jpeg',
          info: 'An Electronics Hons. & Law Graduate with Master’s Degree in Business Administration having 21+ years’ of work experience with Leading Financial Services Organizations such as Citi-Group, Cholamandalam DBS, Tata Capital, ICICI Securities Ltd., Svasti Microfinance and the recent assignment with Satin Creditcare Network Limited as Business Head – MSME. A Retail Asset Lending Expert with deep understanding of NBFC & MSME Secured Loans Business across Geographies of India.'
        },

        {
          name: 'Vijay Nikalje',
          designation: 'Director',
          image: '../../../assets/team/Vijay[486].jpg',
          info: 'A Commerce Graduate with PGDBM in Management having 15+ years of professional experience with Banking & Financial Services Organizations such as IDBI Bank, ICICI Bank & Cholamandalam DBS Finance Limited. A Retail Asset Lending expert with deep understanding of Non-Banking Financial Services Space with keen interest in Individual & Corporate Lending. Currently based at Pune having multiple business interests in and across State of Maharashtra.'
        },
        {
          name: 'Maanu Raajan',
          designation: 'Director',
          image: '../../../assets/team/Maanu[485].jpg',
          info: 'A Commerce Graduate with Master’s Degree in Banking & Finance having 15+ years of work experience with Multinational Banks & Financial Services Organizations such as HSBC, Standard Chartered, Deustche Bank A.G., IIFL & Fedbank before taking up Directorship assignment with scorocket. A Retail Asset Lending Expert with detailed understanding of Banking & Financial Services Space also having keen interest in Real Estate Advisory Business.'
        }
      ]
    ];
  }


  openDialog(teamIndex, i): void {
    const dialogRef = this.dialog.open(TeamMemberDialog, {
      width: '500px',
      data: this.teamData[teamIndex][i]
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

}


@Component({
  selector: 'team-dialog',
  templateUrl: 'dialog.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class TeamMemberDialog {
  team;
  name;
  designation;
  info;
  image;
  constructor(public dialogRef: MatDialogRef<TeamMemberDialog>, @Inject(MAT_DIALOG_DATA) public data) {
    console.log(data);
    this.team = data;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

