import { Component, OnInit } from "@angular/core";
import creditscore from "../../creditscore.json";
import { LocalStorageService } from "ngx-localstorage";
import { scorocketService } from "src/app/providers/scorocket.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { cloneDeep } from "lodash";
import { isArray } from "util";
@Component({
  selector: "app-credit-card-details",
  templateUrl: "./credit-card-details.component.html",
  styleUrls: ["./credit-card-details.component.scss"]
})
export class CreditCardDetailsComponent implements OnInit {
  public creditList: any;
  panelOpenState = false;
  list = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC"
  ];
  userName: any;
  userId: any;
  noCreditReport: boolean;
  showCreditReport: boolean;
  messageNoCustomer: any;
  editField: boolean = false;
  editableCreditList = [];
  monthlyPayStatusArray: any[] = [];
  paymentHistory: any;
  reportDetails: any;
  showFileUpload: boolean = false;
  productid;
  fileToUpload: any = null;
  months = [
    {
      key: "JAN",
      value: "01"
    },
    {
      key: "FEB",
      value: "02"
    },
    {
      key: "MAR",
      value: "03"
    },
    {
      key: "APR",
      value: "04"
    },
    {
      key: "MAY",
      value: "05"
    },
    {
      key: "JUN",
      value: "06"
    },
    {
      key: "JUL",
      value: "07"
    },
    {
      key: "AUG",
      value: "08"
    },
    {
      key: "SEP",
      value: "09"
    },
    {
      key: "OCT",
      value: "10"
    },
    {
      key: "NOV",
      value: "11"
    },
    {
      key: "DEC",
      value: "12"
    }
  ];
  show: boolean;
  credit: FormGroup;
  fileUploadErrorMessage: string;
  constructor(
    public service: scorocketService,
    private ls: LocalStorageService,
    public fb: FormBuilder
  ) {}

  ngOnInit() {
    this.userName = this.ls.get("userName");
    this.userId = this.ls.get("userId");
    this.getCreditScore(this.userId);
    this.credit = this.fb.group({});
    console.log(this.creditList,'credit')
  }

  getCreditScore(userId) {
    this.service.getProductId(userId).subscribe(res => {
      if (res.message == "success") {
        console.log("credit",res)
        if (res.data.length > 0 && res.data[0].productId) {
          this.productid = res.data[0].productId;
          this.loadCreditScore(userId, res.data[0].productId);
        } else {
          this.noCreditReport = true;
          // this.showCreditReport = false;
          this.messageNoCustomer =
            "No Data Available! Please Purchase Your Plan";
        }
      }
    });
  }

  handleFileInput(files: FileList) {
    console.log(files);
    if (files.item(0).size > 1e6) {
      this.fileUploadErrorMessage = "File Size Should Not Be Greater Than 2 MB";
    } else if (files.item(0).size < 1e6) {
      this.fileUploadErrorMessage = "";
      this.fileToUpload = files.item(0);
    }
  }

  removeFile() {
    this.fileToUpload = "";
  }

  changeDisputeState = i => {
    this.creditList[i].reportDetails["dispute"] = !this.creditList[i]
      .reportDetails["dispute"];
    if (!this.creditList[i].reportDetails["dispute"]) {
      this.creditList[i].reportDetails["edit"] = false;
      this.fileToUpload = "";
      this.creditList[i].reportDetails["diputeType"] = "";
      var ele = document.getElementsByName("choice" + i);
      for (var j = 0; j < ele.length; j++)
        (<HTMLInputElement>ele[j]).checked = false;
    }
  };

  changeFieldsToEditable = (i, value, selectedDispute, showUpload) => {
    this.showFileUpload = showUpload;
    this.creditList[i].reportDetails["edit"] = value;
    this.creditList[i].reportDetails["diputeType"] =
      selectedDispute.target.value;
  };
  borrowerDetails:any;
  loadCreditScore(userId, productId) {
    this.service.getCreditReport(userId,productId).subscribe(
      res => {
        if (res.message == "success") {
          this.noCreditReport = false;
          this.showCreditReport = true;
          if (res.data.reports[0].report.finalReport[0].reportDetails.GetCustomerAssetsResponse.GetCustomerAssetsSuccess.Asset.TrueLinkCreditReport.TradeLinePartition) {
            this.creditList = res.data.reports[0].report.finalReport[0].reportDetails.GetCustomerAssetsResponse.GetCustomerAssetsSuccess.Asset.TrueLinkCreditReport.TradeLinePartition;
            this.borrowerDetails = res.data.reports[0].report.finalReport[0].reportDetails.GetCustomerAssetsResponse.GetCustomerAssetsSuccess.Asset.TrueLinkCreditReport.Borrower;
            for (var call of this.creditList) {
              console.log('call',call);
              if (call.accountTypeSymbol == "10") {
                this.noCreditReport = false;
                this.showCreditReport = true;
                break;
              } else {
                this.noCreditReport = true;
                this.showCreditReport = false;
                this.messageNoCustomer = "NO CREDIT CARD AVAILABLE";
              }
            }
            this.creditList.forEach((res, index) => {
              this.reportDetails = res.Tradeline;
              this.paymentHistory = res.Tradeline.GrantedTrade.PayStatusHistory.MonthlyPayStatus
              if(!isArray(this.paymentHistory) && res.accountTypeSymbol == "10"){
                this.monthlyPayStatusArray.push(this.paymentHistory)
                this.monthlyPayStatusArray.forEach(res => {
                  res['Year'] = res.date.slice(0,4);
                  res['Month'] = res.date.slice(5,7)
                })
                let y;
              const sample = this.monthlyPayStatusArray.reduce((r, { Year }) => {
                const year = Year;
                if (!r.some(o => o.group == Year)) {
                  if (y != year) {
                    r.push({
                      Year,
                      groupItem: this.monthlyPayStatusArray.filter(v => v.Year == Year)
                    });
                  }
                  y = year;
                }
                return r;
              }, []);
              this.creditList[index]["PaymentYears"] = sample;
              }
              if(isArray(this.paymentHistory) && res.accountTypeSymbol == "10") {
                this.monthlyPayStatusArray = this.paymentHistory;
                console.log('wrk')
                this.monthlyPayStatusArray.forEach(res => {
                  res['Year'] = res.date.slice(0,4);
                  res['Month'] = res.date.slice(5,7)
                })
                let y;
              const sample = this.monthlyPayStatusArray.reduce((r, { Year }) => {
                const year = Year;
                if (!r.some(o => o.group == Year)) {
                  if (y != year) {
                    r.push({
                      Year,
                      groupItem: this.monthlyPayStatusArray.filter(v => v.Year == Year)
                    });
                  }
                  y = year;
                }
                return r;
              }, []);
              this.creditList[index]["PaymentYears"] = sample;
              }
            });
            this.creditList.forEach(credit => {
              credit["dipute"] = false;
              credit["edit"] = false;
              credit["diputeType"] = "";
              credit["buttonText"] = "Send Dispute";
            });
            this.editableCreditList = cloneDeep(this.creditList);
          } else {
            // this.validateCreditScore(0)
          }
        } else {
          this.messageNoCustomer = res.data[0].errorString;
          this.noCreditReport = true;
          this.showCreditReport = true;
          this.service.snackbar(res.data[0].errorString);
        }
      },
      err => {}
    );
  }

  //deep clone
  clone = arr =>
    Array.from(arr, item => (Array.isArray(item) ? this.clone(item) : item));
  RowClicked() {
    console.log("row clicked");
  }

  sendDispute = i => {
    let hasEmptyFiled = false;
    let conflicFileds = {};
    let oldValues = {};
    let keys = Object.keys(this.editableCreditList[i].reportDetails);
    let disputeObj = {
      emailId: localStorage.getItem("userEmail"),
      disputeType: this.creditList[i].reportDetails["diputeType"],
      bankName: this.creditList[i].reportDetails["Subscriber_Name"],
      accountNumber: this.creditList[i].reportDetails["Account_Number"],
      stage1Id: "1574545",
      stage2Id: "1534392",
      productId: this.productid
    };
    if (this.creditList[i].reportDetails.edit) {
      keys.forEach(key => {
        if (
          typeof this.editableCreditList[i].reportDetails[key] == "string" ||
          typeof this.editableCreditList[i].reportDetails[key] == "number"
        ) {
          if (
            this.editableCreditList[i].reportDetails[key] !==
            this.creditList[i].reportDetails[key]
          ) {
            if (this.editableCreditList[i].reportDetails[key] == "") {
              hasEmptyFiled = true;
            }
            conflicFileds[key] = this.editableCreditList[i].reportDetails[key];
            oldValues[key] = this.creditList[i].reportDetails[key];
          }
        }
      });
      disputeObj["currentData"] = oldValues;
      disputeObj["disputedData"] = conflicFileds;
      console.log("disputeObj", disputeObj);
    }

    if (!hasEmptyFiled) {
      this.creditList[i].buttonText = "Sending...";
      this.service
        .sendDispute(
          disputeObj,
          localStorage.getItem("userId"),
          this.fileToUpload
        )
        .subscribe(
          res => {
            if (res.message == "success") {
              this.creditList[i]["buttonText"] = "Send Dispute";
              this.changeDisputeState(i);
              this.service.snackbar("Dispute Sent Successfully");
            }
          },
          err => {
            if (err) {
              this.service.snackbar(
                "An Error Occurred While Sending The Dispute"
              );
              this.creditList[i]["buttonText"] = "Send Dispute";
            }
          }
        );
    } else if (hasEmptyFiled) {
      this.service.snackbar("Edited Fileds Cannot Be Empty");
    }
  };

  chkValue(val, data) {
    let retrunValue;
    data.groupItem.some(el => {
      if (JSON.stringify(el.Month) == JSON.stringify(val)) {
        retrunValue = 1;
      }
    });
    return retrunValue;
  }
}
