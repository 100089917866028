import { Component, OnInit } from "@angular/core";
import { LocalStorageService } from "ngx-localstorage";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { scorocketService } from "src/app/providers/scorocket.service";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-analyst-mycustomer",
  templateUrl: "./analyst-mycustomer.component.html",
  styleUrls: ["./analyst-mycustomer.component.scss"]
})
export class AnalystMycustomerComponent implements OnInit {
  search: FormGroup;
  downloading = false;
  userdata: any;
  years: number[];
  months: { no: number; value: string }[];
  dates;
  fromDate: string;
  tillDate: string;
  userId: any;
  productId: any;
  messageNoCustomer: string;
  noData: boolean;
  showData: boolean;
  payload: { state: string; reason: string };
  nodataMessage: string;
  showUsers: boolean;
  reloading: boolean;
  show: boolean;
  selectedUser: any;
  toggleBarStatus: string;
  advisorId: any;
  status: string;
  sortOrder = "asc";
  showasc: boolean;
  showdsc: boolean;
  constructor(
    public ls: LocalStorageService,
    public fb: FormBuilder,
    public router: Router,
    public service: scorocketService,
    public datepipe: DatePipe
  ) {}

  ngOnInit() {
    this.advisorId = this.ls.get("userId");
    this.search = this.fb.group({
      search: ["", [Validators.required]]
    });
    this.getusers();
    this.loadDates();
  }

  searchUsers() {
    this.showasc = false;
    this.showdsc = false;
    this.sortOrder = "asc";
    if (
      this.search.value.search == "" ||
      this.search.value.search == undefined ||
      this.search.value.search == null
    ) {
      this.getusers();
    } else {
      const searchText = this.search.value.search;
      this.service.getMyCustomerSearch(this.advisorId, searchText).subscribe(
        res => {
          if (res.message == "success") {
            this.showUsers = true;
            this.userdata = res.data.users;
          }
        },
        err => {
          if ((err.message = "Search did not match any")) {
            this.showUsers = false;
            this.nodataMessage = "No data record matching name";
          }
        }
      );
    }
  }

  getusers() {
    this.reloading = true;
    this.showasc = false;
    this.showdsc = false;
    this.sortOrder = "asc";
    this.search.reset();
    this.service.getMappedCustomers(this.advisorId).subscribe(
      res => {
        if (res.message == "success") {
          this.showUsers = true;
          this.reloading = false;
          this.userdata = res.data.users;
          this.userdata.map(user => {
            return (user.auth_doc.accountState.state = "locked" ? true : false);
          });
        }
      },
      err => {
        this.showUsers = false;
        this.reloading = false;
        this.nodataMessage = "No Users Found";
      }
    );
  }

  getCreditScore(data) {
    this.userId = data.userId;
    if (!data.ratings_doc[0]) {
      this.noData = true;
      this.showData = false;
      this.messageNoCustomer = "Consumer record not found";
    } else if (data.order_doc[0]) {
      this.productId = data.order_doc[0].productId;
      this.noData = false;
      this.showData = true;
    } else {
      this.noData = true;
      this.showData = false;
      this.messageNoCustomer = "No Data Available!";
    }
  }

  loadDates = async () => {
    let years = await this.getyears();
    this.dates = {
      year: years[0],
      month: new Date().getMonth() + 1
    };
    (this.years = years), (this.months = await this.getMonths());
  };

  daysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
  };

  getyears = () => {
    const now = new Date().getUTCFullYear();
    return Array(now - (now - 30))
      .fill("")
      .map((v, idx) => now - idx) as Array<number>;
  };

  getMonths = () => {
    return [
      { no: 1, value: "January" },
      { no: 2, value: "February" },
      { no: 3, value: "March" },
      { no: 4, value: "April" },
      { no: 5, value: "May" },
      { no: 6, value: "June" },
      { no: 7, value: "July" },
      { no: 8, value: "August" },
      { no: 9, value: "September" },
      { no: 10, value: "October" },
      { no: 11, value: "November" },
      { no: 12, value: "December" }
    ];
  };

  loadReport = async () => {
    const month = this.dates.month;
    let lastDate = await this.daysInMonth(parseInt(month), this.dates.year);
    this.fromDate = this.datepipe.transform(
      new Date(`${month}/${"1"}/${this.dates.year}`),
      "yyyy-MM-dd"
    );
    this.tillDate = this.datepipe.transform(
      new Date(`${month}/${lastDate}/${this.dates.year}`),
      "yyyy-MM-dd"
    );
    const reqPayload = {
      userId: this.userId,
      query: `?fromDate=${this.fromDate}&tillDate=${this.tillDate}`,
      productId: this.productId
    };
    this.downloading = true;
    this.service.getReports(reqPayload).subscribe(
      async res => {
        if (res) {
          this.downloading = false;
          this.showPdf(res.data, this.productId);
          document.getElementById("close").click();
        }
      },
      err => {
        if (err.status == 400) {
          this.service.snackbar("Record Not found!");
        }
        this.downloading = false;
      }
    );
  };

  async showPdf(data, prodName) {
    // const linkSource = "data:application/pdf;base64," + data;
    const linkSource = data[0].reports[0].reportLocation.url;
    const downloadLink = document.createElement("a");
    const fileName = `credit_report${prodName}.pdf`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }
  // toggle(toggleChk, data) {
  //   console.log({ toggleChk, data });
  //   this.selectedUser = data;
  //   if (toggleChk) {
  //     this.toggleBarStatus = 'locked';
  //   } else {
  //     this.toggleBarStatus = 'Active';
  //   }
  //   console.log('this.toggleBarStatus', this.toggleBarStatus);
  // }
  toggle(toggleChk, data) {
    console.log({ toggleChk, data });
    this.selectedUser = data;
    this.toggleBarStatus = toggleChk;
    if (toggleChk == true) {
      this.status = "LOCK";
    } else {
      this.status = "ACTIVATE";
    }
    this.userdata.map(user => {
      if (user.userId == this.selectedUser.userId) {
        return (user.auth_doc.accountState.state = this.toggleBarStatus
          ? "locked"
          : "active");
      }
    });
    console.log(this.userdata);
  }

  changelocked(e) {
    console.log("event", e, this.toggleBarStatus);
    this.toggleBarStatus = e;
    if (!e) {
      this.userdata.map(user => {
        if (user.userId == this.selectedUser.userId) {
          return (user.auth_doc.accountState.state =
            user.auth_doc.accountState.state == "locked" ? "active" : "locked");
        }
      });
    } else {
      this.payload = {
        state: this.selectedUser.auth_doc.accountState.state,
        reason:
          this.selectedUser.auth_doc.accountState.state == "locked"
            ? "violated rules & policy"
            : "Violation Cleared"
      };
      console.log("this.payload", this.payload);
      this.service
        .lockedstate(this.selectedUser.userId, this.payload)
        .subscribe(res => {
          if (res.message == "success") {
            this.service.snackbar(res.message, "close");
          }
        });
    }
    console.log(this.userdata);
  }
  sortName() {
    if (this.sortOrder == "asc") {
      this.userdata.sort((a, b) => a.name.first.localeCompare(b.name.first));
      this.sortOrder = "dec";
      this.showasc = true;
      this.showdsc = false;
    } else if (this.sortOrder == "dec") {
      this.userdata.sort((a, b) => b.name.first.localeCompare(a.name.first));
      this.sortOrder = "asc";
      this.showasc = false;
      this.showdsc = true;
    }
  }
}
