import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-scorocket-terms',
  templateUrl: './scorocket-terms.component.html',
  styleUrls: ['./scorocket-terms.component.scss']
})
export class scorocketTermsComponent implements OnInit {

  constructor( public router:Router) { }

  ngOnInit() {
  }
  cancel() {
    window.close();
    this.router.navigate(["/register"]);
  }

}
