import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { scorocketService } from "src/app/providers/scorocket.service";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from "@angular/material/dialog";
import { LocalStorageService } from 'ngx-localstorage';


@Component({
  selector: 'app-dashboard-otp',
  templateUrl: './dashboard-otp.component.html',
  styleUrls: ['./dashboard-otp.component.scss']
})
export class DashboardOtpComponet implements OnInit {
  otpform : FormGroup;
  upiform: FormGroup;
  inputValue: string = ''
  accessCode: any;
  encRequest: any;
  // @ViewChild('form') form!: ElementRef;
  @ViewChild("form", { static: true }) form: ElementRef;


  constructor(
    public fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DashboardOtpComponet>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public service: scorocketService,
    public ls:LocalStorageService,
  ) { }

  ngOnInit() {
    this.otpform  = this.fb.group ({
      otp: [""]
    })
    this.upiform = this.fb.group ({
      upi: ['',[Validators.required]]
    })
    this.accessCode = 'AVBM16KI03CA15MBAC';
  }
  onNoClick(){
    this.dialogRef.close();
  }

  submit(){ 

    const otppayload = {
      "questionKey": this.data.questionKey,
      "answerKey": this.data.AnswerChoiceId,
      "ChallengeConfigGUID": this.data.ChallengeConfigGUID,
      "UserInputAnswer": this.otpform.value.otp
  }
  console.log('otppayload',otppayload)
    if(this.otpform.value.otp ){
      this.service.otpcofirmation(this.data.userId, this.data.productId,otppayload).subscribe(
        res =>{
          if(res.message === "success"){
            this.dialogRef.close({validationSuccess:validationSuccess,res:res});
          }else{
            this.service.snackbar('Enter the valid OTP!')
          }
        },
        err => {
          
        }
      )
      const validationSuccess = true;
      
    }
  }
  pay() {
    // this.cartValue contains all the order information which is sent to the server
    // You can use this package to encrypt - https://www.npmjs.com/package/node-ccavenue/
    this.service.ccavenuetest().subscribe(
      res => {
        this.encRequest = res.encData
        console.log('ress',res)
        setTimeout((_:any) => this.form.nativeElement.submit());
      }
    )
      
    }
  
  upivalid(){
    console.log('work')
    if(this.upiform.value.upi){
      console.log('work1')
      const Upipayload = {
        vpa :this.upiform.value.upi
      }
      this.service.UPIvalidation(Upipayload).subscribe(
        response =>{
          if(response.status == 200){
            const payload = {
              userId : this.ls.get('userId'),
              productId: this.data.productId,
              paymentPartner: "phonepe",
              orderType: "subscriptionPlan",
              vpa: this.upiform.value.upi
            }
            this.service.phonepeSubscription(payload).subscribe(
              res =>{
                if(res.status == 200){
                  const url = res.data.paymentURL.replace(/"/g, '')
                  window.location.href = url;
                }else{
                  this.service.snackbar('Product purchase unSucessfull')
                  
                }
              },
              err =>{
                this.service.snackbar('Product purchase unSucessfull')
              }
            )

          }else {
            this.service.snackbar('Enter valid Upi')
          }
        },
      )
    }
  }

  selectedOption:boolean;
  radio(){
    this.selectedOption =true
  }

  radio1(){
    this.selectedOption =false
    this.pay()
  }

  planpurchase(){
    const orederpayload = {
      userId: this.ls.get('userId'),
      productId: "6505b12ae2587d247862f302",
      paymentPartner: "phonepe",
      orderType: "product"
    };
    this.service.buyNow(orederpayload).subscribe(
      res => {
        if(res.message == 'success'){
          const url = res.data.paymentURL.replace(/"/g, '')
          console.log('url',url)
          window.location.href = url;
  }
})
  }

}
