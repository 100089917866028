import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-analyst-customer-view",
  templateUrl: "./analyst-customer-view.component.html",
  styleUrls: ["./analyst-customer-view.component.scss"]
})
export class AnalystCustomerViewComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
