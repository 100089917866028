import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-refund-conditions',
  templateUrl: './refund-conditions.component.html',
  styleUrls: ['./refund-conditions.component.scss']
})
export class RefundConditionsComponent implements OnInit {

  constructor(public router:Router) { }

  ngOnInit() {
  }
  cancel(){
    this.router.navigate(["/termsandcondition"])
  }

}
