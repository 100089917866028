import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import { scorocketService } from "src/app/providers/scorocket.service";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from "@angular/material/dialog";

@Component({
  selector: "customer-table",
  templateUrl: "./customer-table.component.html",
  styleUrls: ["./customer-table.component.scss"]
})
export class CustomerTableComponent implements OnInit {
  search: FormGroup;
  // searchUsers;
  userdata: any;
  years: number[];
  months: { no: number; value: string }[];
  dates;
  fromDate: string;
  tillDate: string;
  userId: any;
  productId: any;
  messageNoCustomer: string;
  noData: boolean;
  showData: boolean;
  payload: { state: string; reason: string };
  nodataMessage: string;
  show: boolean;
  selectedUser: any;
  toggleBarStatus: string;
  status;
  sortOrder = "asc";
  p: number = 1;
  downloading: boolean = false;
  @Input() customerData: any;
  @Output() searchOnChange = new EventEmitter();
  @Output() reloadUsersEmit = new EventEmitter();
  @Input() showasc: boolean = false;
  @Input() showdsc: boolean = false;
  @Input() showUsers: boolean = false;
  @Input() reloading: boolean = false;
  @Input() nonEditableFields: boolean = false;
  @Input() route: any;
  @Input() page: any;
  constructor(
    public fb: FormBuilder,
    public router: Router,
    public service: scorocketService,
    public datepipe: DatePipe,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.search = this.fb.group({
      search: ["", [Validators.required]]
    });
    this.loadDates();
  }

  statusDialog(): void {
    const dialogRef = this.dialog.open(statusmodal, {
      width: "40%"
      // data: {name: this.name, animal: this.animal}
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log("The dialog was closed", result);
      if (result == true) {
        this.changelocked(true);
      } else {
        this.changelocked(false);
      }
    });
  }

  sortName() {
    if (this.sortOrder == "asc") {
      this.customerData.sort((a, b) =>
        a.name.first.localeCompare(b.name.first)
      );
      this.sortOrder = "dec";
      this.showasc = true;
      this.showdsc = false;
    } else if (this.sortOrder == "dec") {
      this.customerData.sort((a, b) =>
        b.name.first.localeCompare(a.name.first)
      );
      this.sortOrder = "asc";
      this.showasc = false;
      this.showdsc = true;
    }
  }

  getCreditScore(data) {
    console.log(data);
    this.userId = data.userId;
    if (!data.ratings_doc[0]) {
      this.noData = true;
      this.showData = false;
      this.messageNoCustomer = "Consumer record not found";
    } else if (data.order_doc[0]) {
      this.productId = data.order_doc[0].productId;
      this.noData = false;
      this.showData = true;
    } else {
      this.noData = true;
      this.showData = false;
      this.messageNoCustomer = "No Data Available!";
    }
  }

  loadDates = async () => {
    let years = await this.getyears();
    this.dates = {
      year: years[0],
      month: new Date().getMonth() + 1
    };
    (this.years = years), (this.months = await this.getMonths());
  };

  daysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
  };

  getyears = () => {
    const now = new Date().getUTCFullYear();
    return Array(now - (now - 30))
      .fill("")
      .map((v, idx) => now - idx) as Array<number>;
  };

  getMonths = () => {
    return [
      { no: 1, value: "January" },
      { no: 2, value: "February" },
      { no: 3, value: "March" },
      { no: 4, value: "April" },
      { no: 5, value: "May" },
      { no: 6, value: "June" },
      { no: 7, value: "July" },
      { no: 8, value: "August" },
      { no: 9, value: "September" },
      { no: 10, value: "October" },
      { no: 11, value: "November" },
      { no: 12, value: "December" }
    ];
  };

  loadReport = async () => {
    const month = this.dates.month;
    let lastDate = await this.daysInMonth(parseInt(month), this.dates.year);
    this.fromDate = this.datepipe.transform(
      new Date(`${month}/${"1"}/${this.dates.year}`),
      "yyyy-MM-dd"
    );
    this.tillDate = this.datepipe.transform(
      new Date(`${month}/${lastDate}/${this.dates.year}`),
      "yyyy-MM-dd"
    );
    const reqPayload = {
      userId: this.userId,
      query: `?fromDate=${this.fromDate}&tillDate=${this.tillDate}`,
      productId: this.productId
    };
    this.downloading = true;
    this.service.getReports(reqPayload).subscribe(
      async res => {
        if (res) {
          this.downloading = false;
          this.showPdf(res.data, this.productId);
        }
      },
      err => {
        if (err.status == 400) {
          this.service.snackbar("Record Not found!");
        }
        this.downloading = false;
      }
    );
  };

  async showPdf(data, prodName) {
    // const linkSource = "data:application/pdf;base64," + data;
    const linkSource = data[0].reports[0].reportLocation.url;
    const downloadLink = document.createElement("a");
    const fileName = `credit_report${prodName}.pdf`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    await downloadLink.click();
    document.getElementById("close").click();
  }

  toggle(toggleChk, data) {
    if (toggleChk == true) {
      this.status = "LOCK";
    } else {
      this.status = "ACTIVATE";
    }
    this.selectedUser = data;
    this.toggleBarStatus = toggleChk;
    this.customerData.map(user => {
      if (user.userId == this.selectedUser.userId) {
        return (user.auth_doc.accountState.state = this.toggleBarStatus
          ? "locked"
          : "active");
      }
    });
  }

  reloadUsers = () => {
    this.search.reset();
    this.reloadUsersEmit.emit();
    return;
  };

  changelocked(e) {
    console.log("ee", e);
    this.toggleBarStatus = e;
    if (!e) {
      this.customerData.map(user => {
        if (user.userId == this.selectedUser.userId) {
          return (user.auth_doc.accountState.state =
            user.auth_doc.accountState.state == "locked" ? "active" : "locked");
        }
      });
    } else {
      this.payload = {
        state: this.selectedUser.auth_doc.accountState.state,
        reason:
          this.selectedUser.auth_doc.accountState.state == "locked"
            ? "violated rules & policy"
            : "Violation Cleared"
      };
      this.service
        .lockedstate(this.selectedUser.userId, this.payload)
        .subscribe(res => {
          if (res.message == "success") {
            this.service.snackbar(res.message, "close");
          }
        });
    }
  }

  redirectToNextPage = data => {
    this.router.navigate(
      ["/", this.route, { outlets: { [this.page]: ["customer"] } }],
      {
        queryParams: {
          id: data.userId
        }
      }
    );
  };

  searchUsers() {
    this.searchOnChange.emit(this.search.value.search);
    return;
  }
}
@Component({
  selector: "status-modal",
  templateUrl: "status-modal.html",
  styleUrls: ["./customer-table.component.scss"]
})
export class statusmodal {
  constructor(
    public dialogRef: MatDialogRef<statusmodal> // @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}
  status;
  // changelocked(): void {
  //   this.dialogRef.close(false);
  // }
  changelocked(param): void {
    this.dialogRef.close(param);
  }
}
