import { Component, OnInit, Input } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { scorocketService } from "src/app/providers/scorocket.service";
import { LocalStorageService } from "ngx-localstorage";
import { DatePipe } from "@angular/common";
import * as moment from "moment";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { ChartDataSets, ChartOptions } from "chart.js";
import { Color, Label } from "ng2-charts";
import { JwtHelperService } from "@auth0/angular-jwt";

@Component({
  selector: "view-users",
  templateUrl: "./view-users.component.html",
  styleUrls: ["./view-users.component.scss"]
})
export class ViewUsersComponent implements OnInit {
  noCreditReport: boolean;
  showCreditReport: boolean;
  messageNoCustomer: string;
  accountData: any;
  reportDetails: any;
  nonEditableFields = false;
  showOTPField = false;
  paymentHistory: any;
  myTime;
  userId: string;
  tillDate: any;
  months = [
    {
      key: "JAN",
      value: "01"
    },
    {
      key: "FEB",
      value: "02"
    },
    {
      key: "MAR",
      value: "03"
    },
    {
      key: "APR",
      value: "04"
    },
    {
      key: "MAY",
      value: "05"
    },
    {
      key: "JUN",
      value: "06"
    },
    {
      key: "JUL",
      value: "07"
    },
    {
      key: "AUG",
      value: "08"
    },
    {
      key: "SEP",
      value: "09"
    },
    {
      key: "OCT",
      value: "10"
    },
    {
      key: "NOV",
      value: "11"
    },
    {
      key: "DEC",
      value: "12"
    }
  ];
  myDate = new Date();
  noLoanReport: boolean;
  showLoanReport: boolean;
  messageNoLoan: string;
  messageNoCredit: string;
  profile: any;
  dob: any;
  dateOfBirth: string;
  totalAccount: any;
  totalAccountWidth: string;
  totalAccountbg: string;
  totalAccountMsg: string;
  totalAccountColor: string;
  totalCardBalance = [];
  totalCardLimit = [];
  totalMissedPayments = [];
  dates = [];
  paymentHistoryColor: string;
  paymentHistoryMsg: string;
  paymentHistorybg: string;
  paymentHistoryWidth: string;
  diffInMonths: any;
  creditCardUtilise: any;
  creditCardUtiliseWidth: string;
  creditCardUtilisebg: string;
  creditCardUtiliseMsg: string;
  creditCardUtiliseColor: string;
  diffInYear: number;
  diffInYearWidth: string;
  diffInYearbg: string;
  diffInYearMsg: string;
  diffInYearColor: string;
  showData: boolean;
  startDate: string;
  endDate: string;
  scoreTrend: FormGroup;
  dangerpaymenthistory: string;
  warningpaymenthistory: string;
  goodpaymenthistory: string;
  successpaymenthistory: string;
  noinfopaymenthistory: string;
  dangeroldestYear: string;
  warningoldestYear: string;
  goodoldestYear: string;
  successoldestYear: string;
  noinfooldestYear: string;
  dangercardUtilisation: string;
  warningcardUtilisation: string;
  goodcardUtilisation: string;
  successcardUtilisation: string;
  noinfocardUtilisation: string;
  dangeraccountTotal: string;
  warningaccountTotal: string;
  successaccountTotal: string;
  noinfoaccountTotal: string;
  countdownExpired = false;
  graphResponse: any;
  second: any;
  resdates: string;
  chartLabels = [];
  chartData = [];
  @Input() userType: string;
  @Input() sendOtp: boolean = false;
  @Input() route: any;
  @Input() page:any;
  @Input() path:any;
  public lineChartData: ChartDataSets[] = [
    {
      data: [65],
      label: "Experian",
      fill: false,
      lineTension: 0,
      backgroundColor: "white",
      pointRadius: 4
    }
  ];
  public lineChartLabels: Label[];
  public lineChartColors: Color[] = [
    {
      borderColor: "#44a0de"
    }
  ];
  public lineChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [
        {
          offset: true,
          gridLines: {
            display: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            min: 0,
            max: 900,
            stepSize: 100
          }
        }
      ]
    }
  };

  public lineChartLegend = true;
  public lineChartType = "line";
  public lineChartPlugins = [];
  finaldata: any;
  paymentHistorydata: any;
  productId: any;
  otpForm: FormGroup;
  profileForm: FormGroup;
  showEdit: boolean = false;
  showClose: boolean = false;
  dateOf: string;
  years: number;
  monthdiff: number;
  birthDate: string;
  adminId: string;
  totalCreditHistory: any = [];
  payment: number;

  constructor(
    public fb: FormBuilder,
    public activatedroute: ActivatedRoute,
    public datePipe: DatePipe,
    public router: Router,
    public ls: LocalStorageService,
    public service: scorocketService
  ) {}

  ngOnInit() {
    this.profileForm = this.fb.group(
      {
        firstName: [
          "",
          [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(20),
            Validators.pattern("[a-zA-Z ]+")
          ]
        ],
        // userName: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(20)]],
        middleName: [""],
        lastName: [
          "",
          [
            Validators.required,
            Validators.minLength(1),
            Validators.maxLength(20),
            Validators.pattern("[a-zA-Z ]+")
          ]
        ],
        DOB: [""],
        emailAddress: [
          "",
          [
            Validators.required,
            Validators.email,
            Validators.pattern("^[a-z0-9._-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
          ]
        ],
        mobileNumber: [
          "",
          [Validators.required, Validators.pattern("[0-9]\\d{9}")]
        ],
        address: [""],
        pincode: ["", [Validators.pattern("[0-9]\\d{5}")]],
        city: [""],
        pancard: [
          "",
          [
            Validators.pattern("^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$")
          ]
        ]
      },
      (this.otpForm = this.fb.group({
        otp: ["", [Validators.pattern("[0-9]\\d{4}")]]
      }))
    );
    this.startDate = this.datePipe.transform(this.myDate, "yyyy-MM-dd");
    this.endDate = this.datePipe.transform(this.myDate, "yyyy-MM-dd");
    this.scoreTrend = this.fb.group({
      fromDate: ["", [Validators.required]],
      toDate: ["", [Validators.required]]
    });
    this.activatedroute.queryParams.subscribe(params => {
      this.userId = params.id;
    });
    this.getCreditScore(this.userId);
    this.profileData(this.userId);
  }

  goBack(){
    // console.log("data 123",data)
    // this.router.navigateByUrl("analyst/(analystpage:customers)");
    // this.router.navigate(['/' , this.route, { outlets: { [this.page]: ['customer'] }}], {queryParams: {
    //   id: data.userId 
    // }})
    this.router.navigate(['/' , this.route, { outlets: { [this.page]: this.path }}])
  }

  profileData(userId) {
    this.service.getProfile(userId).subscribe(res => {
      this.profile = res.data;
      console.log("this.profile", this.profile);
      this.dob = res.data.DOB;
      console.log("this.dob", this.dob);
      const date = new Date(this.dob);
      console.log("date", date);
      this.dateOfBirth = this.datePipe.transform(date, "dd-MM-yyyy");
      this.dateOf = date.toLocaleDateString();
      console.log("this.dateOfBirth", this.dateOfBirth);
    });
  }

  getCreditScore(userId) {
    this.service.getProductId(userId).subscribe(res => {
      if (res.message == "success") {
        if (res.data.length > 0 && res.data[0].productId) {
          this.productId = res.data[0].productId;
          this.loadCreditScore(userId, res.data[0].productId);
        } else {
          this.noLoanReport = true;
          this.showLoanReport = false;
          this.noCreditReport = true;
          this.showCreditReport = false;
          this.showData = false;
          this.messageNoCredit = "No Data Available!";
          this.messageNoLoan = "No Data Available!";
          this.messageNoCredit = "No Data Available!";
        }
      }
    });
  }

  editProfile() {
    const helper = new JwtHelperService();
    const jwt = localStorage.getItem("token");
    const decoded = helper.decodeToken(jwt);
    const localRole = decoded.role;
    if (localRole == "advisor") {
      this.nonEditableFields = true;
    } else {
      this.nonEditableFields = false;
    }
    this.MappingToEdit();
    this.showEdit = true;
    this.showClose = true;
  }

  closeEdit() {
    this.showEdit = false;
    this.showClose = false;
    this.showOTPField = false;
    this.profileForm.patchValue({
      otp: ""
    });
  }

  MappingToEdit() {
    const patchData = {
      firstName: this.profile.name.first,
      lastName: this.profile.name.last,
      DOB: this.dateOfBirth,
      emailAddress: this.profile.email,
      mobileNumber: this.profile.phone[0].number,
      address: this.profile.address[0].type,
      pincode: this.profile.address[0].zip,
      city: this.profile.address[0].city,
      pancard: this.profile.socialIds.length ? this.profile.socialIds[0].ID : ''
    };
    this.profileForm.patchValue(patchData);
  }

  getOTP() {
    if (this.profileForm.valid) {
      const payload = {
        userReference: this.profile.userId,
        purpose: "edit_profile"
      };
      this.service.getOTP(payload).subscribe(
        res => {
          if (res.message === "success") {
            this.showOTPField = true;
            this.resetTimer(res.data.expiration);
          }
        },
        err => {
          if (err.status === 500) {
            this.service.snackbar("Invalid credential!", "close");
          } else if (err.status == 0) {
            this.service.snackbar("ERR_CONNECTION_TIMED_OUT!", "close");
          }
        }
      );
    } else {
      this.service.snackbar("Please fill the required fields!", "close");
    }
  }

  clearTimer = () => {
    this.myTime = {
      left: 0,
      format: "mm:ss"
    };
  };

  resetTimer = date => {
    this.myTime = {
      stopTime: new Date(date).getTime(),
      format: "mm:ss"
    };
  };

  update() {
    const check = this.profileForm.value.DOB;
    if (check) {
      const splitfun = check.split("-");
      this.adminId = this.ls.get("userId");
      let dobField = new Date(
        parseInt(splitfun[2]),
        parseInt(splitfun[1]) - 1,
        parseInt(splitfun[0])
      );
      this.birthDate = dobField.toISOString();
      dobField.setUTCHours(24, 0, 0, 0);
    }

    const name = {
      first: this.profileForm.value.firstName,
      middle: this.profileForm.value.middleName,
      last: this.profileForm.value.lastName
    };
    const address = [];
    const addPayload = {
      type: this.profileForm.value.address,
      city: this.profileForm.value.city,
      zip: this.profileForm.value.pincode
    };
    address.push(addPayload);
    const pancard = [];
    const pancardPayload = {
      ID: this.profileForm.value.pancard.toUpperCase()
    };
    const phone = [];
    const phonePayload = {
      dialCode: "+91",
      number: this.profileForm.value.mobileNumber
    };
    phone.push(phonePayload);
    pancard.push(pancardPayload);
    const id = this.ls.get("userId");
    const payload = {
      name: name,
      DOB: this.birthDate,
      email: this.profileForm.value.emailAddress,
      address: address,
      socialIds: pancard,
      phone: phone
    };
    console.log("payload",payload)
    if (this.sendOtp) {
      if (this.profileForm.valid && this.otpForm.valid) {
        this.service
          .editCustomerProfile(
            this.adminId,
            this.userId,
            payload,
            this.otpForm.value.otp
          )
          .subscribe(
            res => {
              if (res.message === "success") {
                this.clearTimer();
                this.otpForm.reset();
                this.profileData(this.userId);
                this.service.snackbar(
                  "Profile has been edited successfully",
                  "close"
                );
                this.showOTPField = false;
                this.showEdit = false;
                this.showClose = false;
              }
            },
            err => {
              this.service.snackbar("Incorrect OTP", "close");
            }
          );
      } else {
        this.service.snackbar("Please fill the fields", "close");
      }
    } else {
      if (this.profileForm.valid) {
        this.service
          .editCustomerProfile(this.adminId, this.userId, payload)
          .subscribe(
            res => {
              if (res.message === "success") {
                this.clearTimer();
                this.otpForm.reset();
                this.profileData(this.userId);
                this.service.snackbar(
                  "Profile has been edited successfully",
                  "close"
                );
                this.showOTPField = false;
                this.showEdit = false;
                this.showClose = false;
              }
            },
            err => {
              this.service.snackbar("Incorrect OTP", "close");
            }
          );
      } else {
        this.service.snackbar("Please fill the fields", "close");
      }
    }
  }

  loadCreditScore(userId, productId) {
    this.service.getCreditReport(userId, productId).subscribe(
      async res => {
        if (res.message == "success" && res.data[0].reports) {
          this.noLoanReport = false;
          this.showLoanReport = true;
          this.noCreditReport = false;
          this.showCreditReport = true;
          this.showData = true;
          this.accountData = res.data[0].reports[0].report.finalReport;
          this.search();
          for (var call of this.accountData) {
            var a = call["type"];
            if (a == "LOAN") {
              this.noLoanReport = false;
              this.showLoanReport = true;
              break;
            } else {
              this.noLoanReport = true;
              this.showLoanReport = false;
              this.messageNoLoan = "NO LOAN ACCOUNT AVAILABLE";
            }
          }
          for (var call of this.accountData) {
            var a = call["type"];
            if (a == "CREDIT") {
              this.noCreditReport = false;
              this.showCreditReport = true;
              break;
            } else {
              this.noCreditReport = true;
              this.showCreditReport = false;
              this.messageNoCredit = "NO CREDIT CARD AVAILABLE";
            }
          }
          this.accountData.forEach(res => {
            this.reportDetails = res.reportDetails;
            this.paymentHistorydata = res.reportDetails.CAIS_Account_History;
            const day = res.reportDetails.Open_Date;
            this.dates.push(new Date(day));
            this.totalMissedPayments.push(
              res.reportDetails.CAIS_Account_History.length
            );
            res.reportDetails.CAIS_Account_History.forEach(res => {
              console.log("history count", res);
              this.totalCreditHistory.push(res);
            });
            // Credit Card Utilization
            if (res.type == "CREDIT") {
              const credit = res.reportDetails.Credit_Limit_Amount;
              const balance = res.reportDetails.Current_Balance;
              this.totalCardBalance.push(parseInt(balance));
              console.log('asasfasfasf12412412412', credit)
              this.totalCardLimit.push(parseInt(credit)|| 0);
            }
            let y;
            const sample = this.paymentHistorydata.reduce((r, { Year }) => {
              const year = Year;
              if (!r.some(o => o.group == Year)) {
                if (y != year) {
                  r.push({
                    Year,
                    groupItem: this.paymentHistorydata.filter(
                      v => v.Year == Year
                    )
                  });
                }
                y = year;
              }
              return r;
            }, []);
            this.reportDetails["PaymentYears"] = sample;
          });
          console.log("this.totalCreditHistory", this.totalCreditHistory);
            const missedPayment = await this.service.checkPassedDues(
              this.totalCreditHistory
            );
            console.log("missedPayment", missedPayment);
            this.payment = ((this.totalCreditHistory.length - missedPayment) / this.totalCreditHistory.length) * 100;
            this.paymentHistory = this.payment.toFixed(2)
            console.log("123 paymentHistory", this.paymentHistory);

          this.totalAccount = res.data[0].reports[0].report.finalReport.length;
          if (this.totalAccount == 0) {
            this.totalAccountWidth = "0%";
            this.totalAccountbg = "#e1a1ff";
            this.totalAccountMsg = "No account";
            this.totalAccountColor = "#263e8c";
            this.dangeraccountTotal = "state-danger";
            this.warningaccountTotal = "state-warning";
            this.successaccountTotal = "state-success";
            this.noinfoaccountTotal = "state-dark-selected";
          } else if (this.totalAccount >= 1 && this.totalAccount <= 2) {
            this.totalAccountWidth = "30%";
            this.totalAccountbg = "#e1a1ff";
            this.totalAccountMsg = "Low Impact";
            this.totalAccountColor = "#263e8c";
            this.dangeraccountTotal = "state-danger-selected";
            this.warningaccountTotal = "state-warning";
            this.successaccountTotal = "state-success";
            this.noinfoaccountTotal = "state-dark";
          } else if (this.totalAccount >= 3 && this.totalAccount <= 5) {
            this.dangeraccountTotal = "state-danger";
            this.warningaccountTotal = "state-warning";
            this.successaccountTotal = "state-success-selected";
            this.noinfoaccountTotal = "state-dark";
            this.totalAccountWidth = "60%";
            this.totalAccountbg = "#e1a1ff";
            this.totalAccountMsg = "Medium Impact";
            this.totalAccountColor = "#263e8c";
            this.dangeraccountTotal = "state-danger";
            this.warningaccountTotal = "state-warning-selected";
            this.successaccountTotal = "state-success";
            this.noinfoaccountTotal = "state-dark";
          } else if (this.totalAccount >= 6) {
            this.totalAccountWidth = "100%";
            this.totalAccountbg = "#e1a1ff";
            this.totalAccountMsg = "High Impact";
            this.totalAccountColor = "#263e8c";
            this.dangeraccountTotal = "state-danger";
            this.warningaccountTotal = "state-warning";
            this.successaccountTotal = "state-success-selected";
            this.noinfoaccountTotal = "state-dark";
          }
          // Credit Card Utilization
          var sumTotalCardBalance = this.totalCardBalance.reduce(
            (a, b) => a + b,
            0
          );
          var sumTotalCardLimit = this.totalCardLimit.reduce(
            (a, b) => a + b,
            0
          );
          var totalMissed = this.totalMissedPayments.reduce((a, b) => a + b, 0);
           
            const creditCardUtilCal =  (sumTotalCardBalance / sumTotalCardLimit)
            this.creditCardUtilise = creditCardUtilCal.toFixed(2);
          if (this.creditCardUtilise >= 0 && this.creditCardUtilise <= 29) {
            this.creditCardUtiliseWidth = "20%";
            this.creditCardUtilisebg = "#e1a1ff";
            this.creditCardUtiliseMsg = "Low Impact";
            this.creditCardUtiliseColor = "#263e8c";
            this.dangercardUtilisation = "state-danger";
            this.warningcardUtilisation = "state-warning";
            this.goodcardUtilisation = "state-good";
            this.successcardUtilisation = "state-success-selected";
            this.noinfocardUtilisation = "state-dark";
          } else if (
            this.creditCardUtilise >= 20 &&
            this.creditCardUtilise <= 49
          ) {
            this.creditCardUtiliseWidth = "40%";
            this.creditCardUtilisebg = "#e1a1ff";
            this.creditCardUtiliseMsg = "Medium Impact";
            this.creditCardUtiliseColor = "#263e8c";
            this.dangercardUtilisation = "state-danger";
            this.warningcardUtilisation = "state-warning";
            this.goodcardUtilisation = "state-good-selected";
            this.successcardUtilisation = "state-success";
            this.noinfocardUtilisation = "state-dark";
          } else if (
            this.creditCardUtilise >= 50 &&
            this.creditCardUtilise <= 79
          ) {
            this.creditCardUtiliseWidth = "65%";
            this.creditCardUtilisebg = "#e1a1ff";
            this.creditCardUtiliseMsg = "High Impact";
            this.creditCardUtiliseColor = "#263e8c";
            this.dangercardUtilisation = "state-danger";
            this.warningcardUtilisation = "state-warning-selected";
            this.goodcardUtilisation = "state-good";
            this.successcardUtilisation = "state-success";
            this.noinfocardUtilisation = "state-dark";
          } else if (this.creditCardUtilise >= 80) {
            this.creditCardUtiliseWidth = "100%";
            this.creditCardUtilisebg = "#e1a1ff";
            this.creditCardUtiliseMsg = "Very High Impact";
            this.creditCardUtiliseColor = "#263e8c";
            this.dangercardUtilisation = "state-danger-selected";
            this.warningcardUtilisation = "state-warning";
            this.goodcardUtilisation = "state-good";
            this.successcardUtilisation = "state-success";
            this.noinfocardUtilisation = "state-dark";
          } else {
            this.creditCardUtiliseWidth = "0%";
            this.creditCardUtilisebg = "#e1a1ff";
            this.creditCardUtiliseMsg = "No data";
            this.creditCardUtiliseColor = "#263e8c";
            this.dangercardUtilisation = "state-danger";
            this.warningcardUtilisation = "state-warning";
            this.goodcardUtilisation = "state-good";
            this.successcardUtilisation = "state-success";
            this.noinfocardUtilisation = "state-dark-selected";
          }
          var minDate = new Date(Math.min.apply(null, this.dates));
          const longestdata = this.datePipe.transform(minDate, "yyyy-MM-dd");
          const currentday = moment(this.myDate);
          this.diffInMonths = Math.abs(currentday.diff(longestdata, "month"));
          this.diffInYear = Math.abs(currentday.diff(longestdata, "year"));
          var diff = moment.preciseDiff(currentday, moment(minDate), true);
          this.years = diff.years;
          this.monthdiff = diff.months;
          if (this.diffInYear < 3) {
            this.diffInYearWidth = "25%";
            this.diffInYearbg = "#e1a1ff";
            this.diffInYearMsg = "Low Impact";
            this.diffInYearColor = "#263e8c";
            this.dangeroldestYear = "state-danger-selected";
            this.warningoldestYear = "state-warning";
            this.goodoldestYear = "state-good";
            this.successoldestYear = "state-success";
            this.noinfooldestYear = "state-dark";
          } else if (this.diffInYear >= 3 && this.diffInYear <= 5) {
            this.diffInYearWidth = "50%";
            this.diffInYearbg = "#e1a1ff";
            this.diffInYearMsg = "Medium Impact";
            this.diffInYearColor = "#263e8c";
            this.dangeroldestYear = "state-danger";
            this.warningoldestYear = "state-warning-selected";
            this.goodoldestYear = "state-good";
            this.successoldestYear = "state-success";
            this.noinfooldestYear = "state-dark";
          } else if (this.diffInYear >= 5 && this.diffInYear <= 7) {
            this.diffInYearWidth = "75%";
            this.diffInYearbg = "#e1a1ff";
            this.diffInYearMsg = "High Impact";
            this.diffInYearColor = "#263e8c";
            this.dangeroldestYear = "state-danger";
            this.warningoldestYear = "state-warning";
            this.goodoldestYear = "state-good-selected";
            this.successoldestYear = "state-success";
            this.noinfooldestYear = "state-dark";
          } else if (this.diffInYear > 7) {
            this.diffInYearWidth = "100%";
            this.diffInYearbg = "#e1a1ff";
            this.diffInYearMsg = "Very High Impact";
            this.diffInYearColor = "#263e8c";
            this.dangeroldestYear = "state-danger";
            this.warningoldestYear = "state-warning";
            this.goodoldestYear = "state-good";
            this.successoldestYear = "state-success-selected";
            this.noinfooldestYear = "state-dark";
          } else {
            this.diffInYearWidth = "0%";
            this.diffInYearbg = "#e1a1ff";
            this.diffInYearMsg = "No account";
            this.diffInYearColor = "#263e8c";
            this.dangeroldestYear = "state-danger";
            this.warningoldestYear = "state-warning";
            this.goodoldestYear = "state-good";
            this.successoldestYear = "state-success";
            this.noinfooldestYear = "state-dark-selected";
          }
          // this.paymentHistory = Math.round(
          //   ((this.diffInMonths - totalMissed) / this.diffInMonths) * 100
          // );
          
          if (this.paymentHistory < 50) {
            this.paymentHistoryWidth = "50%";
            this.paymentHistorybg = "#e1a1ff";
            this.paymentHistoryMsg = "Low Impact";
            this.paymentHistoryColor = "#263e8c";
            this.dangerpaymenthistory = "state-danger-selected";
            this.warningpaymenthistory = "state-warning";
            this.goodpaymenthistory = "state-good";
            this.successpaymenthistory = "state-success";
            this.noinfopaymenthistory = "state-dark";
          } else if (this.paymentHistory >= 50 && this.paymentHistory <= 90) {
            this.paymentHistoryWidth = "70%";
            this.paymentHistorybg = "#e1a1ff";
            this.paymentHistoryMsg = "Medium Impact";
            this.paymentHistoryColor = "#263e8c";
            this.dangerpaymenthistory = "state-danger";
            this.warningpaymenthistory = "state-warning-selected";
            this.goodpaymenthistory = "state-good";
            this.successpaymenthistory = "state-success";
            this.noinfopaymenthistory = "state-dark";
          } else if (this.paymentHistory >= 90 && this.paymentHistory <= 99) {
            this.paymentHistoryWidth = "95%";
            this.paymentHistorybg = "#e1a1ff";
            this.paymentHistoryMsg = "High Impact";
            this.paymentHistoryColor = "#263e8c";
            this.dangerpaymenthistory = "state-danger";
            this.warningpaymenthistory = "state-warning";
            this.goodpaymenthistory = "state-good-selected";
            this.successpaymenthistory = "state-success";
            this.noinfopaymenthistory = "state-dark";
          } else if (this.paymentHistory == 100) {
            this.paymentHistoryWidth = "100%";
            this.paymentHistorybg = "#e1a1ff";
            this.paymentHistoryMsg = "Very High Impact";
            this.paymentHistoryColor = "#263e8c";
            this.dangerpaymenthistory = "state-danger";
            this.warningpaymenthistory = "state-warning";
            this.goodpaymenthistory = "state-good";
            this.successpaymenthistory = "state-success-selected";
            this.noinfopaymenthistory = "state-dark";
          } else {
            this.paymentHistoryWidth = "0%";
            this.paymentHistorybg = "#e1a1ff";
            this.paymentHistoryMsg = "No data";
            this.paymentHistoryColor = "#263e8c";
            this.dangerpaymenthistory = "state-danger";
            this.warningpaymenthistory = "state-warning";
            this.goodpaymenthistory = "state-good";
            this.successpaymenthistory = "state-success";
            this.noinfopaymenthistory = "state-dark-selected";
          }
        } else {
          this.messageNoCredit = res.data[0].errorString;
          this.messageNoLoan = res.data[0].errorString;
          this.messageNoCustomer = res.data[0].errorString;
          this.noLoanReport = true;
          this.showLoanReport = false;
          this.noCreditReport = true;
          this.showCreditReport = false;
          this.showData = false;
          this.service.snackbar(res.data[0].errorString);
        }
      },
      err => {}
    );
  }

  chkValue(val, data) {
    let retrunValue = "";
    data.groupItem.some(el => {
      if (JSON.stringify(el.Month) == JSON.stringify(val)) {
        retrunValue = el.Days_Past_Due;
      }
    });
    return retrunValue;
  }

  fromDate() {
    this.tillDate = this.scoreTrend.value.fromDate;
  }

  submit() {
    if (this.scoreTrend.valid) {
      this.chartData = [];
      this.chartLabels = [];
      let fromDate = this.scoreTrend.value.fromDate;
      let toDate = this.scoreTrend.value.toDate;
      this.startDate = this.datePipe.transform(fromDate, "yyyy-MM-dd");
      this.endDate = this.datePipe.transform(toDate, "yyyy-MM-dd");
      this.search();
    } else {
      this.service.snackbar("Fill all the fields");
    }
  }

  handleEvent(e) {
    if (e.text === "00:00") {
      this.countdownExpired = true;
    } else {
      this.countdownExpired = false;
    }
  }

  search() {
    let prodId = this.productId;
    this.service
      .getScoreTrend(this.userId, prodId, this.startDate, this.endDate)
      .subscribe(res => {
        this.graphResponse = res;
        this.second = res.data;
        this.second.forEach(res => {
          this.resdates = this.datePipe.transform(res.date, "MMM yyyy");
          this.chartLabels.push(this.resdates);
          this.lineChartLabels = this.chartLabels;
        });
        this.graphResponse.data.forEach(res => {
          let score = res.reports;
          score.forEach(res => {
            let scoreReport = res.report;
            this.finaldata =
              scoreReport.rawReport.INProfileResponse.SCORE.BureauScore;
            this.chartData.push(this.finaldata);
            this.lineChartData[0].data = this.chartData;
            this.lineChartData = [
              {
                data: [...this.chartData],
                label: "Credit Score",
                fill: false,
                lineTension: 0,
                backgroundColor: "white",
                pointRadius: 4
              }
            ];
          });
        });
      });
  }
}
