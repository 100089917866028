import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { scorocketService } from "src/app/providers/scorocket.service";
import { LocalStorageService } from "ngx-localstorage";

@Component({
  selector: "app-credit-utilisation",
  templateUrl: "./credit-utilisation.component.html",
  styleUrls: ["./credit-utilisation.component.scss"]
})
export class CreditUtilisationComponent implements OnInit {
  myDate = new Date();
  cardUtilisation: string;
  successcardUtilisation: string;
  dangercardUtilisation: string;
  warningcardUtilisation: string;
  noinfocardUtilisation: string;
  goodcardUtilisation: string;
  noCreditReport: boolean;
  messageNoCustomer: string;
  showCreditReport: boolean;
  accounts: any;
  userId: string;

  constructor(
    public router: Router,
    public ls: LocalStorageService,
    public service: scorocketService
  ) {}

  ngOnInit() {
    this.userId = this.ls.get("userId");
    this.getCreditScore(this.userId);
    const scorefactor = JSON.parse(this.ls.get("scoredata"));
    this.cardUtilisation = scorefactor.data3;
    this.validateCreditScore(scorefactor.data3);
  }

  goBack() {
    this.router.navigateByUrl("user/(page:scorefactor)");
  }

  validateCreditScore(e) {
    if (e == "" || e == undefined || e == null) {
      this.dangercardUtilisation = "state-danger";
      this.warningcardUtilisation = "state-warning";
      this.goodcardUtilisation = "state-good";
      this.successcardUtilisation = "state-success";
      this.noinfocardUtilisation = "state-dark-selected";
    } else if (e >= 0 && e <= 29) {
      this.dangercardUtilisation = "state-danger";
      this.warningcardUtilisation = "state-warning";
      this.goodcardUtilisation = "state-good";
      this.successcardUtilisation = "state-success-selected";
      this.noinfocardUtilisation = "state-dark";
    } else if (e >= 20 && e <= 49) {
      this.dangercardUtilisation = "state-danger";
      this.warningcardUtilisation = "state-warning";
      this.goodcardUtilisation = "state-good-selected";
      this.successcardUtilisation = "state-success";
      this.noinfocardUtilisation = "state-dark";
    } else if (e >= 50 && e <= 79) {
      this.dangercardUtilisation = "state-danger";
      this.warningcardUtilisation = "state-warning-selected";
      this.goodcardUtilisation = "state-good";
      this.successcardUtilisation = "state-success";
      this.noinfocardUtilisation = "state-dark";
    } else if (e >= 80) {
      this.dangercardUtilisation = "state-danger-selected";
      this.warningcardUtilisation = "state-warning";
      this.goodcardUtilisation = "state-good";
      this.successcardUtilisation = "state-success";
      this.noinfocardUtilisation = "state-dark";
    }
  }

  getCreditScore(userId) {
    this.service.getProductId(userId).subscribe(res => {
      if (res.message == "success") {
        if (res.data.length > 0 && res.data[0].productId) {
          this.loadCreditScore(userId, res.data[0].productId);
        } else {
          this.noCreditReport = true;
          this.noCreditReport = false;
          this.messageNoCustomer =
            "No Data Available! Please purchase your plan";
        }
      }
    });
  }

  loadCreditScore(userId, productId) {
    this.service.getCreditReport(userId, productId).subscribe(
      res => {
        if (res.message == "success" && res.data.reports[0].report.finalReport[0].reportDetails.GetCustomerAssetsResponse.GetCustomerAssetsSuccess.Asset.TrueLinkCreditReport.TradeLinePartition) {
          this.noCreditReport = false;
          this.showCreditReport = true;
          if (res.data.reports[0].report.finalReport[0].reportDetails.GetCustomerAssetsResponse.GetCustomerAssetsSuccess.Asset.TrueLinkCreditReport.TradeLinePartition) {
            this.accounts = res.data.reports[0].report.finalReport[0].reportDetails.GetCustomerAssetsResponse.GetCustomerAssetsSuccess.Asset.TrueLinkCreditReport.TradeLinePartition;
          } else {
          }
        } else {
          this.messageNoCustomer = res.data[0].errorString;
          this.noCreditReport = true;
          this.showCreditReport = false;
          this.service.snackbar(res.data[0].errorString);
        }
      },
      err => {}
    );
  }
}
