import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { scorocketService } from "src/app/providers/scorocket.service";
import { ActivatedRoute, Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import { LocalStorageService } from "ngx-localstorage";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from "@angular/material/dialog";
import { DashboardOtpComponet } from "../../../app/pages/dashboard/dashboard-otp/dashboard-otp.component";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"]
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  disableBtn = false;
  agreeCondition = false;
  @ViewChild("inputRef", { static: true }) inputRef;
  referral: any;
  href: string;
  referralCode: string;
  checkboxValue: boolean = false;
  constructor(
    public fb: FormBuilder,
    private service: scorocketService,
    private router: Router,
    public datepipe: DatePipe,
    public ls: LocalStorageService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute
  ) { }
  ngOnInit() {
    this.registerForm = this.fb.group({
      fullName: [
        "",
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(25),
          Validators.pattern("[a-z A-Z]+")
        ]
      ],
      lastName: [
        "",
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(25),
          Validators.pattern("[a-z A-Z]+")
        ]
      ],
      selectedGender: ['', Validators.required],
      email: [
        "",
        [
          Validators.required,
          Validators.email,
          Validators.pattern("^[a-zA-Z0-9._-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
        ]
      ],
      phoneNumber: [
        "",
        [Validators.required, Validators.pattern("[0-9]\\d{9}")]
      ],
      pan: [
        "",
        [
          Validators.required,
          Validators.pattern("^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$")
        ]
      ],
      dob: [],
      line1: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      postalCode: ['', [Validators.required, Validators.pattern(/^\d{6}$/)]],
      terms: ["", [Validators.required]],
      referral: []
    });
    this.referral = this.ls.get("referralCode");
    console.log(this.referral)
    // listen to input changes
    this.registerForm.get("pan").valueChanges.subscribe(val => {
      const elRef = this.inputRef.nativeElement;
      // get stored original unmodified value (not including last change)
      const orVal = elRef.getAttribute("data-model-value") || "";
      // modify new value to be equal to the original input (including last change)
      val = val.replace(orVal.toUpperCase(), orVal);
      // store original unmodified value (including last change)
      elRef.setAttribute("data-model-value", val);
      // set view value using DOM value property
      elRef.value = val.toUpperCase();
      // update module without emitting event and without changing view model
      this.registerForm.get("pan").setValue(val, {
        emitEvent: false,
        emitModelToViewChange: false
      });
    });
    this.copyUrl();
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.plan) {
        this.ls.set("plan", params.plan)
      }
    })
  }
  copyUrl() {
    this.href = this.router.url;
    this.referralCode = this.href.slice(21, 32) || "";
  }

  openTermConditionDialog(): void {
    const dialogRef = this.dialog.open(RegTermsDialog, {
      width: "50%",
      height: "80vh",
      hasBackdrop: false,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.checkboxValue = true;
      } else if (result === false) {
        this.checkboxValue = false;
      }
    });
  }
  datevalue
  formatDate(inputDate: string): any {
    const dateObject = new Date(inputDate);

    const year = dateObject.getFullYear();
    const month = ('0' + (dateObject.getMonth() + 1)).slice(-2); // Months are zero-based
    const day = ('0' + dateObject.getDate()).slice(-2);

    this.datevalue = `${year}-${month}-${day}`;
  }

  //stateCode Dropdown
  states = [
    { id: '01', name: 'Jammu & Kashmir' },
    { id: '02', name: 'Himachal Pradesh' },
    { id: '03', name: 'Punjab' },
    { id: '04', name: 'Chandigarh' },
    { id: '05', name: 'Uttaranchal' },
    { id: '06', name: 'Haryana' },
    { id: '07', name: 'Delhi' },
    { id: '08', name: 'Rajasthan' },
    { id: '09', name: 'Uttar Pradesh' },
    { id: 10, name: 'Bihar' },
    { id: 11, name: 'Sikkim' },
    { id: 12, name: 'Arunachal Pradesh' },
    { id: 13, name: 'Nagaland' },
    { id: 14, name: 'Manipur' },
    { id: 15, name: 'Mizoram' },
    { id: 16, name: 'Tripura' },
    { id: 17, name: 'Meghalaya' },
    { id: 18, name: 'Assam' },
    { id: 19, name: 'West Bengal' },
    { id: 20, name: 'Jharkhand' },
    { id: 21, name: 'Orissa' },
    { id: 22, name: 'Chhattisgarh' },
    { id: 23, name: 'Madhya Pradesh' },
    { id: 24, name: 'Gujarat' },
    { id: 25, name: 'Daman & Diu' },
    { id: 26, name: 'Dadra & Nagar Haveli' },
    { id: 27, name: 'Maharashtra' },
    { id: 28, name: 'Andhra Pradesh' },
    { id: 29, name: 'Karnataka' },
    { id: 30, name: 'Goa' },
    { id: 31, name: 'Lakshadweep' },
    { id: 32, name: 'Kerala' },
    { id: 33, name: 'Tamil Nadu' },
    { id: 34, name: 'Pondicherry' },
    { id: 35, name: 'Andaman & Nicobar Islands' },
    { id: 36, name: 'Telangana' },
    { id: 99, name: 'APO Address' }


  ];
  selectedState: any;
  // gender Dropdown
  genders = ['Male', 'Female'];

  tempRegis() {
    this.dialog.open(DashboardOtpComponet, {
      width: "700px",
      disableClose: true,
      data: {
        showupi: false
      }

    });
  }


  register() {
    this.formatDate(this.registerForm.value.dob)
    const refer = this.registerForm.value.referral;
    // if (!this.registerForm.value.terms) {
    //   this.service.snackbar("Agree to terms and condition!");
    // }
    if (this.registerForm.value.terms) {
      if (this.registerForm.valid) {
        console.log("valid register")
        const name = this.registerForm.value.fullName.split(" ");
        const payload = {
          name: {
            first: name[0] ? name[0] : "",
            last: this.registerForm.value.lastName
          },
          role: 'customer',
          userType: 'customer',
          email: this.registerForm.value.email,
          gender: this.registerForm.value.selectedGender,
          address: [
            {
              type: "0",
              line1: this.registerForm.value.line1,
              city: this.registerForm.value.city,
              state: this.registerForm.value.state.name,
              postalCode: this.registerForm.value.postalCode,
              Region: this.registerForm.value.state.id
            }
          ],
          socialIds: [
            {
              type: "pan",
              ID: this.registerForm.value.pan.toUpperCase(),
              nameOnId: "",
              DOI: this.datevalue
            }
          ],
          phone: [
            {
              dialCode: "+91",
              number: this.registerForm.value.phoneNumber
            }
          ],
          referredby: this.registerForm.value.referral || ""
        };
        this.disableBtn = true;
        this.service.register(payload).subscribe(
          res => {
            console.log(res);
            
            if (
              res.message === "success" &&
              res.data.fullfullResponse &&
              res.data.fullfullResponse.data &&
              res.data.fullfullResponse.data.FulfillOfferResponse &&
              res.data.fullfullResponse.data.FulfillOfferResponse.FulfillOfferSuccess &&
              res.data.fullfullResponse.data.FulfillOfferResponse.FulfillOfferSuccess.Status
            ) {
              const status = res.data.fullfullResponse.data.FulfillOfferResponse.FulfillOfferSuccess.Status;
              /* OTP verification for CIBI */


              if (status === "Pending" || status === "Success") {

                this.router.navigate(["/password"], {
                  queryParams: {
                    id: res.data.userId,
                    otpExpiration: res.data.expiration
                  }

                }); this.service.snackbar("Registered successfully!")
              } else {
                this.service.snackbar("Unexpected status: " + status);
              }
            } else if (
              res.message === "success" &&
              res.data.fullfullResponse &&
              res.data.fullfullResponse.data &&
              res.data.fullfullResponse.data.FulfillOfferResponse &&
              res.data.fullfullResponse.data.FulfillOfferResponse.FulfillOfferError &&
              res.data.fullfullResponse.data.FulfillOfferResponse.FulfillOfferError.Failure
            ) {
              const failureEnum =
                res.data.fullfullResponse.data.FulfillOfferResponse.FulfillOfferError.Failure.FailureEnum;

              if (failureEnum === "NO_HIT") {
                this.service.snackbar("Sorry! You don't have a credit history");
              } else {
                // Handle other failure cases if needed
                this.service.snackbar(
                  "Unexpected failure: " +
                  res.data.fullfullResponse.data.FulfillOfferResponse.FulfillOfferError.Failure.Message
                );
              }
            } else {
              this.service.snackbar("Unexpected response structure");
            }

          },
          err => {
            console.log(err.error.message);
            this.disableBtn = false;
            console.log('errr',err)
            if (err.error.message) {
              if (err.error.message.includes("expected `email` to be unique.") || err.error.message.includes('E11000 duplicate key error collection')) {
                this.service.snackbar("Email already exists!");
                return;
              } 
              else if (err.error.message.includes("NO_HIT")) {
                this.service.snackbar("Sorry! You don't have a credit history");
              }
              else if (
                err.error.message.includes("expected `phoneNumber` to be unique.")
              ) {
                this.service.snackbar("Phone Number already exists!");
              }
            } else {
              this.service.snackbar("Registration Failed!");
            }
          }
        );
      } else {
        this.service.snackbar("Please fill the required fields!", "close");
      }
    } else {
      this.service.snackbar("Agree to terms and condition!");
    }
  }
}
@Component({
  selector: "reg-terms-dialog",
  templateUrl: "reg-terms-dialog.html",
  styleUrls: ["./register.component.scss"]
})
export class RegTermsDialog implements OnInit {
  isDialogOpen = true;
  constructor(
    private dialogRef: MatDialogRef<RegTermsDialog> // @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }
  ngOnInit(): void {
    document.addEventListener('keydown', this.handleEscapeKey.bind(this));
  }
  private handleEscapeKey(event: KeyboardEvent): void {
    if (this.isDialogOpen && event.key === 'Escape') {
      event.preventDefault();
    }
  }
  onAccept() {
    this.dialogRef.close(true);
  }

  onDecline() {
    this.dialogRef.close(false);
  }
  ngOnDestroy(): void {
    document.removeEventListener('keydown', this.handleEscapeKey.bind(this));
  }
}