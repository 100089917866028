import { Component, OnInit } from '@angular/core';
import creditscore from '../../creditscore.json';
import { LocalStorageService } from 'ngx-localstorage';
import { scorocketService } from 'src/app/providers/scorocket.service';

@Component({
  selector: 'app-dispute',
  templateUrl: './dispute.component.html',
  styleUrls: ['./dispute.component.scss']
})
export class DisputeComponent implements OnInit {
  public creditList : any;
  panelOpenState = false;
  list=['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'];
  userName: any;
  userId: any;
  noCreditReport: boolean;
  showCreditReport: boolean;
  messageNoCustomer: any;
  formattedDate: string;

  constructor(public service: scorocketService, private ls: LocalStorageService) { }

  ngOnInit() {
    this.userName = this.ls.get('userName');
    this.userId = this.ls.get('userId');
    this.getCreditScore(this.userId);
  }

  getCreditScore(userId) {
    this.service.getProductId(userId).subscribe(res => {
      if (res.message == 'success') {
        if (res.data.length > 0 && res.data[0].productId) {
          this.loadCreditScore(userId, res.data[0].productId)
      }else{
        this.noCreditReport = true;
        this.showCreditReport = false;
        this.messageNoCustomer = "No Data Available! Please purchase your plan"
      }
    }
  })
}
DateOpened
DateReported
monthlyPayStatus
  loadCreditScore(userId, productId) {
    this.service.getCreditReport(userId, productId).subscribe(res => {
      if (res.message == 'success') {
        this.noCreditReport = false;
        this.showCreditReport = true;
        if (res.data.reports[0].report.finalReport[0].reportDetails.GetCustomerAssetsResponse.GetCustomerAssetsSuccess.Asset.TrueLinkCreditReport.TradeLinePartition) {
          this.creditList = res.data.reports[0].report.finalReport[0].reportDetails.GetCustomerAssetsResponse.GetCustomerAssetsSuccess.Asset.TrueLinkCreditReport.TradeLinePartition.Tradeline 
          this.DateOpened = this.creditList.dateOpened
          this.DateReported= this.creditList.dateReported;
          
          this.monthlyPayStatus = this.creditList.GrantedTrade.PayStatusHistory.MonthlyPayStatus.date
        } else {
          // this.validateCreditScore(0) 
        }
      } else {
        this.messageNoCustomer = res.data[0].errorString
        this.noCreditReport = true;
        this.showCreditReport = false;
        this.service.snackbar(res.data[0].errorString);
      }
    }, err => {

    });
  }
  formatDate(dateString: string): string {
    const dateWithoutTimeZone = dateString.replace(/(\+\d{2}:\d{2})$/, '');

  const date = new Date(dateWithoutTimeZone);
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);

  return `${year}-${month}-${day}`;
  }

  RowClicked(){
    console.log("row clicked");
    
  }
}
