import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LocalStorageService } from "ngx-localstorage";
import { scorocketService } from "src/app/providers/scorocket.service";
import * as moment from "moment";
import "moment-precise-range-plugin";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-score-factor",
  templateUrl: "./score-factor.component.html",
  styleUrls: ["./score-factor.component.scss"]
})
export class ScoreFactorComponent implements OnInit {
  noCreditReport: boolean;
  totalCreditHistory = [];
  monthlyPayStatusArray = [];
  showCreditReport: boolean;
  messageNoCustomer: any;
  userName: string;
  userId: string;
  totalAccount: any;
  accounts: any;
  myDate = new Date();
  diffInMonths: number;
  diffInYear: number;
  dates = [];
  scoreData: any = {};
  totalCardBalance: any = [];
  totalCardLimit: any = [];
  creditCardUtilise: any;
  totalMissedPayments: any = [];
  paymentHistory: any;
  paymentHistoryWidth: any;
  creditCardUtiliseWidth: string;
  totalAccountWidth: string;
  totalAccountbg: string;
  totalAccountMsg: string;
  totalAccountColor: string;
  diffInYearWidth: string;
  diffInYearbg: string;
  diffInYearMsg: string;
  diffInYearColor: string;
  paymentHistoryInPercent: string;
  paymentHistorybg: string;
  paymentHistoryMsg: string;
  paymentHistoryColor: string;
  creditCardUtilisebg: string;
  creditCardUtiliseMsg: string;
  creditCardUtiliseColor: string;
  years: number;
  months: number;
  payment: number;
  missedPayment: any;

  constructor(
    private datePipe: DatePipe,
    private router: Router,
    public service: scorocketService,
    private ls: LocalStorageService
  ) { }

  ngOnInit() {
    this.userName = this.ls.get("userName");
    this.userId = this.ls.get("userId");
    this.getCreditScore(this.userId);
  }

  learnMoreLink(n) {
    if (n == "1") {
      this.router.navigateByUrl("user/(page:scoreTotalAccount)");
    } else if (n == "2") {
      this.router.navigateByUrl("user/(page:scoreCreditHistory)");
    } else if (n == "3") {
      this.router.navigateByUrl("user/(page:scoreCreditUtilisation)");
    } else if (n == "4") {
      this.router.navigateByUrl("user/(page:scorePaymentHistory)");
    }
  }

  getCreditScore(userId) {
    this.service.getProductId(userId).subscribe(res => {
      if (res.message == "success") {
        if (res.data.length > 0 && res.data[0].productId) {
          this.loadCreditScore(userId, res.data[0].productId);
        } else {
          this.noCreditReport = true;
          this.showCreditReport = false;
          this.messageNoCustomer =
            "No Data Available! Please purchase your plan";
        }
      }
    });
  }

  loadCreditScore = async (userId, productId) => {
    this.service.getCreditReport(userId, productId).subscribe(
      async res => {
        if (res.message == "success") {
          this.noCreditReport = false;
          this.showCreditReport = true;
          if (res.data.reports[0].report.finalReport[0].reportDetails.GetCustomerAssetsResponse.GetCustomerAssetsSuccess.Asset.TrueLinkCreditReport.TradeLinePartition) {
            this.accounts = res.data.reports[0].report.finalReport[0].reportDetails.GetCustomerAssetsResponse.GetCustomerAssetsSuccess.Asset.TrueLinkCreditReport.TradeLinePartition;
            // ashwini code(calculating the paymets)
            // if(this.accounts.reportType == 'PERSONAL LOAN' ){
            //   console.log("hiiii")
            console.log('acc',this.accounts,this.accounts.length)
    
            this.accounts.forEach(res => {
              this.missedPayment = parseInt(res.Tradeline.GrantedTrade.amountPastDue)
              this.monthlyPayStatusArray.push(res.Tradeline.GrantedTrade.PayStatusHistory.MonthlyPayStatus)
              this.monthlyPayStatusArray.forEach(res => {
                console.log("history count", res);
                this.totalCreditHistory.push(res);
              });
            });
            console.log("this.totalCreditHistory", this.totalCreditHistory);
            // const missedPayment = await this.service.checkPassedDues(this.totalCreditHistory);
            console.log("missedPayment", this.missedPayment);
            this.payment = ((this.totalCreditHistory.length - this.missedPayment) / this.totalCreditHistory.length) * 100;
            this.paymentHistory = this.payment.toFixed(2)
            console.log("123 paymentHistory", this.paymentHistory);
            // }
            // end(calculation)

            // Total Account
            this.totalAccount = this.accounts.length
            console.log('lll',this.totalAccount)
            if (this.totalAccount == 0) {
              this.totalAccountWidth = "0%";
              this.totalAccountbg = "#175cff";
              this.totalAccountMsg = "No account";
              this.totalAccountColor = "#263e8c";
            } else if (this.totalAccount >= 1 && this.totalAccount <= 2) {
              this.totalAccountWidth = "30%";
              this.totalAccountbg = "#175cff";
              this.totalAccountMsg = "Low Impact";
              this.totalAccountColor = "#263e8c";
            } else if (this.totalAccount >= 3 && this.totalAccount <= 5) {
              this.totalAccountWidth = "60%";
              this.totalAccountbg = "#175cff";
              this.totalAccountMsg = "Medium Impact";
              this.totalAccountColor = "#263e8c";
            } else if (this.totalAccount >= 6) {
              this.totalAccountWidth = "100%";
              this.totalAccountbg = "#175cff";
              this.totalAccountMsg = "High Impact";
              this.totalAccountColor = "#263e8c";
            }
            this.accounts.forEach(element => {
              // Age of Credit History
              console.log('ele',element)
              const day = element.Tradeline.dateOpened.slice(0, -6)
              console.log('day',day)
              this.dates.push(new Date(day));
              this.totalMissedPayments.push(
                element.Tradeline.GrantedTrade.PayStatusHistory.MonthlyPayStatus.length
              );
              // Credit Card Utilization
              if (element.accountTypeSymbol == "07") {
                const credit = element.dateOpened;
                const balance = element.Tradeline.currentBalance;
                this.totalCardBalance.push(parseInt(balance));
                this.totalCardLimit.push(parseInt(credit) || 0);
              }
            });
            // Credit Card Utilization
            var sumTotalCardBalance = this.totalCardBalance.reduce(
              (a, b) => a + b,
              0
            );
            var sumTotalCardLimit = this.totalCardLimit.reduce(
              (a, b) => a + b,
              0
            );
            var totalMissed = this.totalMissedPayments.reduce(
              (a, b) => a + b,
              0
            );
            console.log('summm',sumTotalCardBalance,sumTotalCardLimit)
            const creditCardUtilCal =  (sumTotalCardBalance / sumTotalCardLimit)
            this.creditCardUtilise = creditCardUtilCal.toFixed(2);

            if (this.creditCardUtilise >= 0 && this.creditCardUtilise <= 29) {
              this.creditCardUtiliseWidth = "20%";
              this.creditCardUtilisebg = "#175cff";
              this.creditCardUtiliseMsg = "Low Impact";
              this.creditCardUtiliseColor = "#263e8c";
            } else if (
              this.creditCardUtilise >= 20 &&
              this.creditCardUtilise <= 49
            ) {
              this.creditCardUtiliseWidth = "40%";
              this.creditCardUtilisebg = "#175cff";
              this.creditCardUtiliseMsg = "Medium Impact";
              this.creditCardUtiliseColor = "#263e8c";
            } else if (
              this.creditCardUtilise >= 50 &&
              this.creditCardUtilise <= 79
            ) {
              this.creditCardUtiliseWidth = "65%";
              this.creditCardUtilisebg = "#175cff";
              this.creditCardUtiliseMsg = "High Impact";
              this.creditCardUtiliseColor = "#263e8c";
            } else if (this.creditCardUtilise >= 80) {
              this.creditCardUtiliseWidth = "100%";
              this.creditCardUtilisebg = "#175cff";
              this.creditCardUtiliseMsg = "Very High Impact";
              this.creditCardUtiliseColor = "#263e8c";
            } else {
              this.creditCardUtiliseWidth = "0%";
              this.creditCardUtilisebg = "#175cff";
              this.creditCardUtiliseMsg = "No data";
              this.creditCardUtiliseColor = "#263e8c";
            }
            var minDate = new Date(Math.min.apply(null, this.dates));
            const longestdata = this.datePipe.transform(minDate, "yyyy-MM-dd");
            const currentday = moment(this.myDate);
            this.diffInMonths = Math.abs(
              currentday.diff(longestdata, "months")
            );
            this.diffInYear = Math.abs(currentday.diff(longestdata, "year"));
            var diff = moment.preciseDiff(currentday, moment(minDate), true);
            this.years = diff.years;
            this.months = diff.months;
            if (this.diffInYear == 0) {
              this.diffInYearWidth = "0%";
              this.diffInYearbg = "#175cff";
              this.diffInYearMsg = "No account";
              this.diffInYearColor = "#263e8c";
            } else if (this.diffInYear < 3) {
              this.diffInYearWidth = "25%";
              this.diffInYearbg = "#175cff";
              this.diffInYearMsg = "Low Impact";
              this.diffInYearColor = "#263e8c";
            } else if (this.diffInYear >= 3 && this.diffInYear <= 5) {
              this.diffInYearWidth = "50%";
              this.diffInYearbg = "#175cff";
              this.diffInYearMsg = "Medium Impact";
              this.diffInYearColor = "#263e8c";
            } else if (this.diffInYear >= 5 && this.diffInYear <= 7) {
              this.diffInYearWidth = "75%";
              this.diffInYearbg = "#175cff";
              this.diffInYearMsg = "High Impact";
              this.diffInYearColor = "#263e8c";
            } else if (this.diffInYear > 7) {
              this.diffInYearWidth = "100%";
              this.diffInYearbg = "#175cff";
              this.diffInYearMsg = "Very High Impact";
              this.diffInYearColor = "#263e8c";
            }
            // this.paymentHistory = Math.round(
            //   ((this.diffInMonths - totalMissed) / this.diffInMonths) * 100
            // );
            if (this.paymentHistory < 50) {
              this.paymentHistoryWidth = "50%";
              this.paymentHistorybg = "#175cff";
              this.paymentHistoryMsg = "Low Impact";
              this.paymentHistoryColor = "#263e8c";
            } else if (this.paymentHistory >= 50 && this.paymentHistory <= 90) {
              this.paymentHistoryWidth = "70%";
              this.paymentHistorybg = "#175cff";
              this.paymentHistoryMsg = "Medium Impact";
              this.paymentHistoryColor = "#263e8c";
            } else if (this.paymentHistory >= 90 && this.paymentHistory <= 99) {
              this.paymentHistoryWidth = "95%";
              this.paymentHistorybg = "#175cff";
              this.paymentHistoryMsg = "High Impact";
              this.paymentHistoryColor = "#263e8c";
            } else if (this.paymentHistory == 100) {
              this.paymentHistoryWidth = "100%";
              this.paymentHistorybg = "#175cff";
              this.paymentHistoryMsg = "Very High Impact";
              this.paymentHistoryColor = "#263e8c";
            } else {
              this.paymentHistoryWidth = "0%";
              this.paymentHistorybg = "#175cff";
              this.paymentHistoryMsg = "No data";
              this.paymentHistoryColor = "#263e8c";
            }
            this.scoreData = {
              data1: this.totalAccount,
              data2: this.diffInYear,
              data3: this.creditCardUtilise,
              data4: this.paymentHistory
            };
            this.ls.set("scoredata", JSON.stringify(this.scoreData));
          } else {
          }
        } else {
          this.messageNoCustomer = res.data[0].errorString;
          this.noCreditReport = true;
          this.showCreditReport = false;
          this.service.snackbar(res.data[0].errorString);
        }
      },
      err => { }
    );
  };
}
