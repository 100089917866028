import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "scorocket-primary-button",
  templateUrl: "./scorocket-primary-button.component.html",
  styleUrls: ["./scorocket-primary-button.component.scss"]
})
export class scorocketPrimaryButtonComponent implements OnInit {
  @Input("button-txt") text = "";
  @Input("disbale-btn") disbaleBtn;
  @Input("btn-type") btnType = "submit";
  @Output("on-click") onclick = new EventEmitter();
  @Input("btn-class") btnClass = "btn btn-sm btn-primary col btn-sub";
  @Input("loading") loading = false;
  constructor() {}

  ngOnInit() {}

  btnClick = () => {
    this.onclick.emit();
  };
}
