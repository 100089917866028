import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { scorocketService } from 'src/app/providers/scorocket.service';

@Component({
  selector: 'app-submit-query',
  templateUrl: './submit-query.component.html',
  styleUrls: ['./submit-query.component.scss']
})
export class SubmitQueryComponent implements OnInit {
  queryForm: FormGroup;
  submitted = false;
  fileName: string;

  constructor(public fb: FormBuilder, public service: scorocketService) { }

  ngOnInit() {
    this.queryForm = this.fb.group({
      product: ['', [Validators.required]],
      gmail: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      description: ['', [Validators.required]],
    });
  }

  fileEvent(fileInput: Event){
    console.log(fileInput)
    let file = (<HTMLInputElement>fileInput.target).files[0];
    this.fileName = file.name;
    console.log("fileName",this.fileName)
}
  get f() {
    return this.queryForm.controls;
  }
  submit() {
    this.submitted = true;
    if (this.queryForm.valid) {
      this.service.snackbar('Submitted successfully!');
      this.queryForm.reset();
      this.submitted = false;
      
    } else {
      this.service.snackbar('Fill all the fields');
      return

    }
  }

}
