import { Component, OnInit } from "@angular/core";
import { LocalStorageService } from "ngx-localstorage";
import { scorocketService } from "src/app/providers/scorocket.service";

@Component({
  selector: "app-advisory-payment-history",
  templateUrl: "./advisory-payment-history.component.html",
  styleUrls: ["./advisory-payment-history.component.scss"]
})
export class AdvisoryPaymentHistoryComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
