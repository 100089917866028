import { Component, OnInit } from "@angular/core";
import { LocalStorageService } from "ngx-localstorage";
import { scorocketService } from "src/app/providers/scorocket.service";

@Component({
  selector: "payment-history",
  templateUrl: "./payment-history.component.html",
  styleUrls: ["./payment-history.component.scss"]
})
export class scorocketPaymentHistoryComponent implements OnInit {
  paymentHistory = [];
  userID: any;
  reloading: boolean;
  showUsers: boolean;
  userdata: any;
  nodataMessage: string;
  productsDetail: any = [];
  productName: any;
  sortOrder = "asc";
  showasc: boolean;
  showdsc: boolean;
  package: any;
  status: any;
  constructor(public ls: LocalStorageService, public service: scorocketService) {}

  ngOnInit() {
    this.getProductsList();
    this.getusers();
  }

  send(input) {
    const payload = {
      to: [
        {
          userId: this.userID,
          package: this.package,
          status: this.status,
          purpose: "paymentReminder",
          mail: true,
          sms: true
        }
      ]
    };
    const userId = localStorage.getItem("userId");
    this.service.sendReminder(userId, payload).subscribe(
      res => {
        this.service.snackbar("Reminder sent successfully", "close");
      },
      err => {
        this.service.snackbar("Failed to send reminder", "close");
      }
    );
  }

  senddata(data) {
    this.userID = data.userID;
    this.status = data.Status;
    this.package = data.packName;
  }

  sortName() {
    if (this.sortOrder == "asc") {
      this.paymentHistory.sort((a, b) => a.Fn.localeCompare(b.Fn));
      this.sortOrder = "dec";
      this.showasc = true;
      this.showdsc = false;
    } else if (this.sortOrder == "dec") {
      this.paymentHistory.sort((a, b) => b.Fn.localeCompare(a.Fn));
      this.sortOrder = "asc";
      this.showasc = false;
      this.showdsc = true;
    }
  }

  getProductsList() {
    return new Promise((resolve, reject) => {
      this.service.getProducts().subscribe(
        res => {
          if (res.message == "success") {
            this.productsDetail = res.data;
            resolve(this.productsDetail);
          } else {
            reject(false);
          }
        },
        err => {}
      );
    });
  }

  getusers() {
    let role = "customer";
    let searchText = "";
    this.service.getUsers(searchText, role).subscribe(
      res => {
        if (res.message == "success") {
          this.reloading = false;
          this.userdata = res.data.users;
          this.userdata.forEach(element => {
            if (element.order_doc.length > 0) {
              const sortedActivities = element.order_doc.reverse(function(
                a,
                b
              ) {
                return (
                  new Date(b.payment.dateOfPurchase).getTime() -
                  new Date(a.payment.dateOfPurchase).getTime()
                );
              })[0];
              this.getProductsList();
              this.productsDetail.forEach(res => {
                if (res._id == sortedActivities.productId) {
                  this.productName = res.name;
                }
              });
              const paymentDetails = {
                Fn: element.name.first,
                Sn: element.name.last,
                RefId: sortedActivities._id,
                userID: sortedActivities.userId,
                packName: "12 Months Credit Repair Program",
                Status: sortedActivities.payment.status,
                Amount: sortedActivities.payment.amount
              };
              this.paymentHistory.push(paymentDetails);
            }
          });
        }
        console.log("this.paymentHistory",this.paymentHistory)
      },
      err => {
        // this.showUsers = false;
        // this.nodataMessage = "No data record matching name"
      }
    );
  }
}
