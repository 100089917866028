import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { scorocketService } from "src/app/providers/scorocket.service";
import { Router, ActivatedRoute } from "@angular/router";
import { CountdownComponent } from "ngx-countdown";
import { LocalStorageService } from "ngx-localstorage";
@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"]
})
export class ResetPasswordComponent implements OnInit {
  hide = true;
  passwordForm: FormGroup;
  userId: string;
  @ViewChild("countdown", { static: false }) counter: CountdownComponent;
  countdownExpired = false;
  otpValue: any;
  myTime;
  userRef;
  constructor(
    public fb: FormBuilder,
    private service: scorocketService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public ls: LocalStorageService
  ) {}

  ngOnInit() {
    console.log("count down", this.counter);
    this.passwordForm = this.fb.group({
      password: ["", Validators.required],
      otp: ["", [Validators.required, Validators.pattern("[0-9]\\d{4}")]]
    });

    this.activatedRoute.queryParams.subscribe(params => {
      console.log(params);
      if (params.id) {
        this.userId = params.id;
      }
      if (params.otp) {
        this.passwordForm.patchValue({ otp: params.otp });
      }
      if (params.expiration) {
        if (new Date(params.expiration) > new Date()) {
          this.resetTimer(params.expiration);
        } else {
          this.clearTimer();
        }
      }
      if (params.userRef) {
        this.userRef = params.userRef;
      }
    });
  }

  clearTimer = () => {
    this.myTime = {
      left: 0,
      format: "mm:ss"
    };
  };

  resetTimer = date => {
    this.myTime = {
      stopTime: new Date(date).getTime(),
      format: "mm:ss"
    };
  };

  resendOTP() {
    const payload = {
      userReference: this.userRef,
      purpose: "set_password"
    };
    console.log("payload", payload);
    this.service.getOTP(payload).subscribe(
      res => {
        if (res.message == "success") {
          this.router.navigate(["/resetPassword"], {
            replaceUrl: true,
            queryParams: {
              id: res.data.userId,
              expiration: res.data.expiration,
              userRef: this.userRef
            }
          });
        }
      },
      err => {
        console.log("ui error", err);
      }
    );
  }

  handleEvent(e) {
    console.log("expired", e);
    if (e.text === "00:00") {
      this.countdownExpired = true;
    }
  }

  setPassword() {
    if (this.passwordForm.valid) {
      const data = this.passwordForm.value;
      this.service.setForgotPassword(this.userId, data).subscribe(
        res => {
          if (res.message == "success") {
            this.service.snackbar("Password set successfully!", "close");
            this.router.navigate(["/login"]);
            console.log(res);
          }
        },
        err => {
          // if (err.error.message.includes('Invalid User Name : Invalid OTP or OTP expired')) {
          //   this.service.snackbar('Invalid OTP!', 'close');
          // }
          if (err.status == 401) {
            this.service.snackbar("Invalid OTP or OTP expired!", "close");
          }
        }
      );
    } else {
      this.service.snackbar("Please fill the required fields!", "close");
    }
  }
}
