import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-send-query',
  templateUrl: './send-query.component.html',
  styleUrls: ['./send-query.component.scss']
})
export class SendQueryComponent implements OnInit {
  account:boolean =true;
  csAccount: boolean;
  fileName: string;
  file: string;

  constructor() { }

  ngOnInit() {
  }

  myAccount(){
    this.account = true;
    this.csAccount = false;
  }
  customerAccount(){
    this.account = false;
    this.csAccount = true;
  }

  fileEvent(fileInput: Event){
    console.log(fileInput)
    let file = (<HTMLInputElement>fileInput.target).files[0];
    this.fileName = file.name;
    console.log("fileName",this.fileName)
}
fileClick(fileInput: Event){
  console.log(fileInput)
  let file = (<HTMLInputElement>fileInput.target).files[0];
  this.file = file.name;
  console.log("fileName",this.fileName)
}

}
// import { Component, OnInit } from "@angular/core";
// import { Router, ActivatedRoute } from "@angular/router";
// import { scorocketService } from "src/app/providers/scorocket.service";
// import { FormGroup, FormBuilder } from "@angular/forms";
// @Component({
//   selector: "app-send-query",
//   templateUrl: "./send-query.component.html",
//   styleUrls: ["./send-query.component.scss"]
// })
// export class SendQueryComponent implements OnInit {
//   account: boolean = true;
//   csAccount: boolean;
//   userId: any;
//   queryForm: FormGroup;
//   fileName: string;
//   file: string;
//   constructor(
//     public service: scorocketService,
//     public activatedroute: ActivatedRoute,
//     public fb: FormBuilder
//   ) { }
//   ngOnInit() {
//     this.activatedroute.queryParams.subscribe(params => {
//       if (params.id) {
//         this.userId = params.id;
//         this.profileData(this.userId);
//       } else {
//       }
//     });
//     this.queryForm = this.fb.group({
//       name: [""],
//       mobile: [""],
//       email: [""],
//       service: [""],
//       description: [""],
//       pan: [""],
//       creditscore: [""],
//       status: [""]
//     });
//   }
//   profileData(userId) {
//     this.service.getProfile(userId).subscribe(res => {
//       console.log("res123", res);
//       const patchData = {
//         name: res.data.name.first,
//         mobile: res.data.phone[0].number,
//         email: res.data.email
//       };
//       this.queryForm.patchValue(patchData);
//     });
//   }
//   myAccount() {
//     this.account = true;
//     this.csAccount = false;
//   }
//   customerAccount() {
//     this.account = false;
//     this.csAccount = true;
//   }
//   fileEvent(fileInput: Event) {
//     console.log(fileInput)
//     let file = (<HTMLInputElement>fileInput.target).files[0];
//     this.fileName = file.name;
//     console.log("fileName", this.fileName)
//   }
//   fileClick(fileInput: Event) {
//     console.log(fileInput)
//     let file = (<HTMLInputElement>fileInput.target).files[0];
//     this.file = file.name;
//     console.log("fileName", this.fileName)
//   }
// }

