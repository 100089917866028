import { NgModule } from "@angular/core";
import { Routes, RouterModule, ExtraOptions } from "@angular/router";
import { HomeComponent } from "./pages/home/home.component";
import { PlanComponent } from "./pages/plan/plan.component";
import { AboutUsComponent } from "./pages/about-us/about-us.component";
import { ContactUsComponent } from "./pages/contact-us/contact-us.component";
import { ServicesComponent } from "./pages/services/services.component";
import { ConsultationComponent } from "./pages/consultation/consultation.component";
import { RegisterComponent } from "./pages/register/register.component";
import {ConsentComponent} from "./pages/consent/consent.component";
import { PasswordComponent } from "./pages/password/password.component";
import { LoginComponent } from "./pages/login/login.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { UserPageComponent } from "./pages/user-page/user-page.component";
import { CreditCardDetailsComponent } from "./pages/credit-card-details/credit-card-details.component";
import { ScoreTrendComponent } from "./pages/score-trend/score-trend.component";
import { ScoreFactorComponent } from "./pages/score-factor/score-factor.component";
import { AuthGuard } from "./providers/auth/auth-guard.service";
import { RoleguardService } from "./providers/role/roleguard.service";
import { PersonalProfileComponent } from "./pages/personal-profile/personal-profile.component";
import { SubmitQueryComponent } from "./pages/submit-query/submit-query.component";
import { PaymentHistoryComponent } from "./pages/payment-history/payment-history.component";
import { LoanAccountComponent } from "./pages/loan-account/loan-account.component";
import { ForgotPasswordComponent } from "./pages/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./pages/reset-password/reset-password.component";
import { ChangePasswordComponent } from "./pages/change-password/change-password.component";
import { TotalAccountComponent } from "./pages/total-account/total-account.component";
import { CreditHistoryComponent } from "./pages/credit-history/credit-history.component";
import { CreditUtilisationComponent } from "./pages/credit-utilisation/credit-utilisation.component";
import { ScorePaymentHistoryComponent } from "./pages/score-payment-history/score-payment-history.component";
import { DisputeComponent } from "./pages/dispute/dispute.component";
import { AdvisoryPageComponent } from "./pages/advisory-page/advisory-page.component";
import { AdvisoryAllCustomersComponent } from "./pages/advisory-all-customers/advisory-all-customers.component";
import { AdvisoryCustomerViewComponent } from "./pages/advisory-customer-view/advisory-customer-view.component";
import { AdvisoryPaymentHistoryComponent } from "./pages/advisory-payment-history/advisory-payment-history.component";
import { SendQueryComponent } from "./pages/send-query/send-query.component";
import { AdvisoryRegistrationComponent } from "./pages/advisory-registration/advisory-registration.component";
import { AdminPageComponent } from "./pages/admin-page/admin-page.component";
import { AdminAllCllCustomersComponent } from "./pages/admin-all-customers/admin-all-customers.component";
import { TermsAndConditionComponent } from "./pages/terms-and-condition/terms-and-condition.component";
import { RefundConditionsComponent } from "./pages/refund-conditions/refund-conditions.component";
import { AdvisoryMyCustomerComponent } from "./pages/advisory-my-customer/advisory-my-customer.component";
import { AdminAllAdvisorsComponent } from "./pages/admin-all-advisors/admin-all-advisors.component";
import { RegisterTermsComponent } from "./pages/register-terms/register-terms.component";
import { scorocketTermsComponent } from "./pages/scorocket-terms/scorocket-terms.component";
import {DashboardOtpComponet} from "./pages/dashboard/dashboard-otp/dashboard-otp.component"
import { AdminCustomerViewComponent } from "./pages/admin-customer-view/admin-customer-view.component";
import { CustomerQueryComponent } from "./pages/customer-query/customer-query.component";
import { AnalystPageComponent } from "./pages/analyst-page/analyst-page.component";
import { AnalystAllCustomerComponent } from "./pages/analyst-all-customer/analyst-all-customer.component";
import { AnalystMycustomerComponent } from "./pages/analyst-mycustomer/analyst-mycustomer.component";
import { AnalystPaymentHistoryComponent } from "./pages/analyst-payment-history/analyst-payment-history.component";
import { AnalystSendQueryComponent } from "./pages/analyst-send-query/analyst-send-query.component";
import { AnalystCustomerViewComponent } from "./pages/analyst-customer-view/analyst-customer-view.component";
import { AdminAnalystViewComponent } from "./pages/admin-analyst-view/admin-analyst-view.component";
import { AdminAllAnalystComponent } from "./pages/admin-all-analyst/admin-all-analyst.component";
import { EnquiriesComponent } from './pages/enquiries/enquiries.component';
import { AdminAdvisorViewComponent } from './pages/admin-advisor-view/admin-advisor-view.component';

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: "enabled",
  anchorScrolling: "enabled",
  scrollOffset: [0, 64]
};
const routes: Routes = [
  {
    path: "home",
    component: LoginComponent
  },
  {
    path: "plan",
    component: PlanComponent
  },
  {
    path: "about",
    component: AboutUsComponent
  },
  {
    path: "contact",
    component: ContactUsComponent
  },
  {
    path: "services",
    component: ServicesComponent
  },
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full"
  },
  {
    path: "consult",
    component: ConsultationComponent
  },
  {
    path: "register",
    component: RegisterComponent
  },
  {
    path: "consent",
    component: ConsentComponent
  },
  {
    path: "termsandcondition",
    component: TermsAndConditionComponent
  },
  {
    path: "registerTerms",
    component: RegisterTermsComponent
  },
  {
    path: "scorocketTerms",
    component: scorocketTermsComponent
  },
  {
    path: "refundcondition",
    component: RefundConditionsComponent
  },
  {
    path: "password",
    component: PasswordComponent
  },
  {
    path: "login",
    component: LoginComponent
  },
  {
    path: "Dashboardotp",
    component: DashboardOtpComponet
  },
  {
    path: "forgotPassword",
    component: ForgotPasswordComponent
  },
  {
    path: "resetPassword",
    component: ResetPasswordComponent
  },
  {
    path: "user",
    component: UserPageComponent,
    canActivate: [RoleguardService],
    data: {
      expectedRole: ["customer"]
    },
    children: [
      {
        path: "dashboard",
        component: DashboardComponent,
        canActivate: [RoleguardService],
        outlet: "page",
        data: {
          expectedRole: ["customer"]
        }
      },
      {
        path: "creditcard",
        component: CreditCardDetailsComponent,
        // canActivate: [AuthGuard],
        outlet: "page",
        canActivate: [RoleguardService],
        data: {
          expectedRole: ["customer"]
        }
      },
      {
        path: "changePassword",
        component: ChangePasswordComponent,
        canActivate: [RoleguardService],
        outlet: "page",
        data: {
          expectedRole: ["customer"]
        }
      },
      {
        path: "scoretrend",
        component: ScoreTrendComponent,
        canActivate: [RoleguardService],
        outlet: "page",
        data: {
          expectedRole: ["customer"]
        }
      },
      {
        path: "scoreTotalAccount",
        component: TotalAccountComponent,
        canActivate: [RoleguardService],
        outlet: "page",
        data: {
          expectedRole: ["customer"]
        }
      },
      {
        path: "scoreCreditHistory",
        component: CreditHistoryComponent,
        canActivate: [RoleguardService],
        outlet: "page",
        data: {
          expectedRole: ["customer"]
        }
      },
      {
        path: "scorePaymentHistory",
        component: ScorePaymentHistoryComponent,
        canActivate: [RoleguardService],
        outlet: "page",
        data: {
          expectedRole: ["customer"]
        }
      },
      {
        path: "scoreCreditUtilisation",
        component: CreditUtilisationComponent,
        canActivate: [RoleguardService],
        outlet: "page",
        data: {
          expectedRole: ["customer"]
        }
      },
      {
        path: "scorefactor",
        component: ScoreFactorComponent,
        canActivate: [RoleguardService],
        outlet: "page",
        data: {
          expectedRole: ["customer"]
        }
      },
      {
        path: "personalProfile",
        component: PersonalProfileComponent,
        canActivate: [RoleguardService],
        outlet: "page",
        data: {
          expectedRole: ["customer"]
        }
      },
      {
        path: "disputes",
        component: DisputeComponent,
        canActivate: [RoleguardService],
        outlet: "page",
        data: {
          expectedRole: ["customer"]
        }
      },
      {
        path: "submitQuery",
        component: SubmitQueryComponent,
        canActivate: [RoleguardService],
        outlet: "page",
        data: {
          expectedRole: ["customer"]
        }
      },
      {
        path: "paymentHistory",
        component: PaymentHistoryComponent,
        canActivate: [RoleguardService],
        outlet: "page",
        data: {
          expectedRole: ["customer"]
        }
      },
      {
        path: "loanAccount",
        component: LoanAccountComponent,
        canActivate: [RoleguardService],
        outlet: "page",
        data: {
          expectedRole: ["customer"]
        }
      },
      {
        path: "enquiries",
        component: EnquiriesComponent,
        canActivate: [RoleguardService],
        outlet: "page",
        data: {
          expectedRole: ["customer"]
        }
      },
      // {
      //   path: "termsandcondition",
      //   component: TermsAndConditionComponent,
      //   canActivate: [RoleguardService],
      //   outlet: "page",
      //   data: {
      //     expectedRole: ["customer"]
      //   }
      // }
    ]
  },
  {
    path: "advisory",
    component: AdvisoryPageComponent,
    canActivate: [RoleguardService],
    data: {
      expectedRole: ["advisor", "analyst"]
    },
    children: [
      {
        path: "customers",
        component: AdvisoryAllCustomersComponent,
        canActivate: [RoleguardService],
        outlet: "advisorypage",
        data: {
          expectedRole: ["advisor", "analyst"]
        }
      },
      {
        path: "customer",
        component: AdvisoryCustomerViewComponent,
        canActivate: [RoleguardService],
        outlet: "advisorypage",
        data: {
          expectedRole: ["advisor", "analyst"]
        }
      },
      {
        path: "paymentHistory",
        component: AdvisoryPaymentHistoryComponent,
        canActivate: [RoleguardService],
        outlet: "advisorypage",
        data: {
          expectedRole: ["advisor", "analyst"]
        }
      },
      {
        path: "sendQuery",
        component: SendQueryComponent,
        canActivate: [RoleguardService],
        outlet: "advisorypage",
        data: {
          expectedRole: ["advisor", "analyst"]
        }
      },
      {
        path: "customer_query",
        component: CustomerQueryComponent,
        canActivate: [RoleguardService],
        outlet: "advisorypage",
        data: {
          expectedRole: ["advisor"]
        }
      },
      {
        path: "assignedCustomer",
        component: AdvisoryMyCustomerComponent,
        canActivate: [RoleguardService],
        outlet: "advisorypage",
        data: {
          expectedRole: ["advisor", "analyst"]
        }
      }
    ]
  },
  {
    path: "analyst",
    component: AnalystPageComponent,
    canActivate: [RoleguardService],
    data: {
      expectedRole: ["analyst"]
    },
    children: [
      {
        path: "customers",
        component: AnalystAllCustomerComponent,
        canActivate: [RoleguardService],
        outlet: "analystpage",
        data: {
          expectedRole: ["analyst"]
        }
      },
      {
        path: "mycustomer",
        component: AnalystMycustomerComponent,
        canActivate: [RoleguardService],
        outlet: "analystpage",
        data: {
          expectedRole: ["analyst"]
        }
      },
      {
        path: "paymentHistory",
        component: AnalystPaymentHistoryComponent,
        canActivate: [RoleguardService],
        outlet: "analystpage",
        data: {
          expectedRole: ["analyst"]
        }
      },
      {
        path: "sendQuery",
        component: AnalystSendQueryComponent,
        canActivate: [RoleguardService],
        outlet: "analystpage",
        data: {
          expectedRole: ["analyst"]
        }
      },
      {
        path: "customer",
        component: AnalystCustomerViewComponent,
        canActivate: [RoleguardService],
        outlet: "analystpage",
        data: {
          expectedRole: ["analyst"]
        }
      }
    ]
  },
  {
    path: "admin",
    component: AdminPageComponent,
    canActivate: [RoleguardService],
    data: {
      expectedRole: ["admin"]
    },
    children: [
      {
        path: "registration",
        component: AdvisoryRegistrationComponent,
        canActivate: [RoleguardService],
        outlet: "adminpage",
        data: {
          expectedRole: ["admin"]
        }
      },
      {
        path: "customers",
        component: AdminAllCllCustomersComponent,
        canActivate: [RoleguardService],
        outlet: "adminpage",
        data: {
          expectedRole: ["admin"]
        }
      },
      {
        path: "customer",
        component: AdminCustomerViewComponent,
        canActivate: [RoleguardService],
        outlet: "adminpage",
        data: {
          expectedRole: ["admin"]
        }
      },
      {
        path: "View",
        component: AdminAdvisorViewComponent,
        canActivate: [RoleguardService],
        outlet: "adminpage",
        data: {
          expectedRole: ["admin"]
        }
      },
      {
        path: "advisors",
        component: AdminAllAdvisorsComponent,
        canActivate: [RoleguardService],
        outlet: "adminpage",
        data: {
          expectedRole: ["admin"]
        }
      },
      {
        path: "analyst",
        component: AdminAnalystViewComponent,
        canActivate: [RoleguardService],
        outlet: "adminpage",
        data: {
          expectedRole: ["admin"]
        }
      },
      {
        path: "analysts",
        component: AdminAllAnalystComponent,
        canActivate: [RoleguardService],
        outlet: "adminpage",
        data: {
          expectedRole: ["admin"]
        }
      }
      // {
      //   path: "customer",
      //   component: AdminAllCllCustomersComponent,
      //   canActivate: [RoleguardService],
      //   outlet: "adminpagepage",
      //   data: {
      //     expectedRole: ["admin"]
      //   },
      // },
      // {
      //   path: "paymentHistory",
      //   component: AdvisoryPaymentHistoryComponent,
      //   canActivate: [RoleguardService],
      //   outlet: "adminpage",
      //   data: {
      //     expectedRole: ["admin"]
      //   },
      // },
    ]
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
