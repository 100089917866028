import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { scorocketService } from "src/app/providers/scorocket.service";
import { Router, ActivatedRoute } from "@angular/router";
import { CountdownComponent } from "ngx-countdown";
import { LocalStorageService } from 'ngx-localstorage';
@Component({
  selector: "app-password",
  templateUrl: "./password.component.html",
  styleUrls: ["./password.component.scss"]
})
export class PasswordComponent implements OnInit {
  hide = true;
  passwordForm: FormGroup;
  userId: string;
  @ViewChild("countdown", { static: false }) counter: CountdownComponent;
  countdownExpired = false;
  otpValue: any;
  num = 100;
  myTime;
  regID: any;
  constructor(
    public fb: FormBuilder,
    private service: scorocketService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ls: LocalStorageService
  ) { }

  ngOnInit() {
    console.log("count down", this.counter);
    this.passwordForm = this.fb.group({
      password: ["",[Validators.required]],
      otp: ["", [Validators.required, Validators.pattern("[0-9]\\d{4}")]]
    });

    this.activatedRoute.queryParams.subscribe(params => {
      console.log("params", params);
      this.regID = params.id;
      if (params.id) {
        this.userId = params.id;
      }
      if (params.otp) {
        this.passwordForm.patchValue({ otp: params.otp });
      }
      if (params.otpExpiration) {
        if (new Date(params.otpExpiration) > new Date()) {
          this.resetTimer(params.otpExpiration);
        } else {
          this.clearTimer();
        }
      }
    });
    console.log("register_id password", this.ls.get('register_id'))
  }

  clearTimer = () => {
    this.myTime = {
      left: 0,
      format: "mm:ss"
    };
  };

  resetTimer = date => {
    // console.log(date)
    this.myTime = {
      stopTime: new Date(date).getTime(),
      format: "mm:ss"
    };
  };

  handleEvent(e) {
    console.log("expired", e);
    if (e.text === "00:00") {
      this.countdownExpired = true;
    }
  }

  setPassword() {
    if (this.passwordForm.get('password').value) {
      console.log("checking the error",this.passwordForm.value.otp,"passwd",this.passwordForm.value.otp)
      const data = {
        password : this.passwordForm.get('password').value,
        otp: "56981"
      }
      this.service.setPassword(this.userId, data).subscribe(
        res => {
          console.log("res", res);
          if (res.message == "success") {
            this.service.snackbar("Password set successfully!", "close");
            this.router.navigate(["/login"]);
            console.log(res);
          }
        },
        err => {
          if (
            err.error.message.includes(
              "Invalid User Name : Invalid OTP or OTP expired"
            )
          ) {
            this.service.snackbar("Invalid OTP!", "close");
          }
        }
      );
    } else {
      console.log("checking the error",this.passwordForm.value.otp,"passwd",this.passwordForm.value.otp)
      this.service.snackbar("Please fill the required fields!", "close");
    }
  }
  resendOTP() {
    const payload = {
      userReference: this.userId,
      purpose: "set_password"
    };
    this.service.getOTP(payload).subscribe(res => {
      console.log("res", res)
      if (res.message === "success") {
        this.router.navigate(["/password"], {
          queryParams: {
            id:this.userId,
            otpExpiration: res.data.expiration
          }
        });
      }
    }, err => {
      if (err.status === 500) {
        this.service.snackbar("Invalid credential!", "close");
      } else if (err.status == 0) {
        this.service.snackbar("ERR_CONNECTION_TIMED_OUT!", "close");
      }
    });
  }
}
