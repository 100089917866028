import { Component, OnInit } from "@angular/core";
import { scorocketService } from "src/app/providers/scorocket.service";
import { LocalStorageService } from "ngx-localstorage";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-enquiries",
  templateUrl: "./enquiries.component.html",
  styleUrls: ["./enquiries.component.scss"]
})
export class EnquiriesComponent implements OnInit {
  userId: string;
  productId: any;
  res: any;
  report: any = [];
  enqData: any;
  dob: string;
  show: boolean;
  messageNoCustomer: string;
  msg: boolean;

  constructor(
    public service: scorocketService,
    public ls: LocalStorageService,
    public datepipe: DatePipe
  ) { }

  ngOnInit() {
    this.getProductId();
  }
  getProductId() {
    this.userId = this.ls.get("userId");
    this.service.getProductId(this.userId).subscribe(res => {
      if (res.message == "success") {
        if (res.data.length > 0 && res.data[0].productId) {
          this.productId = res.data[0].productId;
          this.enquiry(this.userId, this.productId);
        }
      }
    });
  }
  enquiry(userId, productId) {
    console.log("userId", userId, "productId", productId);
    this.service.getCreditReport(userId, productId).subscribe(res => {
      if (res.message) {
        this.res = res.data.reports[0].report.finalReport[0].reportDetails.GetCustomerAssetsResponse.GetCustomerAssetsSuccess.Asset.TrueLinkCreditReport.TradeLinePartition;
        this.report = res.data.reports[0].report.finalReport[0].reportDetails.GetCustomerAssetsResponse.GetCustomerAssetsSuccess.Asset.TrueLinkCreditReport.TradeLinePartition;
        this.show = true;
        this.msg = false;
        this.res.forEach(res => {
          this.res = !Array.isArray(this.res) ? [this.res] : this.res;
          // this.dob = this.report.Date_of_Request.substring(4, 6) + '-' + this.report.Date_of_Request.substring(6,8) + '-' + this.report.Date_of_Request.substring(0, 4)
          console.log("this.report", this.report);
          this.res.forEach((res, i) => {
            this.enqData = res;
            console.log(res);
            this.dob = res.Tradeline.dateOpened.slice(0, -6)
            console.log(this.dob);
            this.report[i]["Date_of_Request"] = this.dob;
          });
        });
      } else {
        this.show = false;
        this.msg = true;
        // this.messageNoCustomer = "No Data Available! Please purchase your plan";
      }
    });
  }
}
