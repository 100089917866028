import { Injectable, enableProdMode } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpResponse, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { MatSnackBar } from "@angular/material";
import { LocalStorageService } from "ngx-localstorage";
enableProdMode();
export interface Config {}
export interface userRegister {}
@Injectable({
  providedIn: "root"
})
export class scorocketService {
  public scrollActive;
  public isLogged;
  public userdata = [];
  public scorocketServiceUrl = environment.scorocketServiceUrls.backend;
  public cibilUrl = environment.scorocketServiceUrls.cibilhawk;
  constructor(
    private http: HttpClient,
    private matSnackbar: MatSnackBar,
    private ls: LocalStorageService
  ) {}
  token = this.ls.get("token");

  contactForm(): Observable<HttpResponse<Config>> {
    return this.http.get<Config>(this.scorocketServiceUrl, {
      observe: "response"
    });
  }

  checkPassedDues = loanData => {
    let missedDues = 0;
    loanData.forEach(loan => {
      if (
        loan.Days_Past_Due !== "s" &&
        loan.Days_Past_Due !== "S" &&
        loan.Days_Past_Due !== "0" &&
        loan.Days_Past_Due !== ""
      ) {
        missedDues += 1;
      }
    });
    return missedDues;
  };

  register(data): any{
    // return this.http.post(this.scorocketServiceUrl + '/users', data);
    return this.http.post(`http://localhost:3000/users`,data)
  }

  contact(data): Observable<any> {
    return this.http.post(this.scorocketServiceUrl + "/help/mail", data);
  }

  consult(data): Observable<any> {
    return this.http.post(this.scorocketServiceUrl + "/convey/mail?conveyType=support", data);
  }
  experianotp(id,ProductId,data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token
      })
    };
    // return this.http.post(this.scorocketServiceUrl + `/users/${id}/products/${ProductId}/register/ratings`, data,httpOptions )
    return this.http.post(`localhost:3000/+'/users/${id}/products/${ProductId}/register/ratings`,data)

  }
  otpcofirmation (id,ProductId,data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token
      })
    };
    return this.http.post(this.scorocketServiceUrl + `/users/${id}/products/${ProductId}/new/ratings`, data,httpOptions )
    
  }


  advisorregister(id, data): Observable<any> {
    return this.http.post(this.scorocketServiceUrl + `/admin/${id}/users`, data);
  }

  mapAdvisor(id, data): Observable<any> {
    return this.http.patch(this.scorocketServiceUrl + `/admin/${id}/users`, data);
  }

  getMappedCustomers(id): Observable<any> {
    return this.http.get(this.scorocketServiceUrl + `/users/${id}/customers`);
  }

  setPassword(id, data): Observable<any> {
    return this.http.put(this.scorocketServiceUrl + `/users/${id}/password`, data);

    // return this.http.put(`http://localhost:3000/users/${id}/password`,data)
  }

  
  changePassword(id, data): Observable<any> {
    return this.http.patch(
      this.scorocketServiceUrl + `/users/${id}/password`,
      data
    );
  }

  getOTP(data): Observable<any> {
    return this.http.post(this.scorocketServiceUrl + `/users/otp`, data);
    // return this.http.post(`http://localhost:3000/users/otp`,data)

  }

  getChangePasswordOTP(data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token
      })
    };
    return this.http.post(
      this.scorocketServiceUrl + "/users/otp",
      data,
      httpOptions
    );
  }

  changeUserPassword(id, data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token
      })
    };
    return this.http.patch(
      this.scorocketServiceUrl + `/users/${id}/password/change`,
      data
    );
  }

  setForgotPassword(id, data): Observable<any> {
    return this.http.put(
      this.scorocketServiceUrl + `/users/${id}/password/forgot`,
      data
    );
  }

  login(data): Observable<any> {
    // return this.http.post(this.scorocketServiceUrl + `/login`, data);
     return this.http.post(`http://localhost:3000/login`,data)
  }

//To purchase Products
  buyNow(data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token
            })
    };
    // return this.http.post(this.scorocketServiceUrl + "/orders", data, httpOptions);
    return this.http.post(`http://localhost:3000/orders`,data,httpOptions)

    
  }
  ccavenuetest(): Observable<any> {
    const httpOptions = {
      headers: {}
    };
    return this.http.get('https://app.scorocket.com/api/ccavenue/node/encdata',httpOptions)
  }

  buyCard(data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token
      })
    };
    return this.http.post(this.scorocketServiceUrl + "/orders", data, httpOptions)
  }
  lockedstate(id, data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token
      })
    };
    return this.http.put(
      this.scorocketServiceUrl + "/users/" + id + "/state",
      data,
      httpOptions
    );
  }

  // getUsers(): Observable<any> {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + this.token
  //     })
  //   };
  //   return this.http.get(this.scorocketServiceUrl + "/users", httpOptions);
  // }

  getUsers(searchText, role): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token
      })
    };
    return this.http.get(
      `${this.scorocketServiceUrl}/users?offset=0&limit=100&searchFor=${searchText}&searchType=partial&userRoles=` +
        role,
      httpOptions
    );
  }

  getMyCustomerSearch(id, searchText): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token
      })
    };
    return this.http.get(
      `${this.scorocketServiceUrl}/users/${id}/customers?offset=0&limit=100&searchFor=${searchText}&searchType=partial`,
      httpOptions
    );
  }

  // getAdvisorSearch(searchText,role): Observable<any> {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + this.token
  //     })
  //   };
  //   return this.http.get(`${this.scorocketServiceUrl}/users?offset=0&limit=100&searchFor=${searchText}&searchType=partial&userRoles=`+ role, httpOptions);
  // }

  getProductId(userId): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.ls.get("token")
      })
    };
    // return this.http.get(
    //   this.scorocketServiceUrl + `/users/${userId}/products`,
    //   httpOptions
    // );
    return this.http.get(`http://localhost:3000/users/${userId}/products`,httpOptions)
  }
  getDashboardReport(userId): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.ls.get("token")
      })
    };
    return this.http.get(`http://localhost:3000/users/${userId}/ratings`, httpOptions)
  }

  getCreditReport(userId, productId): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token
      })
    };
    // return this.http.get(
    //   this.scorocketServiceUrl + `/users/${userId}/products/${productId}/ratings`,
    //   httpOptions
    // );

    return this.http.get(`http://localhost:3000/users/${userId}/products/${productId}/ratings`,httpOptions)
    // return this.http.get(this.scorocketServiceUrl + `/users/${userId}/products/${productId}/ratings`,httpOptions)

  }
  saveReport(userId,productId,data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token
      })
    };
    const payload: FormData = new FormData();
    payload.append("attachments", data);
    console.log('pay',payload)
    return this.http.post(this.scorocketServiceUrl + `/users/${userId}/products/${productId}/report`,payload,httpOptions)
    // return this.http.post(`http://localhost:3000//users/24-S001-S-000001/products/${productId}/report`,data,httpOptions)
  }

  getProductToken(data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token
      })
    };
    return this.http.post(
      this.cibilUrl + `/GetProductWebToken`,data,httpOptions
    )
  }


  getScoreTrend(userId, productId, fromDate, toDate): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token
      })
    };
    // return this.http.get(
    //   this.scorocketServiceUrl +
    //     `/users/${userId}/products/${productId}/ratings?fromDate=${fromDate}&tillDate=${toDate}`,
    //   httpOptions
    // );
    return this.http.get(
        `http://localhost:3000/users/${userId}/products/${productId}/ratings?fromDate=${fromDate}&tillDate=${toDate}`,
      httpOptions
    );
  }
  UPIvalidation(data): Observable<any>{
    return this.http.post(this.scorocketServiceUrl + `/phonepe/vpa`,data)
  }

  phonepeSubscription(data) : Observable<any>{
    return this.http.post(this.scorocketServiceUrl + `/phonepe/subscription`,data)
  }

  getProducts(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning": "true"
      })
    };
    // return this.http.get(this.scorocketServiceUrl + `/products`,httpOptions);
    return this.http.get(`http://localhost:3000/products`,httpOptions)

  }

  getProfile(userId): Observable<any> {
    // return this.http.get(this.scorocketServiceUrl + `/users/${userId}`);
    return this.http.get(`http://localhost:3000/users/${userId}`)
  }
  cibilReq(data): Observable<any> {
    return this.http.post(`http://localhost:3000/cibil/ratings`,data)
  }
  editProfile(userId, data, otp): Observable<any> {
    return this.http.patch(
      this.scorocketServiceUrl + `/users/${userId}?otp=${otp}`,
      data
    );
  }

  editCustomerProfile(adminId, userId, data, otp?): Observable<any> {
    let url = `/admin/${adminId}/users/${userId}`;
    if (otp) {
      url = `/admin/${adminId}/users/${userId}?otp=${otp}`;
    }
    return this.http.patch(this.scorocketServiceUrl + `${url}`, data);
  }

  paymentHistory(userId): Observable<any> {
    return this.http.get(this.scorocketServiceUrl + `/users/${userId}/payments`);
  }

  snackbar(msg, control?, duration?) {
    this.matSnackbar.open(msg, control || "close", {
      horizontalPosition: "right",
      verticalPosition: "bottom",
      duration: duration || 3000
    });
  }

  dismissSnackbar() {
    this.matSnackbar.dismiss();
  }

  getReports = (data): Observable<any> => {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token
      })
    };
    // return this.http.get(
    //   this.scorocketServiceUrl +
    //     `/users/${data.userId}/products/${data.productId}/ratings${data.query}`,
    //   httpOptions
    // );
    return this.http.get(`http://localhost:3000/users/${data.userId}/products/${data.productId}/ratings${data.query}`)
  };

  sendDispute = (data, userId, file): Observable<any> => {
    const httpOptions = {
      headers: new HttpHeaders({
        // "content-type": "multipart/form-data",
        // Authorization: "Bearer " + this.token
      })
    };
    const payload: FormData = new FormData();
    payload.append("disputedata", JSON.stringify(data));
    if (file) {
      payload.append("attachments", file);
    }
    return this.http.post(
      this.scorocketServiceUrl + `/users/${userId}/dispute`,
      payload,
      httpOptions
    );
  };

  sendReminder(id, data): Observable<any> {
    return this.http.post(
      this.scorocketServiceUrl + `/admin/${id}/reminders`,
      data
    );
  }

  addAnalystToAdvisor(adminId, advisorId, data): Observable<any> {
    return this.http.put(
      this.scorocketServiceUrl + `/admin/${adminId}/users/${advisorId}`,
      data
    );
  }

  getAnalystOfAdvisor(adminId, analystId): Observable<any> {
    return this.http.get(
      this.scorocketServiceUrl + `/admin/${adminId}/users/${analystId}`
    );
  }

  deleteAnalyst(adminId, analystId): Observable<any> {
    return this.http.delete(
      this.scorocketServiceUrl + `/admin/${adminId}/users/${analystId}`
    );
  }
  // convey(query,data):Observable<any>{
  //   return this.http.post(this.scorocketServiceUrl + `/convey/mail?conveyType=${query}`,data);
  // }

}
