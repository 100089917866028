import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { scorocketService } from "src/app/providers/scorocket.service";
import { LocalStorageService } from "ngx-localstorage";

@Component({
  selector: 'app-credit-history',
  templateUrl: './credit-history.component.html',
  styleUrls: ['./credit-history.component.scss']
})
export class CreditHistoryComponent implements OnInit {
  myDate = new Date();
  oldestYear: string;
  successoldestYear: string;
  dangeroldestYear: string;
  warningoldestYear: string;
  noinfooldestYear: string;
  goodoldestYear: string;
  noCreditReport: boolean;
  messageNoCustomer: string;
  showCreditReport: boolean;
  accounts: any;
  userId: string;

  constructor(public router:Router, public ls: LocalStorageService,  public service: scorocketService) { }

  ngOnInit() {
    this.userId = this.ls.get("userId");
    this.getCreditScore(this.userId);
    const scorefactor  = JSON.parse(this.ls.get('scoredata'));
    this.oldestYear = scorefactor.data2
    this.validateCreditScore(this.oldestYear)
  }

    goBack(){
      this.router.navigateByUrl('user/(page:scorefactor)');
    }

    validateCreditScore(e) {
      if (e == "" || e == undefined) {
        this.dangeroldestYear = "state-danger";
        this.warningoldestYear = "state-warning";
        this.goodoldestYear = "state-good";         
        this.successoldestYear = "state-success";
        this.noinfooldestYear = "state-dark-selected";
      }else if (e < 3) {
        this.dangeroldestYear = "state-danger-selected";
        this.warningoldestYear = "state-warning";
        this.goodoldestYear = "state-good";   
        this.successoldestYear = "state-success";
        this.noinfooldestYear = "state-dark";
      } else if (e >= 3 && e <= 5) {
        this.dangeroldestYear = "state-danger";
        this.warningoldestYear = "state-warning-selected";
        this.goodoldestYear = "state-good";    
        this.successoldestYear = "state-success";
        this.noinfooldestYear = "state-dark";
      } else if (e >= 5 && e <= 7) {
        this.dangeroldestYear = "state-danger";
        this.warningoldestYear = "state-warning";
        this.goodoldestYear = "state-good-selected";  
        this.successoldestYear = "state-success";
        this.noinfooldestYear = "state-dark";
      }else if (e > 7) {
        this.dangeroldestYear = "state-danger";
        this.warningoldestYear = "state-warning";
        this.goodoldestYear = "state-good";  
        this.successoldestYear = "state-success-selected";
        this.noinfooldestYear = "state-dark";
      }
    }

    getCreditScore(userId) {
      this.service.getProductId(userId).subscribe(res => {
        if (res.message == "success") {
          if (res.data.length > 0 && res.data[0].productId) {
            this.loadCreditScore(userId, res.data[0].productId);
          } else {
            this.noCreditReport = true;
            this.noCreditReport = false;
            this.messageNoCustomer =
              "No Data Available! Please purchase your plan";
          }
        }
      });
    }
  
    loadCreditScore(userId, productId) {
      this.service.getCreditReport(userId, productId).subscribe(
        res => {
          if (res.message == "success") {
            this.noCreditReport = false;
            this.showCreditReport = true;
            if (res.data.reports[0].report.finalReport[0].reportDetails.GetCustomerAssetsResponse.GetCustomerAssetsSuccess.Asset.TrueLinkCreditReport.TradeLinePartition) {
              this.accounts = res.data.reports[0].report.finalReport[0].reportDetails.GetCustomerAssetsResponse.GetCustomerAssetsSuccess.Asset.TrueLinkCreditReport.TradeLinePartition       
            } else {
            }
          } else {
            this.messageNoCustomer = res.data[0].errorString;
            this.noCreditReport = true;
            this.showCreditReport = false;
            this.service.snackbar(res.data[0].errorString);
          }
        },
        err => { }
      );
    }
  
}

