import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'ngx-localstorage';
import { scorocketService } from 'src/app/providers/scorocket.service';

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.scss']
})
export class PaymentHistoryComponent implements OnInit {
  userId: string;
  payment;

  constructor(public ls: LocalStorageService, public service: scorocketService) { }

  ngOnInit() {
    this.paymentHistory();
    
  }
  paymentHistory() {
    this.userId = this.ls.get('userId');
    this.service.paymentHistory(this.userId).subscribe(res => {
      this.payment = res.data.orders;
      console.log("payment history", res.data.orders)
    })

  }

}
