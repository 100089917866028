import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-analyst-payment-history",
  templateUrl: "./analyst-payment-history.component.html",
  styleUrls: ["./analyst-payment-history.component.scss"]
})
export class AnalystPaymentHistoryComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
  