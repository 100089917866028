import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { scorocketService } from "src/app/providers/scorocket.service";
import { LocalStorageService } from "ngx-localstorage";

@Component({
  selector: 'app-score-payment-history',
  templateUrl: './score-payment-history.component.html',
  styleUrls: ['./score-payment-history.component.scss']
})
export class ScorePaymentHistoryComponent implements OnInit {

  myDate = new Date();
  paymenthistory: string;
  successpaymenthistory: string;
  dangerpaymenthistory: string;
  warningpaymenthistory: string;
  noinfopaymenthistory: string;
  goodpaymenthistory: string;
  noCreditReport: boolean;
  messageNoCustomer: string;
  showCreditReport: boolean;
  accounts: any;
  userId: string;

  constructor(public router:Router, public ls: LocalStorageService,  public service: scorocketService) { }

  ngOnInit() {
    this.userId = this.ls.get("userId");
    this.getCreditScore(this.userId);
    const scorefactor  = JSON.parse(this.ls.get('scoredata'));
    console.log("scorefactor",scorefactor)
    this.paymenthistory = scorefactor.data4;
    this.validateCreditScore(scorefactor.data4)
  }

  goBack(){
    this.router.navigateByUrl('user/(page:scorefactor)');
  }

  validateCreditScore(e) {
    if (e == "" || e == undefined) {
      this.dangerpaymenthistory = "state-danger";
      this.warningpaymenthistory = "state-warning";
      this.goodpaymenthistory = "state-good";         
      this.successpaymenthistory = "state-success";
      this.noinfopaymenthistory = "state-dark-selected";
    }else if (e < 50) {
      this.dangerpaymenthistory = "state-danger-selected";
      this.warningpaymenthistory = "state-warning";
      this.goodpaymenthistory = "state-good";   
      this.successpaymenthistory = "state-success";
      this.noinfopaymenthistory = "state-dark";
    } else if (e >= 50 && e <= 90) {
      this.dangerpaymenthistory = "state-danger";
      this.warningpaymenthistory = "state-warning-selected";
      this.goodpaymenthistory = "state-good";    
      this.successpaymenthistory = "state-success";
      this.noinfopaymenthistory = "state-dark";
    } else if (e >= 90 && e <= 99) {
      this.dangerpaymenthistory = "state-danger";
      this.warningpaymenthistory = "state-warning";
      this.goodpaymenthistory = "state-good-selected";  
      this.successpaymenthistory = "state-success";
      this.noinfopaymenthistory = "state-dark";
    }else if (e == 100) {
      this.dangerpaymenthistory = "state-danger";
      this.warningpaymenthistory = "state-warning";
      this.goodpaymenthistory = "state-good";  
      this.successpaymenthistory = "state-success-selected";
      this.noinfopaymenthistory = "state-dark";
    }
  }

  getCreditScore(userId) {
    this.service.getProductId(userId).subscribe(res => {
      console.log("res for data",res)
      if (res.message == "success") {
        if (res.data.length > 0 && res.data[0].productId) {
          this.loadCreditScore(userId, res.data[0].productId);
        } else {
          this.noCreditReport = true;
          this.noCreditReport = false;
          this.messageNoCustomer =
            "No Data Available! Please purchase your plan";
        }
      }
    });
  }

  loadCreditScore(userId, productId) {
    this.service.getCreditReport(userId, productId).subscribe(
      res => {
        if (res.message == "success" && res.data.reports[0].report.finalReport[0].reportDetails.GetCustomerAssetsResponse.GetCustomerAssetsSuccess.Asset.TrueLinkCreditReport.TradeLinePartition) {
          this.noCreditReport = false;
          this.showCreditReport = true;
          if (res.data.reports[0].report.finalReport[0].reportDetails.GetCustomerAssetsResponse.GetCustomerAssetsSuccess.Asset.TrueLinkCreditReport.TradeLinePartition) {
            this.accounts = res.data.reports[0].report.finalReport[0].reportDetails.GetCustomerAssetsResponse.GetCustomerAssetsSuccess.Asset.TrueLinkCreditReport.TradeLinePartition       
          } else {
          }
        } else {
          this.messageNoCustomer = res.data[0].errorString;
          this.noCreditReport = true;
          this.showCreditReport = false;
          this.service.snackbar(res.data[0].errorString);
        }
      },
      err => { }
    );
  }

}
