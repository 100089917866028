import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'ngx-localstorage';
import { Router } from '@angular/router';
import { scorocketService } from 'src/app/providers/scorocket.service';
@Component({
  selector: 'app-advisory-page',
  templateUrl: './advisory-page.component.html',
  styleUrls: ['./advisory-page.component.scss']
})
export class AdvisoryPageComponent implements OnInit {
  userDetails: any;
  userId: string;

  constructor(private ls: LocalStorageService,public router: Router, private service: scorocketService) { }

  ngOnInit() {
    this.userDetails = this.ls.get('userName');
    this.userId = this.ls.get('userId');
    this.service.isLogged = true;
  }

}


