import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { scorocketService } from 'src/app/providers/scorocket.service';

@Component({
  selector: 'app-consulation',
  templateUrl: './consultation.component.html',
  styleUrls: ['./consultation.component.scss']
})
export class ConsultationComponent implements OnInit {
  consultationForm: FormGroup;
  sending: boolean;
  constructor(private fb: FormBuilder, private service: scorocketService) { }

  ngOnInit() {
    this.consultationForm = this.fb.group({
      fullName: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(50), Validators.pattern('[a-zA-Z\. ]+')]],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      phoneNumber: ['', [Validators.required, Validators.pattern('[0-9]\\d{9}')]],
      zip: ['', [Validators.required, Validators.pattern('[0-9]\\d{5}')]],
      agree: ['']
    });
  }

  submitConsultation() {
    if (this.consultationForm.valid) {
      if (!this.consultationForm.value.agree) {
        this.service.snackbar('Please  agree to send me FREE info on other credit related offers.', 'close');
      } else {
        console.log('daata', this.consultationForm.value);
        this.sending = true;
        this.service.consult(this.consultationForm.value).subscribe(res => {
          if (res.message == "success") {
            this.service.snackbar('Mail Sent Successfully')
            this.consultationForm.reset();
            this.sending = false;
          }
        }, err => {
          this.sending = false;
        })
      }
    } else {
      this.service.snackbar('Please fill the required fields!', 'close');
    }
  }

}
