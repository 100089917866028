import { Component, OnInit } from '@angular/core';
import { scorocketService } from 'src/app/providers/scorocket.service';
import { LocalStorageService } from 'ngx-localstorage';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  logout: boolean;

  constructor(public service: scorocketService, private ls: LocalStorageService) { }

  ngOnInit() {
    if (this.ls.get('token')) {
      this.service.isLogged = true;
    } else {
      this.service.isLogged = false;
    }
  }

  next(e) {
    console.log(e.target);
  }
  prev(e) {
    console.log(e.target);
  }

}
