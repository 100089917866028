import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'ngx-localstorage';

@Component({
  selector: 'app-user-sidebar',
  templateUrl: './user-sidebar.component.html',
  styleUrls: ['./user-sidebar.component.scss']
})
export class UserSidebarComponent implements OnInit {
  userId: any;
  userDetails: any;
  userName: string;

  constructor(private ls: LocalStorageService) { }

  ngOnInit() {
    this.userName = this.ls.get('userName');
    this.userId = this.ls.get('userId');
  }

}
