import { Component, OnInit } from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { scorocketService } from "src/app/providers/scorocket.service";
import { Router, ActivatedRoute } from "@angular/router";
import { LocalStorageService } from "ngx-localstorage";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"]
})
export class ForgotPasswordComponent implements OnInit {
  forgtPaswd: FormGroup;

  constructor(
    public fb: FormBuilder,
    public service: scorocketService,
    public route: Router,
    public ls: LocalStorageService
  ) {}

  ngOnInit() {
    this.forgtPaswd = this.fb.group({
      userName: [""]
    });
  }
  Submit() {
    const payload = {
      userReference: this.forgtPaswd.value.userName,
      purpose: "forgot_password"
    };
    this.service.getOTP(payload).subscribe(
      res => {
        console.log(res);
        if (res.message == "success") {
          this.service.snackbar(
            "OTP successfully sent to your number",
            "close"
          );
          this.route.navigate(["/resetPassword"], {
            queryParams: {
              id: res.data.userId,
              expiration: res.data.expiration,
              userRef: this.forgtPaswd.value.userName
            }
          });
        }
      },
      err => {
        if (err.status == 500) {
          this.service.snackbar("Invalid Credential!", "close");
        }
      }
    );
  }
}
