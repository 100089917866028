import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { scorocketService } from "src/app/providers/scorocket.service";
import { Router, ActivatedRoute } from "@angular/router";
import { CountdownComponent } from "ngx-countdown";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"]
})
export class ChangePasswordComponent implements OnInit {
  forgtPaswd: FormGroup;
  hide = true;
  passwordForm: FormGroup;

  userId: string;
  @ViewChild("countdown", { static: false }) counter: CountdownComponent;
  countdownExpired = false;
  otpValue: any;
  showOTP: boolean = false;
  userRef: any;
  myTime;
  constructor(
    public router: Router,
    public fb: FormBuilder,
    public service: scorocketService,
    public route: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    (this.forgtPaswd = this.fb.group({
      userName: [""]
    })),
      (this.passwordForm = this.fb.group({
        password: ["", Validators.required],
        otp: ["", [Validators.required, Validators.pattern("[0-9]\\d{4}")]]
      }));
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.otpExpiration) {
        if (new Date(params.otpExpiration) > new Date()) {
          this.resetTimer(params.otpExpiration);
          this.showOTP = true;
        } else {
          this.clearTimer();
        }
      } else {
        this.showOTP = false;
      }
      if (params.userRef) {
        this.userRef = params.userRef;
      }
    });
  }

  clearTimer = () => {
    this.myTime = {
      left: 0,
      format: "mm:ss"
    };
  };

  resetTimer = date => {
    this.myTime = {
      stopTime: new Date(date).getTime(),
      format: "mm:ss"
    };
  };
  Submit() {
    const payload = {
      userReference: this.forgtPaswd.value.userName,
      purpose: "change_password"
    };
    this.service.getChangePasswordOTP(payload).subscribe(
      res => {
        if (res.message == "success") {
          this.router.navigate(
            ["/user", { outlets: { page: "changePassword" } }],
            {
              queryParams: {
                otpExpiration: res.data.expiration,
                userRef: res.data.userId
              }
            }
          );
          this.service.snackbar(
            "OTP successfully sent to your number",
            "close"
          );
          this.userId = res.data.userId;
          this.showOTP = true;
        }
      },
      err => {
        if (err.status == 500) {
          this.service.snackbar("Invalid Credential!", "close");
        }
      }
    );
  }
  resendOTP() {
    const payload = {
      userReference: this.userRef,
      purpose: "set_password"
    };
    console.log("payload", payload);
    this.service.getOTP(payload).subscribe(
      res => {
        if (res.message == "success") {
          this.router.navigate(
            ["/user", { outlets: { page: "changePassword" } }],
            {
              replaceUrl: true,
              queryParams: {
                otpExpiration: res.data.expiration,
                userRef: this.userRef
              }
            }
          );
          this.showOTP = true;

          // this.loginPassword = false;
          // this.showPhone = false;
        }
      },
      err => {
      }
    );
  }
  handleEvent(e) {
    if (e.text === "00:00") {
      this.countdownExpired = true;
    }
  }

  setPassword() {
    if (this.passwordForm.valid) {
      const data = this.passwordForm.value;
      this.service.changeUserPassword(this.userRef, data).subscribe(
        res => {
          if (res.message == "success") {
            this.service.snackbar("Password set successfully!", "close");
            this.route.navigate(["/user"]);
            // console.log(res);
          }
        },
        err => {
          //
          if (err.status == 401) {
            this.service.snackbar("Invalid OTP or OTP expired!", "close");
          }
        }
      );
    } else {
      this.service.snackbar("Please fill the required fields!", "close");
    }
  }
}
