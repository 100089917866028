import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { scorocketService } from "src/app/providers/scorocket.service";

@Component({
  selector: 'app-terms-and-condition',
  templateUrl: './terms-and-condition.component.html',
  styleUrls: ['./terms-and-condition.component.scss']
})
export class TermsAndConditionComponent implements OnInit {
  tandc: FormGroup;

  constructor(public router: Router, public fb: FormBuilder, public service: scorocketService) { }

  ngOnInit() {
    this.tandc = this.fb.group({
      terms: ['', Validators.required]
    })
  }
  cancel() {
    this.router.navigate(["/user"], {
      queryParams: {
        terms: false
      }
    });
  }
  terms(){
    this.router.navigate(["/refundcondition"])
  }
  continue() {
    if (this.tandc.value.terms) {
      this.router.navigate(["/user"], {
        queryParams: {
          terms: true
        }
      });
    } else {
      this.service.snackbar("Please agree to terms and conditions!");
    }

  }

}
