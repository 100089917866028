import { Component, OnInit, Inject } from "@angular/core";
import { scorocketService } from "src/app/providers/scorocket.service";
import { LocalStorageService } from "ngx-localstorage";
import { ExternalLibraryService } from "../../providers/util";
import { DatePipe } from "@angular/common";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from "@angular/material/dialog";
import { Router, ActivatedRoute, NavigationExtras } from "@angular/router";
import { DashboardOtpComponet } from "./dashboard-otp/dashboard-otp.component";
import { SubscritionPlansComponent } from "../subscrition-plans/subscrition-plans.component";
import { JwtHelperService } from "@auth0/angular-jwt/src/jwthelper.service";
import { saveAs } from 'file-saver';

declare const Razorpay: any;
declare const zingchart;
declare const ZC;

export interface IDialogData {
  dates: {
    start: Date;
    end: Date;
  };
}
@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"]
})

export class DashboardComponent implements OnInit {
  dates;
  downloading = false;
  userDetails = {
    name: "Murrali",
    creditScore: 500,
    date: new Date()
  };
  noButton: boolean = true;
  tillDate: string;
  fromDate: string;
  htmlFile: string;
  productsDetail;
  plans: {
    id: number;
    name: string;
    features: string[];
    price: string;
    btnTitle: string;
    disabled: boolean;
  }[];
  response;
  charts = [];
  razorpayResponse;
  showModal = false;
  RAZORPAY_OPTIONS = {
    key: "",
    amount: "",
    name: "scorocket",
    subscription_id: "",
    subscription_card_change: 0,
    description: "Pro plan",
    handler: function(response) {
      alert(response.razorpay_payment_id),
        alert(response.razorpay_subscription_id),
        alert(response.razorpay_signature);
    },
    image:
      "https://livestatic.novopay.in/resources/img/nodeapp/img/Logo_NP.jpg",
    prefill: {
      name: "",
      email: "",
      contact: "",
      method: ""
    },
    // modal: {},
    notes: {},
    theme: {
      color: "#44a0de"
    }
  };
  image =
    "https://upload.wikimedia.org/wikipedia/commons/thumb/3/31/Experian_logo.svg/1280px-Experian_logo.svg.png";
  creditHealthColor;
  creditscore = 0;
  links = [
    {
      name: "Whatsapp",
      link: "",
      image: "",
      icon: "fa fa-whatsapp",
      bg: "#4caf50",
      fontSize: "2.5rem"
    },
    {
      name: "Facebook",
      link: "",
      image: "",
      icon: "fab fa-facebook-f",
      bg: "#3b5998",
      fontSize: "2.5rem"
    },
    {
      name: "Gmail",
      link: "",
      image: "",
      icon: "fa fa-google",
      bg: "#f34235",
      fontSize: "2.5rem"
    },
    {
      name: "Copy link",
      link: "",
      image: "",
      icon: "fas fa-link",
      bg: "#0973ba",
      fontSize: "2.5rem"
    }
  ];
  userInfo: { userName: any; userId: any };
  userId = this.ls.get("userId");
  credit: boolean = false;
  userName: string;
  noCreditScore: boolean;
  creditHealthContent: string;
  empList = [];
  showCreditScore: boolean;
  messageData: any;
  messageNoCustomerData: any;
  referredCode: string;
  productId: any;
  years: number[];
  months: { no: number; value: string }[];
  accountListDetails: any;
  accountDetail: any;
  totalcreditDetail: any;
  totalnoncreditDetail: any;
  subscriptionFailed: boolean = false;
  selPlans:any;
  selectedTabIndex = 0;
  showupi = true;
  lendername: any;
  AccountNumber: any;
  dateReported: any;
  dateOpened: any;
  highBalance: any;
  currentBalance: any;
  questionKey: any;
  ChallengeConfigGUID: any;
  AnswerChoiceId: any;
  CibliServerDown: boolean = false;
  normalPage: boolean = true;
  scoremeter: boolean = false
  constructor(
    public service: scorocketService,
    private razorpayService: ExternalLibraryService,
    private ls: LocalStorageService,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    public router: Router,
    public activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.redirect()
    this.getuserdetails()
    this.userName = this.ls.get("userName");
    this.userId = this.ls.get("userId");
    if (this.ls.get("token")) {
      this.service.isLogged = true;
      this.plans = [
        {
          id: 0,
          name: "Pre",
          features: [
            "Online support",
            "Full Credit Report",
            "Credit advise",
            "90 days dashboard access"
          ],
          price: "",
          btnTitle: "Coming Soon",
          disabled: true
        },
        {
          id: 1,
          name: "Pro",
          features: [
            "Toll Free Support",
            "Personalised Credit Advise",
            "12 Months Credit Repair Program",
            "Raise Dispute"
          ],
          price: "",
          btnTitle: " Buy Now",
          disabled: false
        },
        {
          id: 2,
          name: "Plus",
          features: [
            "Dedicated Credit Advisor",
            "Lifetime Credit Repair Program",
            "Credit Payment Negotiation",
            "Score Improvement Gurantee"
          ],
          price: "",
          btnTitle: "Coming Soon",
          disabled: true
        }
      ];
      this.getProductsList();
      this.getCibilScore(this.userId)
      // this.loadCreditScore(this.userId,this.productId)
      this.razorpayService
        .lazyLoadLibrary("https://checkout.razorpay.com/v1/checkout.js")
        .subscribe();
    } else {
      this.service.isLogged = false;
    }
    this.referredCode = this.ls.get("referralCode");
    this.loadDates();
    this.activatedRoute.queryParams.subscribe(async params => {
      

      if (params.terms == "true") {
        this.getProductsList().then(val => {
          if (val) {
            // this.reportRazor();
          }
        });
      }
    });
    
    this.selPlans = this.ls.get('plan')
  }

  copyLink(inputElement) {
    inputElement.select();
    document.execCommand("copy");
    inputElement.setSelectionRange(0, 0);
  }
  firstName:any
  lastName:any;
  mobile:any;
  getuserdetails(){
    this.service.getProfile(this.userId).subscribe (
      res => {
        console.log(res)
        if(res.message == 'success'){
          this.firstName = res.data.name.first;
          this.lastName = res.data.name.last;
          this.mobile = res.data.phone[0].number
        }
      }
    )
  }
  cibilreq(){
    const payload ={}
    this.service.cibilReq(payload).subscribe ()
  }
payProduct: any;
  getProductsList() {
    return new Promise((resolve, reject) => {
      this.service.getProducts().subscribe(
        res => {
          if (res.message == "success") {
            this.productsDetail = res.data;
            this.payProduct = res.data[0].onProductPurchaseSubscription.costBreakup[0]._id

            resolve(this.productsDetail);
            // this.service.getProductId(this.userId).subscribe(
            //   res => {
            //     if(res.message == "success"){
            //       this.productId = res.data[0].productId;
            //       console.log('pro',this.productId)
            //     }
            //   }
            // )
          } else {
            reject(false);
          }
        },
        err => {}
      );
    });
  }
  removeIdQueryParam() {
    const currentUrlTree = this.router.parseUrl(this.router.url);
    delete currentUrlTree.queryParams['plan'];
  
    const navigationExtras: NavigationExtras = {
      queryParams: currentUrlTree.queryParams,
      queryParamsHandling: 'merge',
      preserveFragment: true,
    };
    this.router.navigate(['/user']);
  }
  getCibilScore(userId) {
    this.service.getDashboardReport(userId).subscribe(
      res => {
          if (res.data.reports[0].report.finalReport[0].reportDetails.GetCustomerAssetsResponse.GetCustomerAssetsSuccess.Asset.TrueLinkCreditReport.TradeLinePartition) {
          this.normalPage = false
          this.showCreditScore = true;
            let creditScore = res.data.reports[0].score;
            this.acc = res.data.reports[0].report.finalReport[0].reportDetails
            // let creditScore = "751"
            this.empList.push(parseInt(creditScore));
            this.validateCreditScore(creditScore);
            this.accountListDetails = res.data.reports[0].report.finalReport[0].reportDetails.GetCustomerAssetsResponse.GetCustomerAssetsSuccess.Asset.TrueLinkCreditReport.TradeLinePartition
            this.currentBalance = this.accountListDetails.reduce((total, res) => {
              const totalCurrentBal = parseInt(res.Tradeline.currentBalance, 10) || 0;
              return total + totalCurrentBal;
            }, 0);
            
            this.numberOfAcc = this.accountListDetails.length
            // this.noCreditScore = false;
            ZC.LICENSE = [
              "569d52cefae586f634c54f86dc99e6a9",
              "b55b025e438fa8a98e32482b5f768ff5"
            ];
            let feed = callback => {
              let tick = {};
              tick["plot0"] = Math.ceil(350 + Math.random() * 500);
              callback(JSON.stringify(tick));
            };

            let chartConfig = {
              
              type: "gauge",
              globals: {
                fontSize: "15px"
              },
              plot: {
                valueBox: {
                  text: "%v", // default
                  fontSize: "45",
                  placement: "center",
                  rules: [
                    {
                      text: "%v",
                      rule: "%v < 900 && %v > 850",
                      color: "#6377ab"
                    },
                    {
                      text: "%v",
                      rule: "%v < 850 && %v > 750",
                      color: "#6377ab"
                    },
                    {
                      text: "%v",
                      rule: "%v < 750 && %v > 650",
                      color: "#6377ab"
                    },
                    {
                      text: "%v",
                      rule: "%v < 650 && %v > 500",
                      color: "#6377ab"
                    },
                    {
                      text: "%v",
                      rule: "%v <  500",
                      color: "#6377ab"
                    }
                  ]
                },
                size: "100%"
              },
              scale: {
                "size-factor": "70%" //Modify your gauge chart size.
              },
              plotarea: {
                marginTop: "80px"
              },
              scaleR: {
                aperture: 180,
                center: {
                  visible: false
                },
                item: {
                  offsetR: 0,
                  rules: [
                    {
                      offsetX: "15px",
                      rule: "%i == 9"
                    }
                  ]
                },
                labels: ["300", "", "", "", "", "", "", "", "", "", "", "900"],
                maxValue: 900,
                minValue: 300,
                ring: {
                  rules: [
                    {
                      backgroundColor: "#59871b",
                      rule: "%v < 900 && %v >= 850"
                    },
                    {
                      backgroundColor: "#8cda2a",
                      rule: "%v < 850 && %v >= 750"
                    },
                    {
                      backgroundColor: "#ecc11c",
                      rule: "%v < 750 && %v >= 650"
                    },
                    {
                      backgroundColor: "#f28822",
                      rule: "%v < 650 && %v >= 500"
                    },
                    {
                      backgroundColor: "#ed5127",
                      rule: "%v < 500 && %v >= 300"
                    }
                  ],
                  size: "40px"
                },
                step: 50,
                tick: {
                  visible: false
                }
              },
              tooltip: {
                borderRadius: "5px"
              },
              refresh: {
                type: "feed",
                url: "feed()",
                interval: 1500,
                resetTimeout: 1000,
                transport: "js"
              },
              series: [
                {
                  values: this.empList, // starting value
                  backgroundColor: "#6377ab",
                  csize: "1%",
                  indicator: [10, 10, 10, 10, 0.75],
                  animation: {
                    effect: "ANIMATION_EXPAND_VERTICAL",
                    method: "ANIMATION_BACK_EASE_OUT",
                    sequence: "null",
                    speed: 900
                  }
                }
              ]
            };
            zingchart.render({
              id: "myChart",
              data: chartConfig
            });
          } else {
            this.validateCreditScore(0);
          }
      }
    )
  }
  getCreditScore(userId) {
    this.service.getProductId(userId).subscribe(
      res => {
        if (res) {
          if (res.data[0].productId) {
            this.credit = true;
            this.productId = res.data[0].productId;
            this.loadCreditScore(userId, res.data[0].productId);
            this.normalPage = false
            this.showCreditScore = true;
            if (this.productId) {
              this.ls.remove('plan')
            }
            if(this.selPlans){
              this.subscribe()
            }
          } else {
            this.showCreditScore = false;
            ZC.LICENSE = [
              "569d52cefae586f634c54f86dc99e6a9",
              "b55b025e438fa8a98e32482b5f768ff5"
            ];
            let feed = callback => {
              let tick = {};
              tick["plot0"] = Math.ceil(350 + Math.random() * 500);
              callback(JSON.stringify(tick));
            };

            let chartConfig = {
              type: "gauge",
              globals: {
                fontSize: "15px"
              },
              plot: {
                valueBox: {
                  rules: [
                    {
                      text: "%v",
                      rule: "%v < 900 && %v > 850",
                      color: "#6377ab"
                    },
                    {
                      text: "%v",
                      rule: "%v < 850 && %v > 750",
                      color: "#6377ab"
                    },
                    {
                      text: "%v",
                      rule: "%v < 750 && %v > 650",
                      color: "#6377ab"
                    },
                    {
                      text: "%v",
                      rule: "%v < 650 && %v > 500",
                      color: "#6377ab"
                    },
                    {
                      text: "%v",
                      rule: "%v <  500",
                      color: "#6377ab"
                    }
                  ]
                },
                size: "100%"
              },
              scale: {
                "size-factor": "70%" //Modify your gauge chart size.
              },
              plotarea: {
                marginTop: "80px"
              },
              scaleR: {
                aperture: 180,
                center: {
                  visible: false
                },
                item: {
                  offsetR: 0,
                  rules: [
                    {
                      offsetX: "15px",
                      rule: "%i == 9"
                    }
                  ]
                },
                labels: ["300", "", "", "", "", "", "", "", "", "", "", "900"],
                maxValue: 900,
                minValue: 300,
                ring: {
                  rules: [
                    {
                      backgroundColor: "#59871b",
                      rule: "%v < 900 && %v >= 850"
                    },
                    {
                      backgroundColor: "#8cda2a",
                      rule: "%v < 850 && %v >= 750"
                    },
                    {
                      backgroundColor: "#ecc11c",
                      rule: "%v < 750 && %v >= 650"
                    },
                    {
                      backgroundColor: "#f28822",
                      rule: "%v < 650 && %v >= 500"
                    },
                    {
                      backgroundColor: "#ed5127",
                      rule: "%v < 500 && %v >= 300"
                    }
                  ],
                  size: "40px"
                },
                step: 50,
                tick: {
                  visible: false
                }
              },
              tooltip: {
                borderRadius: "5px"
              },
              refresh: {
                type: "feed",
                transport: "js",
                url: "feed()",
                interval: 1500,
                resetTimeout: 1000
              },
              series: [
                {
                  values: [755], // starting value
                  backgroundColor: "black",
                  indicator: [10, 10, 10, 10, 0.75],
                  animation: {
                    effect: 2,
                    method: 1,
                    sequence: 4,
                    speed: 900
                  }
                }
              ]
            };

            zingchart.render({
              id: "myChart",
              data: chartConfig
            });

            // this.noCreditScore = false;
          }
        } else {
          this.validateCreditScore(0);
        }
      },
      err => {}
    );
  }


  async reportpayload(userId,productId) {
    const files =  await this.ReportSave(this.acc)
    
    console.log('file',files)
    this.service.saveReport(userId,productId,files).subscribe(res =>{
      console.log(res)
    },
    err => {
      console.log(err)
    } )
  }
 acc: any
  numberOfAcc: any
  loadCreditScore(userId, productId) {
    this.service.getCreditReport(userId, productId).subscribe(
      res => {
        if (res.message == "success") {
          this.scoremeter = true;
          this.showCreditScore = true;
          if (res.data.reports[0].report.finalReport[0].reportDetails.GetCustomerAssetsResponse.GetCustomerAssetsSuccess.Asset.TrueLinkCreditReport.TradeLinePartition) {
            let creditScore = res.data.reports[0].score;
            this.acc = res.data.reports[0].report.finalReport[0].reportDetails
            // let creditScore = "751"
            this.empList.push(parseInt(creditScore));
            this.validateCreditScore(creditScore);
            this.accountListDetails = res.data.reports[0].report.finalReport[0].reportDetails.GetCustomerAssetsResponse.GetCustomerAssetsSuccess.Asset.TrueLinkCreditReport.TradeLinePartition
            this.currentBalance = this.accountListDetails.reduce((total, res) => {
              const totalCurrentBal = parseInt(res.Tradeline.currentBalance, 10) || 0;
              return total + totalCurrentBal;
            }, 0);
            
            this.numberOfAcc = this.accountListDetails.length
            // this.noCreditScore = false;
            this.showCreditScore = true;
            ZC.LICENSE = [
              "569d52cefae586f634c54f86dc99e6a9",
              "b55b025e438fa8a98e32482b5f768ff5"
            ];
            let feed = callback => {
              let tick = {};
              tick["plot0"] = Math.ceil(350 + Math.random() * 500);
              callback(JSON.stringify(tick));
            };

            let chartConfig = {
              
              type: "gauge",
              globals: {
                fontSize: "15px"
              },
              plot: {
                valueBox: {
                  text: "%v", // default
                  fontSize: "45",
                  placement: "center",
                  rules: [
                    {
                      text: "%v",
                      rule: "%v < 900 && %v > 850",
                      color: "#6377ab"
                    },
                    {
                      text: "%v",
                      rule: "%v < 850 && %v > 750",
                      color: "#6377ab"
                    },
                    {
                      text: "%v",
                      rule: "%v < 750 && %v > 650",
                      color: "#6377ab"
                    },
                    {
                      text: "%v",
                      rule: "%v < 650 && %v > 500",
                      color: "#6377ab"
                    },
                    {
                      text: "%v",
                      rule: "%v <  500",
                      color: "#6377ab"
                    }
                  ]
                },
                size: "100%"
              },
              scale: {
                "size-factor": "70%" //Modify your gauge chart size.
              },
              plotarea: {
                marginTop: "80px"
              },
              scaleR: {
                aperture: 180,
                center: {
                  visible: false
                },
                item: {
                  offsetR: 0,
                  rules: [
                    {
                      offsetX: "15px",
                      rule: "%i == 9"
                    }
                  ]
                },
                labels: ["300", "", "", "", "", "", "", "", "", "", "", "900"],
                maxValue: 900,
                minValue: 300,
                ring: {
                  rules: [
                    {
                      backgroundColor: "#59871b",
                      rule: "%v < 900 && %v >= 850"
                    },
                    {
                      backgroundColor: "#8cda2a",
                      rule: "%v < 850 && %v >= 750"
                    },
                    {
                      backgroundColor: "#ecc11c",
                      rule: "%v < 750 && %v >= 650"
                    },
                    {
                      backgroundColor: "#f28822",
                      rule: "%v < 650 && %v >= 500"
                    },
                    {
                      backgroundColor: "#ed5127",
                      rule: "%v < 500 && %v >= 300"
                    }
                  ],
                  size: "40px"
                },
                step: 50,
                tick: {
                  visible: false
                }
              },
              tooltip: {
                borderRadius: "5px"
              },
              refresh: {
                type: "feed",
                url: "feed()",
                interval: 1500,
                resetTimeout: 1000,
                transport: "js"
              },
              series: [
                {
                  values: this.empList, // starting value
                  backgroundColor: "#6377ab",
                  csize: "1%",
                  indicator: [10, 10, 10, 10, 0.75],
                  animation: {
                    effect: "ANIMATION_EXPAND_VERTICAL",
                    method: "ANIMATION_BACK_EASE_OUT",
                    sequence: "null",
                    speed: 900
                  }
                }
              ]
            };
            zingchart.render({
              id: "myChart",
              data: chartConfig
            });
            console.log('chart is working')
          } else {
            this.validateCreditScore(0);
          }
        } else {
          this.AuthenticationOTP(productId)
          this.messageNoCustomerData = res.data[0].errorString;
          this.showCreditScore = false
          this.normalPage = false;
          this.service.snackbar(res.data[0].errorString);
          this.noButton = true;
        }
        this.scoremeter = false
      },
      err => {
        // this.AuthenticationOTP(productId)
        this.CibliServerDown = true
        this.showCreditScore = false
        this.noButton = true;
        if (err.error.message == "Subscription not found") {
          this.subscriptionFailed = true;
          
        }
      }
    );
  }
  validateCreditScore(e) {
    this.creditscore = e;
    if (e >= 300 && e <= 500) {
      this.creditHealthColor = "Bad";
      this.creditHealthContent =
        "Oh Dear, you have a poor history of financial management, resulting out of bad debts, payment defaults and poor credit utilization patterns. You need a lot of help from our experts at scorocket.";
    } else if (e >= 500 && e <= 650) {
      this.creditHealthColor = "Fair";
      this.creditHealthContent =
        "Guess you have made some bad financial decisions with instances of payment defaults, unsecured loans etc. you need to work towards bettering your credit score to avail better credit facilities";
    } else if (e >= 650 && e <= 750) {
      this.creditHealthColor = "Average";
      this.creditHealthContent =
        "Ok! You are just a few points away from a good credit score. All you need to do is take a few minor steps and become eligible for the best offers on loans and credit cards from banks.";
    } else if (e >= 750 && e <= 850) {
      this.creditHealthColor = "Good";
      this.creditHealthContent =
        "Superb, You have good financial responsibility and you have good control on credit score and credit worthiness, Most banks love to work with you.";
    } else {
      this.creditHealthColor = "Excellent";
      this.creditHealthContent =
        "Excellent, You are celebrity amongst all customers, all banks and financial companies waiting for you to be their privileged customer and you get premium services at low rates, ";
    }
  }
  reportRazor() {
    this.proceed();
  }

  public proceed() {
    this.service.snackbar("connecting to razorpay...", "close", 60000);
    this.router.navigate(["/Dashboardotp"])
    this.userInfo = {
      userName: this.ls.get("userName"),
      userId: this.ls.get("userId")
    };
    // this.getCreditScore(this.userId)
    if (!this.userInfo.userId) {
      this.service.snackbar("Please login to purchase!");
      return;
    }
    const payload = {
      userId: this.userInfo.userId,
      productId: this.productsDetail[0]._id || "",
      paymentPartner: "razorpay",
      orderType: "product"
    };

    this.service.buyNow(payload).subscribe(
      res => {
        if (res.message == "success") {

          this.service.dismissSnackbar();
          this.getCreditScore(this.userId);
          if (res.data.orderId && res.data.subscriptionId) {
            this.RAZORPAY_OPTIONS.subscription_id = res.data.subscriptionId;
            this.RAZORPAY_OPTIONS.prefill.email = this.ls.get("userEmail");
            this.RAZORPAY_OPTIONS.prefill.name = this.ls.get("userName");
            this.RAZORPAY_OPTIONS.amount = 0 + "00";

            // binding this object to both success and dismiss handler
            this.RAZORPAY_OPTIONS["handler"]= this.razorPaySuccessHandler.bind(
              this
            );

            // this.showPopup();
            let razorpay = new Razorpay(this.RAZORPAY_OPTIONS);
            razorpay.open();
          }
        }
      },
      err => {
        this.service.dismissSnackbar();
        this.service.snackbar("Failed to process, please try after sometime!");
      }
    );
  }

  public razorPaySuccessHandler(response) {
    if (response.razorpay_payment_id) {
      this.getCreditScore(this.userId);
    }
    this.razorpayResponse = `Razorpay Response`;
    this.showModal = true;
  }

  showPdf(url, prodName) {
    // const linkSource = "data:application/pdf;base64," + url;
    console.log('daata',url)
    const linkSource = url;
    const downloadLink = document.createElement("a");
    const fileName = `credit_report${prodName}.pdf`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  ReportSave(data){
    const jsonString = JSON.stringify(data, null, 2);
    const blob = new Blob([jsonString], { type: 'application/json' });
    return saveAs(blob, 'report.json');
  }

  loadDates = async () => {
    let years = await this.getyears();
    this.dates = {
      year: years[0],
      month: (new Date().getMonth() + 1).toString().padStart(2, '0')
    };
    (this.years = years), (this.months = await this.getMonths());
  };

  loadReport = async () => {
    const month = this.dates.month;
    let lastDate = await this.daysInMonth(parseInt(month), this.dates.year);
    this.fromDate = this.datepipe.transform(
      new Date(`${month}/${"1"}/${this.dates.year}`),
      "yyyy-MM-dd"
    );
    this.tillDate = this.datepipe.transform(
      new Date(`${month}/${lastDate}/${this.dates.year}`),
      "yyyy-MM-dd"
    );
    const reqPayload = {
      userId: localStorage.getItem("userId"),
      query: `?fromDate=${month}&tillDate=${this.dates.year}&report=${true}`,
      productId: this.productId,
    };
    this.downloading = true;
    let url = ''
    this.service.getReports(reqPayload).subscribe(
      async res => {
        if (res) {
          this.downloading = false;
          res.data.reports.forEach((response:any) => {
            url = response.reportLocation.url
          })
          this.showPdf(url, this.productId);
        } 
      },
      err => {
        if (err.status == 400) {
          this.service.snackbar("Record Not found!");
        }
        this.downloading = false;
      }
    );
  };
  cibilReport(){
    const payload = {
        "Clientkey": this.userId,
        "RequestKey":this.userId,
        "PartnerCustomerId":this.userId
    }
    this.service.getProductToken(payload).subscribe(
      res => {
        if(res.message = "succees") {
          const webtoken = res.data.GetProductWebTokenResponse.GetProductWebTokenSuccess.WebToken;
          let downloadUrl  = `https://atlasls-in-live.sd.demo.truelink.com/CreditView/webtokenasset.page?enterprise=InventivasFP&pcc=${this.userId}&webtoken=${webtoken}`;
          // let downloadUrl = `https://myscore.cibil.com/CreditView/webtokenasset.page?enterprise=InventivasFP&pcc=Test2009&webtoken=${webtoken}`
          window.open(downloadUrl, '_blank');
        }
        
      }
    )
  }

  daysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
  };

  getyears = () => {
    const now = new Date().getUTCFullYear();
    return Array(now - (now - 30))
      .fill("")
      .map((v, idx) => now - idx) as Array<number>;
  };
  stgOneHitId:any;
  stgTwoHitId:any

  
  openDialog(productId) {
    const orederpayload = {
      userId: this.userId,
      productId: productId,
      paymentPartner: "phonepe",
      orderType: "product"
    };
    this.service.buyNow(orederpayload).subscribe(
      res => {
        if(res.message == 'success'){
          const url = res.data.paymentURL.replace(/"/g, '')
          console.log('url',url)
          window.location.href = url;
        }
      },
      err => {
        if (err.status == 400) {
          this.service.snackbar("Product already purchased!");
        }
      }
      
    )  
  }

  AuthenticationOTP(productid){
    const payload = {
      "firstName": this.firstName,
    "lastName": this.lastName,
    "mobileNo": this.mobile,
    "email": this.ls.get("userEmail")
    }
    this.service.experianotp(this.userId,productid,payload).subscribe(
      async res => {
        try {
        if(res) {
          this.ChallengeConfigGUID = res.data.reports[0].report.finalReport[0].reportDetails.GetAuthenticationQuestionsResponse.GetAuthenticationQuestionsSuccess.ChallengeConfigGUID,
          this.AnswerChoiceId = res.data.reports[0].report.finalReport[0].reportDetails.GetAuthenticationQuestionsResponse.GetAuthenticationQuestionsSuccess.question.AnswerChoice.AnswerChoiceId,
          this.questionKey = res.data.reports[0].report.finalReport[0].reportDetails.GetAuthenticationQuestionsResponse.GetAuthenticationQuestionsSuccess.question.Key
          if(this.ChallengeConfigGUID && this.AnswerChoiceId && this.questionKey ) {
  
          this.service.getProductId(this.userId).subscribe(
            response => {
              if(response){ 
                const dialogRef = this.dialog.open(DashboardOtpComponet ,{
                  width: "700px",
                  disableClose: true,
            data: { 
              value: true, 
              userId : this.userId , 
              productId : this.productId,
              ChallengeConfigGUID : this.ChallengeConfigGUID,
              questionKey : this.questionKey,
              AnswerChoiceId : this.AnswerChoiceId
            },
            
          });
      
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              const ApiResult = result.res.data.reports[0].report.finalReport[0].reportDetails.VerifyAuthenticationAnswersResponse.IVStatus
              if (ApiResult == 'Success'){
                this.getCreditScore(this.userId);
              }else{
                this.AuthenticationOTP(this.productId)
              }
              
            }
          });
              }
            }
          )
          }     
        }
      } catch (error){
        this.getCreditScore(this.userId)
        this.service.snackbar('Try after sometime!')
        this.noButton = false;
        console.log(error)
      }
      },
      err => {
        if (err.status == 400) {
          this.service.snackbar("Record Not found!");
        }
      }
    )
  }

  
  subscribe(){
    const dialogRef1= this.dialog.open(SubscritionPlansComponent,{
      width: '950px',
      height: '600px',
      autoFocus: false,
      data: {
        plan : this.selPlans,
      },
    closeOnNavigation: true, 
    disableClose: false,
  
    })
    
    dialogRef1.afterClosed().subscribe(result => {
      this.ls.remove('plan');
      if (result === 'closed') {
        this.removeIdQueryParam()
      }
      if(result.plan === 'plan' ){
        this.openDialog(result.productId)
      }
      if(result.month === 'monthly'){
        this.showupi = true
        const dialogRef = this.dialog.open(DashboardOtpComponet ,{
          data : {
            showupi : true,
            productId: result.productId
          },
          width: "700px",
        })
      }
      
    });
  }
  

  getMonths = () => {
    return [
      { no: 1, value: "January" },
      { no: 2, value: "February" },
      { no: 3, value: "March" },
      { no: 4, value: "April" },
      { no: 5, value: "May" },
      { no: 6, value: "June" },
      { no: 7, value: "July" },
      { no: 8, value: "August" },
      { no: 9, value: "September" },
      { no: 10, value: "October" },
      { no: 11, value: "November" },
      { no: 12, value: "December" }
    ];
  };
  redirect(){
    this.activatedRoute.queryParams.subscribe(params => {
      if(params.token){
        const token = params.token;
        this.ls.set("token",token)
        const helper = new JwtHelperService();
        const decoded = helper.decodeToken(token);
        this.service.isLogged = true;
        this.ls.set("userName", decoded.name.first);
        this.ls.set("userId", decoded.userId);
        this.ls.set("userEmail", decoded.email);
        this.ls.set("referralCode", decoded.referralCode);
        this.ls.set("role", decoded.role);
        const role = this.ls.get("role");
        if (decoded.role == "customer") {
          this.router.navigate(["/user"]);
        }
      }
    })
  }
}

@Component({
  selector: "subscription-popup",
  templateUrl: "subscription-popup.html",
  styleUrls: ["./dashboard.component.scss"]
})
export class SubscriptionPopupDialog {
  showMonthly= false;
  showYearly = true;
  constructor(
    public dialogRef: MatDialogRef<SubscriptionPopupDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  onNoClick(): void {
    this.dialogRef.close();
  }
  monthly(){
    this.showYearly = false;
    this.showMonthly = true ;
  }
  yearly(){
    this.showYearly = true ;
  }
}
