import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-localstorage';

@Injectable()
export class AuthService {
  constructor(private ls: LocalStorageService, private router: Router) { }
  public isAuthenticated(): boolean {
    const token = this.ls.get('token');
    if (token) {
      const helper = new JwtHelperService();
      if (helper.decodeToken(token)) {
        return !helper.isTokenExpired(token);
      } else {
        this.router.navigate(['/login']);
        return false;
      }
    }
  }
}
