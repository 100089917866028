import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from "@angular/material/dialog";

@Component({
  selector: 'app-subscrition-plans',
  templateUrl: './subscrition-plans.component.html',
  styleUrls: ['./subscrition-plans.component.scss']
})
export class SubscritionPlansComponent implements OnInit {
  @Output() dialogClosed = new EventEmitter<string>();
  currentSlide = 0;
  currentIndex = 0;
  showyearly= true;
  showmonthly=false;
  slideWidth: number;
  slides = ['Slide 1', 'Slide 2', 'Slide 3'];
  isOneTimeActive = false;
  isMonthlyActive = false;
  plans = {
    plan1 :"",
    plan2: "",
    plan3:"",
    plan4: "",
    plan5: "",
    plan6: ""
  }

  constructor(
    public dialogRef: MatDialogRef<SubscritionPlansComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.slideWidth = window.innerWidth;
    if(this.data.plan === 'Plan1' ||this.data.plan === 'Plan2' ||this.data.plan === 'Plan3' ){
      this.showyearly= true;
      this.showmonthly=false;
    } else if(this.data.plan === 'Plan4' ||this.data.plan === 'Plan5' ||this.data.plan === 'Plan6') {
      this.showmonthly = true
      this.showyearly = false
    } else {
      this.showyearly = true
    }
  }
 

  prevSlide() {
    this.currentIndex = (this.currentIndex - 1 + 3) % 3;
  }

  nextSlide() {
    this.currentIndex = (this.currentIndex + 1) % 3;
  }
  toggleButton(buttonType: string): void {
    if (buttonType === 'oneTime') {
      this.isOneTimeActive = true;
      this.isMonthlyActive = false;
      this.showyearly = true;
      this.showmonthly = false
      
    } else if (buttonType === 'monthly') {
      this.isOneTimeActive = false;
      this.isMonthlyActive = true;
      this.showyearly = false;
      this.showmonthly = true;
    }
  }

  monthly(){
    this.showyearly = false;
    this.showmonthly = true;
  }
  yearly(){
    this.showyearly = true;
    this.showmonthly = false
  }
  closeDialog() {
    this.dialogRef.close('closed');
    this.dialogClosed.emit('closed');
  }
  buyPlan(id){
    this.dialogRef.close({plan:'plan',productId:id})
  }
  monthlyPlans(id){
    this.dialogRef.close({month:'monthly',productId:id})
  }

}
