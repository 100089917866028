import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-admin-customer-view",
  templateUrl: "./admin-customer-view.component.html",
  styleUrls: ["./admin-customer-view.component.scss"]
})
export class AdminCustomerViewComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
