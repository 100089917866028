import { Component, OnInit } from "@angular/core";
@Component({
  selector: "app-advisory-customer-view",
  templateUrl: "./advisory-customer-view.component.html",
  styleUrls: ["./advisory-customer-view.component.scss"]
})
export class AdvisoryCustomerViewComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
