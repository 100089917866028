import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { scorocketService } from "src/app/providers/scorocket.service";
import { ActivatedRoute, Router } from "@angular/router";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  CanActivate
} from "@angular/router";
import { LocalStorageService } from "ngx-localstorage";
import { AuthService } from "../auth/auth.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class RoleguardService implements CanActivate {
  constructor(
    private ls: LocalStorageService,
    private service: AuthService,
    private router: Router,
    public scorocketService: scorocketService,
    private activatedRoute: ActivatedRoute
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    let expectedRole = "";
    console.log("route", route);
    const routerRole = route.data.expectedRole;
    console.log("routerRole", routerRole);
    const helper = new JwtHelperService();
    const jwt = localStorage.getItem("token");
    const decoded = helper.decodeToken(jwt);
    const localRole = decoded.role;
    routerRole.map(role => {
      console.log("123role", role);
      if (role === localRole) {
        expectedRole = role;
      }
    });
    // this.activatedRoute.queryParams.subscribe(params => {
    //   if(params){
    //     const token = params.token;
    //     const decoded = helper.decodeToken(token);
    //     const localrole = decoded.role;
    //     routerRole.map(role => {
    //       if(role == localRole) {
          
    //       }
    //     })
    //   }
    // })
    if (!this.service.isAuthenticated() || localRole !== expectedRole) {
      this.router.navigate(["/login"]);
      localStorage.clear();
      sessionStorage.clear();
      this.scorocketService.snackbar("Unauthorized Access");
      return false;
    }
    return true;
  }
}
