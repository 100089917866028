import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-admin-analyst-view",
  templateUrl: "./admin-analyst-view.component.html",
  styleUrls: ["./admin-analyst-view.component.scss"]
})
export class AdminAnalystViewComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
