import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { scorocketService } from "src/app/providers/scorocket.service";
import { DatePipe } from "@angular/common";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { LocalStorageService } from "ngx-localstorage";
import { promise } from "protractor";
import { resolve } from "url";
@Component({
  selector: "app-admin-all-analyst",
  templateUrl: "./admin-all-analyst.component.html",
  styleUrls: ["./admin-all-analyst.component.scss"]
})
export class AdminAllAnalystComponent implements OnInit {
  advisorId: string;
  search: FormGroup;
  reloading: boolean;
  advisordata: any;
  analystData: any;
  showUsers: boolean;
  sortOrder = "asc";
  showdsc: boolean;
  showasc: boolean;
  p = 1;
  nodataMessage: string;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  deleteUserId: string;

  constructor(
    public ls: LocalStorageService,
    public fb: FormBuilder,
    public router: Router,
    public service: scorocketService,
    public datepipe: DatePipe
  ) {}

  ngOnInit() {
    this.advisorId = this.ls.get("userId");
    this.search = this.fb.group({
      search: ["", [Validators.required]]
    });
    this.getAdvisors("analyst");
    this.getAdvisors("advisor");
  }

  getAdvisors(type) {
    this.showasc = false;
    this.showdsc = false;
    this.sortOrder = "asc";
    let role = type;
    const searchText = this.search.value.search;
    this.service.getUsers(searchText, role).subscribe(
      async res => {
        if (res.message == "success") {
          this.showUsers = true;
          if (type == "advisor") {
            this.advisordata = res.data.users;
          }

          if (type == "analyst") {
            this.analystData = res.data.users;
            const adminId = localStorage.getItem("userId");
            this.analystData.forEach(async (analyst, i) => {
              let selectedAdvisors = [];
              let advisors: any = [];
              advisors = await this.getAdvisorsByAnalystId(
                adminId,
                analyst.userId
              );
              advisors.forEach(advisor => {
                selectedAdvisors.push(advisor.userId);
              });
              this.analystData[i]["advisors"] = selectedAdvisors;
            });
          }
        }
      },
      err => {
        if ((err.message = "Search did not match any")) {
          this.showUsers = false;
          this.nodataMessage = "No data record matching name";
        }
      }
    );
  }

  getAdvisorsByAnalystId = (adminId, analystId) => {
    return new Promise((resolve, reject) => {
      this.service.getAnalystOfAdvisor(adminId, analystId).source.subscribe(
        res => {
          if (res) {
            resolve(res.body.data);
          }
        },
        err => {
          reject(err);
        }
      );
    });
  };

  getadvisor() {
    this.reloading = true;
    this.showasc = false;
    this.showdsc = false;
    this.sortOrder = "asc";
    this.search.reset();
    let role = "advisor";
    let searchText = "";
    this.service.getUsers(searchText, role).subscribe(
      res => {
        if (res.message == "success") {
          this.advisordata = res.data.users;
          this.reloading = false;
          this.showUsers = true;
        }
      },
      err => {
        this.showUsers = false;
        this.nodataMessage = "No data found";
      }
    );
  }

  selectAdvisor = (advisorId, analystId, currentAdvisors, index) => {
    const rmDiff = currentAdvisors.filter(x => !advisorId.includes(x));
    const addDiff = advisorId.filter(x => !currentAdvisors.includes(x));
    let advisorToAdd: string;
    let message = "";
    let payload: any = {};
    if (rmDiff.length) {
      payload = {
        relatedTo: {}
      };
      advisorToAdd = rmDiff[0];
      message = "Advisor unassigned";
    }
    if (addDiff.length) {
      payload = {
        relatedTo: {
          relationType: "analyst",
          relationUserId: analystId
        }
      };
      message = "Advisor Assigned";
      advisorToAdd = addDiff[0];
    }

    this.service
      .addAnalystToAdvisor(
        localStorage.getItem("userId"),
        advisorToAdd,
        payload
      )
      .subscribe(
        res => {
          this.analystData[index].advisors = advisorId;
          this.service.snackbar(message);
          this.getAdvisors("analyst");
        },
        err => {
          this.service.snackbar("Advisor Failed To Assing To The Analyst");
        }
      );
  };

  sortName() {
    if (this.sortOrder == "asc") {
      this.advisordata.sort((a, b) => a.name.first.localeCompare(b.name.first));
      this.sortOrder = "dec";
      this.showasc = true;
      this.showdsc = false;
    } else if (this.sortOrder == "dec") {
      this.advisordata.sort((a, b) => b.name.first.localeCompare(a.name.first));
      this.sortOrder = "asc";
      this.showasc = false;
      this.showdsc = true;
    }
  }

  setDelete = userId => {
    this.deleteUserId = userId;
  };

  deleteAnalyst = () => {
    this.service
      .deleteAnalyst(localStorage.getItem("userid"), this.deleteUserId)
      .subscribe(
        res => {
          if (res) {
            this.service.snackbar("Analyst Saved Successfully");
            this.getAdvisors("analyst");
          }
        },
        err => {
          this.service.snackbar("Faied To Delete Analyst");
        }
      );
  };
}
