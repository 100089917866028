import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-analyst-send-query',
  templateUrl: './analyst-send-query.component.html',
  styleUrls: ['./analyst-send-query.component.scss']
})
export class AnalystSendQueryComponent implements OnInit {
  account:boolean =true;
  csAccount: boolean;
  fileName: string;
  file: string;
  constructor() { }

  ngOnInit() {
  }
  
  myAccount(){
    this.account = true;
    this.csAccount = false;
  }
  customerAccount(){
    this.account = false;
    this.csAccount = true;
  }

  fileEvent(fileInput: Event){
    console.log(fileInput)
    let file = (<HTMLInputElement>fileInput.target).files[0];
    this.fileName = file.name;
    console.log("fileName",this.fileName)
}
fileClick(fileInput: Event){
  console.log(fileInput)
  let file = (<HTMLInputElement>fileInput.target).files[0];
  this.file = file.name;
  console.log("fileName",this.fileName)
}


}
