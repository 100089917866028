import { Component, OnInit } from '@angular/core';
import { scorocketService } from 'src/app/providers/scorocket.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  // endPoint = Info@scorocket.in
  contactForm: FormGroup;
  sending: boolean;
  constructor(private fb: FormBuilder, private service: scorocketService) { }

  ngOnInit() {
    this.contactForm = this.fb.group({
      fullName: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(20), Validators.pattern('[a-zA-Z]+')]],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      phoneNumber: ['', [Validators.required, Validators.pattern('[0-9]\\d{9}')]],
      subject: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(20), Validators.pattern('[a-zA-Z]+')]],
      message: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(1000)]]
    });
  }

  submitContact() {
    if (this.contactForm.valid) {
      this.sending = true;
      this.service.contact(this.contactForm.value).subscribe(res=>{
        if(res.message == "success"){
          this.service.snackbar('Mail Sent')
          this.contactForm.reset();
          this.sending = false;
        }
      },err=>{
        this.sending = false;
      })
    } else {
      this.service.snackbar('Please fill the required fields!', 'close');
    }
  }
}
