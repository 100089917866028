import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'ngx-localstorage';

@Component({
  selector: 'app-advisory-sidebar',
  templateUrl: './advisory-sidebar.component.html',
  styleUrls: ['./advisory-sidebar.component.scss']
})
export class AdvisorySidebarComponent implements OnInit {
  userId: any;
  userDetails: any;
  userName: string;

  constructor(private ls: LocalStorageService) { }

  ngOnInit() {
    this.userName = this.ls.get('userName');
    this.userId = this.ls.get('userId');
  }

}
