import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { scorocketService } from "src/app/providers/scorocket.service";
import { DatePipe } from "@angular/common";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { LocalStorageService } from "ngx-localstorage";

@Component({
  selector: "app-admin-all-advisors",
  templateUrl: "./admin-all-advisors.component.html",
  styleUrls: ["./admin-all-advisors.component.scss"]
})
export class AdminAllAdvisorsComponent implements OnInit {
  advisorId: string;
  search: FormGroup;
  reloading: boolean;
  advisordata: any;
  showUsers: boolean;
  sortOrder = "asc";
  showdsc: boolean;
  showasc: boolean;
  p = 1;
  nodataMessage: string;

  constructor(
    public ls: LocalStorageService,
    public fb: FormBuilder,
    public router: Router,
    public service: scorocketService,
    public datepipe: DatePipe
  ) {}

  ngOnInit() {
    this.advisorId = this.ls.get("userId");
    this.search = this.fb.group({
      search: ["", [Validators.required]]
    });
    this.getAdvisors();
  }

  getAdvisors() {
    this.showasc = false;
    this.showdsc = false;
    this.sortOrder = "asc";
    let role = "advisor";
    const searchText = this.search.value.search;
    this.service.getUsers(searchText, role).subscribe(
      res => {
        if (res.message == "success") {
          this.showUsers = true;
          this.advisordata = res.data.users;
          console.log("advisordata",this.advisordata)
        }
      },
      err => {
        if ((err.message = "Search did not match any")) {
          this.showUsers = false;
          this.nodataMessage = "No data record matching name";
        }
      }
    );
  }

  getadvisor() {
    this.reloading = true;
    this.showasc = false;
    this.showdsc = false;
    this.sortOrder = "asc";
    this.search.reset();
    let role = "advisor";
    let searchText = "";
    this.service.getUsers(searchText, role).subscribe(
      res => {
        if (res.message == "success") {
          this.advisordata = res.data.users;
          this.reloading = false;
          this.showUsers = true;
        }
      },
      err => {
        this.showUsers = false;
        this.nodataMessage = "No data found";
      }
    );
  }

  sortName() {
    if (this.sortOrder == "asc") {
      this.advisordata.sort((a, b) => a.name.first.localeCompare(b.name.first));
      this.sortOrder = "dec";
      this.showasc = true;
      this.showdsc = false;
    } else if (this.sortOrder == "dec") {
      this.advisordata.sort((a, b) => b.name.first.localeCompare(a.name.first));
      this.sortOrder = "asc";
      this.showasc = false;
      this.showdsc = true;
    }
  }
}
