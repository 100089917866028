// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// const url = 'https://418e9b85.ngrok.io';
// const url = "http://192.168.1.42:3000";
// const url = "http://13.233.140.226/api/backend";
const url =    "https://app.scorocket.com/api/backend";
const cibilUrl = "https://app.scorocket.com/api/cibilhawk";
// const url = "https://ea58-111-92-16-151.ngrok.io"
// const url =    "https://a710-111-92-16-151.ngrok.io";

// const url = "http://192.168.1.39:3000";
// const url = "http://192.168.1.27:3000";
// const url = "http://192.168.1.32:3000";
// const url = "https://scorocket.in";

const envObject = {
  scorocketServiceUrl: url
};

// export const environment = {
//   production: false,
//   scorocketServiceUrl: envObject.scorocketServiceUrl
// };
export const environment = {
  production: false,
  scorocketServiceUrls: {
    backend: url,
    cibilhawk: cibilUrl
  }
};

/*
 * For easier debugging in development mode, yo u can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
