import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import { scorocketService } from "src/app/providers/scorocket.service";
import { JwtHelperService } from "@auth0/angular-jwt";

@Component({
  selector: "app-analyst-all-customer",
  templateUrl: "./analyst-all-customer.component.html",
  styleUrls: ["./analyst-all-customer.component.scss"]
})
export class AnalystAllCustomerComponent implements OnInit {
  nonEditableFields = false;
  search: FormGroup;
  // searchUsers;
  productId: any;
  payload: { state: string; reason: string };
  nodataMessage: string;
  showUsers: boolean;
  reloading: boolean;
  show: boolean;
  sortOrder = "asc";
  showasc: boolean;
  showdsc: boolean;
  customerData: any;
  analyst= 'analyst'
  score: any;
  constructor(public service: scorocketService) {}

  ngOnInit() {
    this.getUsers();
    this.checkRole();
  }

  checkRole = () => {
    const helper = new JwtHelperService();
    const jwt = localStorage.getItem("token");
    const decoded = helper.decodeToken(jwt);
    const localRole = decoded.role;
    if (localRole == "advisor") {
      this.nonEditableFields = true;
    }
  };

  getUsers() {
    this.showasc = false;
    this.showdsc = false;
    this.sortOrder = "asc";
    let role = "customer";
    const searchText = "";
    this.service.getUsers(searchText, role).subscribe(
      res => {
        if (res.message == "success") {
          this.showUsers = true;
          this.customerData = res.data.users;
          this.customerData.forEach((customer)=>{
            const ratings =  customer.ratings_doc;
            if(ratings.length){
              ratings[ratings.length-1].reports.forEach(rating=>{
                this.score = rating.score
                customer['showScore'] = this.score
                console.log("res",customer)
              })
            }
          })
          console.log(" this.customerData", this.customerData)
        }
      },
      err => {
        if ((err.message = "Search did not match any")) {
          this.showUsers = false;
          this.nodataMessage = "No data record matching name";
        }
      }
    );
  }

  getusers() {
    // this.upArrow = true;
    this.reloading = true;
    this.showasc = false;
    this.showdsc = false;
    this.sortOrder = "asc";
    let role = "customer";
    let searchText = "";
    this.service.getUsers(searchText, role).subscribe(
      res => {
        if (res.message == "success") {
          this.showUsers = true;
          this.reloading = false;
          this.customerData = res.data.users;
        }
      },
      err => {
        this.showUsers = false;
        this.reloading = false;
        this.nodataMessage = "No data record";
      }
    );
  }

  searchUsers(searchValue) {
    this.showasc = false;
    this.showdsc = false;
    this.sortOrder = "asc";
    if (searchValue == "" || searchValue == undefined || searchValue == null) {
      this.getusers();
    } else {
      this.service.getUsers(searchValue, "customer").subscribe(
        res => {
          if (res.message == "success") {
            this.showUsers = true;
            this.customerData = res.data.users;
          }
        },
        err => {
          if ((err.message = "Search did not match any")) {
            this.showUsers = false;
            this.nodataMessage = "No data record matching name";
          }
        }
      );
    }
  }
}
