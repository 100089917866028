import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { scorocketService } from "src/app/providers/scorocket.service";
import { LocalStorageService } from "ngx-localstorage";

@Component({
  selector: "app-advisory-registration",
  templateUrl: "./advisory-registration.component.html",
  styleUrls: ["./advisory-registration.component.scss"]
})
export class AdvisoryRegistrationComponent implements OnInit {
  hide = true;
  registerForm: FormGroup;
  @ViewChild("inputRef", { static: true }) inputRef;
  disableBtn: boolean = false;
  constructor(
    public fb: FormBuilder,
    public service: scorocketService,
    public ls: LocalStorageService
  ) {}

  ngOnInit() {
    this.registerForm = this.fb.group({
      fullName: [
        "",
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(20),
          Validators.pattern("[a-z A-Z ]+")
        ]
      ],
      lastName: [
        "",
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(20),
          Validators.pattern("[a-z A-Z ]+")
        ]
      ],
      email: [
        "",
        [
          Validators.required,
          Validators.email,
          Validators.pattern("^[a-zA-Z0-9._-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
        ]
      ],
      phoneNumber: [
        "",
        [Validators.required, Validators.pattern("[0-9]\\d{9}")]
      ],
      zip: ["", [Validators.required, Validators.pattern("[0-9]\\d{5}")]],
      address1: [
        "",
        [Validators.required, Validators.minLength(1), Validators.maxLength(20)]
      ],
      landmark: [
        "",
        [Validators.required, Validators.minLength(1), Validators.maxLength(20)]
      ],
      city: [
        "",
        [Validators.required, Validators.minLength(1), Validators.maxLength(20)]
      ],
      state: [
        "",
        [Validators.required, Validators.minLength(1), Validators.maxLength(20)]
      ],
      password: ["", Validators.required],
      role: ["", Validators.required]
    });
  }
  gettingRole() {
    console.log(
      "document",
      (<HTMLInputElement>document.getElementById("rrole")).value
    );
  }

  register() {
    const userId = this.ls.get("userId");
    if (this.registerForm.valid) {
      const payload = {
        name: {
          first: this.registerForm.value.fullName,
          middle: "",
          last: this.registerForm.value.lastName
        },
        role: this.registerForm.value.role,
        userType:this.registerForm.value.role,
        email: this.registerForm.value.email,
        address: [
          {
            type: "current",
            line1: this.registerForm.value.address1,
            landmark: this.registerForm.value.landmark,
            city: this.registerForm.value.city,
            state: this.registerForm.value.state,
            country: "",
            zip: +this.registerForm.value.zip
          }
        ],
        phone: [
          {
            dialCode: "+91",
            number: this.registerForm.value.phoneNumber
          }
        ],
        password: this.registerForm.value.password,
      };
      console.log("payload", payload);
      // this.disableBtn = true;
      this.service.advisorregister(userId, payload).subscribe(
        res => {
          console.log(res);
          if (res.message === "success") {
            this.service.snackbar("Registered successfully!");
            this.registerForm.reset();
          } else {
            this.service.snackbar(res.message);
          }
        },
        err => {
          console.log(err.error.message);
          this.disableBtn = false;
          if (err.error.message) {
            if (err.error.message.includes("expected `email` to be unique.")) {
              this.service.snackbar("Email already exists!");
              return;
            } else if (
              err.error.message.includes("expected `phoneNumber` to be unique.")
            ) {
              this.service.snackbar("Phone Number already exists!");
            }
          } else {
            this.service.snackbar("Registered Failed!");
          }
        }
      );
    } else {
      this.service.snackbar("Please fill the required fields!", "close");
    }
  }
}
