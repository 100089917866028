import { Component, OnInit } from '@angular/core';
import creditscore from '../../creditscore.json';
// import { ChartDataSets } from 'chart.js';
// import { Label, Color } from 'ng2-charts';
import { ChartDataSets, ChartOptions } from "chart.js";
import { Color, Label } from "ng2-charts";
import { DatePipe } from '@angular/common';
import { LocalStorageService } from 'ngx-localstorage';
import { scorocketService } from 'src/app/providers/scorocket.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-score-trend',
  templateUrl: './score-trend.component.html',
  styleUrls: ['./score-trend.component.scss']
})
export class ScoreTrendComponent implements OnInit {
  myDate = new Date();
  chartLabels = [];
  chartData = []
  public lineChartData: ChartDataSets[] = [{ data: [65], label: 'Scorocket', fill: false, lineTension: 0, backgroundColor: "white", pointRadius: 4 }];
  public lineChartLabels: Label[];
  public lineChartColors: Color[] = [
    {
      borderColor: '#44a0de'
    }
  ];
  public lineChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [
        {
          offset: true, 
          gridLines: {
              display: false
            }
        }
      ],
      yAxes: [
        {
          ticks: {
            min: 0,
            max: 900,
            stepSize: 100,
          }
        }
      ]
    }
  };

  public lineChartLegend = true;
  public lineChartType = "line";
  public lineChartPlugins = [];
  abc: any;
  scoreTrend: FormGroup;
  second: any;
  graphResponse: any;
  messageNoCustomer: any;
  showgraph: boolean;
  nograph: boolean;
  creditList: any;
  userName: string;
  userId: string;
  finaldata: any;
  dates: string;
  tillDate: any;
  startDate: string;
  endDate: string;
  prod: void;

  constructor(private datePipe: DatePipe, public ls: LocalStorageService, public service: scorocketService, public fb: FormBuilder) { }

  ngOnInit() {
    this.startDate = this.datePipe.transform(this.myDate, "yyyy-MM-dd");;
    this.endDate = this.datePipe.transform(this.myDate, "yyyy-MM-dd");;;
    this.scoreTrend = this.fb.group({
      fromDate: ['', [Validators.required]],
      toDate: ['', [Validators.required]],
    })
    this.userName = this.ls.get('userName');
    this.userId = this.ls.get('userId');
    this.getCreditScore(this.userId);

  }

  getCreditScore(userId) {
    this.service.getProductId(userId).subscribe(res => {
      if (res.message == 'success') {
        if (res.data.length > 0 && res.data[0].productId) {
          this.loadCreditScore(userId, res.data[0].productId)
        } else {
          this.nograph = true;
          this.showgraph = false;
          this.messageNoCustomer = "No Data Available! Please purchase your plan"
        }
      }
    })
  }

  loadCreditScore(userId, productId) {
    this.prod = this.ls.set('prodId', productId)
    this.service.getCreditReport(userId, productId).subscribe(res => {
      if (res.message == 'success' && res.data.reports[0].report.finalReport[0].reportDetails.GetCustomerAssetsResponse.GetCustomerAssetsSuccess) {
        this.nograph = false;
        this.showgraph = true;
        if (res.data.reports[0].report.finalReport[0].reportDetails.GetCustomerAssetsResponse.GetCustomerAssetsSuccess.Asset.TrueLinkCreditReport.TradeLinePartition) {
          this.creditList = res.data.reports[0].report.finalReport[0].reportDetails.GetCustomerAssetsResponse.GetCustomerAssetsSuccess.Asset.TrueLinkCreditReport.TradeLinePartition;
          this.search();
        } else {
          // this.validateCreditScore(0)
        }
      } else {
        this.messageNoCustomer = res.data.errorString
        this.nograph = true;
        this.showgraph = false;
        this.service.snackbar(res.data.errorString);
      }
    }, err => {
    });
  }

  fromDate() {
    this.tillDate = this.scoreTrend.value.fromDate;
  }

  submit() {
    if (this.scoreTrend.valid) {
      this.chartData = [];
      this.chartLabels = []
      let fromDate = this.scoreTrend.value.fromDate;
      let toDate = this.scoreTrend.value.toDate;
      this.startDate = this.datePipe.transform(fromDate, "yyyy-MM-dd");
      this.endDate = this.datePipe.transform(toDate, "yyyy-MM-dd");
      this.search()
    } else {
      this.service.snackbar("Fill all the fields");
    }
  }

  search() {
    let userid = this.ls.get('userId');
    let prodId = this.ls.get('prodId');
    console.log("user", userid, "prod", prodId, this.startDate, this.endDate)
    this.service.getScoreTrend(userid, prodId, this.startDate, this.endDate).subscribe(res => {
      console.log("123", res);
      this.graphResponse = res.data.reports[0].report.finalReport[0].reportDetails.GetCustomerAssetsResponse.GetCustomerAssetsSuccess.Asset.TrueLinkCreditReport.TradeLinePartition;
      this.finaldata = res.data.reports[0].report.finalReport[0].reportDetails.GetCustomerAssetsResponse.GetCustomerAssetsSuccess.Asset.TrueLinkCreditReport.Borrower.CreditScore.riskScore
      console.log("res12345", this.graphResponse)
      this.second = res.data.reports[0].report.finalReport[0].reportDetails.GetCustomerAssetsResponse.GetCustomerAssetsSuccess.Asset.TrueLinkCreditReport.TradeLinePartition;
      console.log("this.second", this.second)
      this.second.forEach(res => {
        console.log("res second", res)
        this.dates = this.datePipe.transform(res.date, "MMM yyyy");
        console.log("this.dates loop", this.dates)
        // this.chartLabels.push('')
        this.chartLabels.push(this.dates);
        console.log("this.chartLabels", this.chartLabels)
        this.lineChartLabels = this.chartLabels;
      })
      this.graphResponse.forEach(res => {
        let score = res.Tradeline;
        console.log(" this.chartData", this.chartData)
          this.chartData.push(this.finaldata);
          console.log(" this.chartData123123123", this.chartData)
          this.lineChartData[0].data = this.chartData;
          this.lineChartData = [{ data: [...this.chartData], label: 'Credit Score', fill: false, lineTension: 0, backgroundColor: "white", pointRadius: 4 }];
        // score.forEach(res => {
        //   let scoreReport = res.report;
        //   this.finaldata = scoreReport.rawReport.INProfileResponse.SCORE.BureauScore;
        //   this.chartData.push(0)
        //   console.log(" this.chartData", this.chartData)
        //   this.chartData.push(this.finaldata);
        //   console.log(" this.chartData123123123", this.chartData)
        //   this.lineChartData[0].data = this.chartData;
        //   this.lineChartData = [{ data: [...this.chartData], label: 'Credit Score', fill: false, lineTension: 0, backgroundColor: "white", pointRadius: 4 }];
        // })
      })
    })
  }

}