import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { LocalStorageService } from "ngx-localstorage";
import { scorocketService } from "src/app/providers/scorocket.service";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-personal-profile",
  templateUrl: "./personal-profile.component.html",
  styleUrls: ["./personal-profile.component.scss"]
})
export class PersonalProfileComponent implements OnInit {
  profileForm: FormGroup;
  profile: any;
  dob: any;
  showEdit: boolean = false;
  showClose: boolean = false;
  dateOfBirth: string;
  myTime;
  showOTPField = false;
  countdownExpired = false;
  dateOf: string;
  birthDate: string;
  constructor(
    public fb: FormBuilder,
    public ls: LocalStorageService,
    public service: scorocketService,
    public datepipe: DatePipe
  ) {}

  ngOnInit() {
    this.profileForm = this.fb.group({
      firstName: [
        "",
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(20),
          Validators.pattern("[a-zA-Z ]+")
        ]
      ],
      // userName: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(20)]],
      middleName: [""],
      lastName: [
        "",
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(20),
          Validators.pattern("[a-zA-Z ]+")
        ]
      ],
      DOB: [""],
      emailAddress: [
        "",
        [
          Validators.required,
          Validators.email,
          Validators.pattern("^[a-z0-9._-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
        ]
      ],
      mobileNumber: [
        "",
        [Validators.required, Validators.pattern("[0-9]\\d{9}")]
      ],
      address: [""],
      pincode: ["", [Validators.pattern("[0-9]\\d{5}")]],
      city: [""],
      pancard: [
        "",
        [
          Validators.required,
          Validators.pattern("^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$")
        ]
      ],
      otp: ["", [Validators.required, Validators.pattern("[0-9]\\d{4}")]]
    });
    this.profileData();
  }
  profileData() {
    const userId = this.ls.get("userId");
    console.log('id',userId)
    this.service.getProfile(userId).subscribe(res => {
      console.log("res123", res);
      this.profile = res.data;
      this.dob = res.data.DOB;
      this.ls.set("firstName",res.data.name.first)
      this.ls.set("lastName",res.data.name.last)
      this.ls.set("mobile",res.data.phone[0].number)
      const date = new Date(this.dob);
      console.log("date", date);
      this.dateOfBirth = this.datepipe.transform(date, "dd-MM-yyyy");
      this.dateOf = date.toLocaleDateString();
      console.log(" this.dateOfBirth", this.dateOfBirth);
    });
  }
  editProfile() {
    this.MappingToEdit();
    this.showEdit = true;
    this.showClose = true;
  }

  closeEdit() {
    this.showEdit = false;
    this.showClose = false;
    this.showOTPField = false;
    this.clearTimer();
    this.profileForm.patchValue({ otp: "" });
  }

  MappingToEdit() {
    console.log("asfasasf", this.dob);
    const patchData = {
      firstName: this.profile.name.first,
      lastName: this.profile.name.last,
      DOB: this.dateOfBirth,
      emailAddress: this.profile.email,
      mobileNumber: this.profile.phone[0].number,
      address: this.profile.address[0].type,
      pincode: this.profile.address[0].zip,
      city: this.profile.address[0].city,
      pancard: this.profile.socialIds[0].ID
    };
    console.log("patchData", patchData);
    this.profileForm.patchValue(patchData);
  }

  getOTP() {
    if (this.profileForm.value.mobileNumber) {
      const payload = {
        userReference: "+91" + this.profileForm.value.mobileNumber,
        purpose: "edit_profile"
      };
      this.service.getOTP(payload).subscribe(
        res => {
          if (res.message === "success") {
            this.showOTPField = true;
            // this.router.navigate(["/login"], {
            //   queryParams: {
            //     replaceUrl: true,
            //     otpExpiration: res.data.expiration,
            //     userRef: this.profileForm.value.mobileNumber
            //   }
            // });
            this.resetTimer(res.data.expiration);
            // this.showOTP = true;
            // this.loginPassword = false;
            // this.showPhone = false;
          }
        },
        err => {
          if (err.status === 500) {
            this.service.snackbar("Invalid credential!", "close");
          } else if (err.status == 0) {
            this.service.snackbar("ERR_CONNECTION_TIMED_OUT!", "close");
          }
        }
      );
    } else {
      this.service.snackbar("Please fill the required fields!", "close");
    }
  }

  clearTimer = () => {
    this.myTime = {
      left: 0,
      format: "mm:ss"
    };
  };

  resetTimer = date => {
    this.myTime = {
      stopTime: new Date(date).getTime(),
      format: "mm:ss"
    };
  };
  update() {
    const check = this.profileForm.value.DOB;
    console.log("check", check);
    if(check){
    const splitfun = check.split("-");
    let dobField = new Date(
      parseInt(splitfun[2]),
      parseInt(splitfun[1]) - 1,
      parseInt(splitfun[0])
    );
    this.birthDate = dobField.toISOString();
    dobField.setUTCHours(24, 0, 0, 0);
    console.log("dob 124", dobField.toISOString());
    console.log("dob", splitfun);
    } else{
      this.birthDate = ''
    }
    const name = {
      first: this.profileForm.value.firstName,
      middle: this.profileForm.value.middleName,
      last: this.profileForm.value.lastName
    };
    console.log("name", name);
    const address = [];
    const addPayload = {
      type: this.profileForm.value.address,
      city: this.profileForm.value.city,
      zip: this.profileForm.value.pincode
    };
    address.push(addPayload);
    const pancard = [];
    const pancardPayload = {
      ID: this.profileForm.value.pancard.toUpperCase()
    };
    const phone = [];
    const phonePayload = {
      dialCode: "+91",
      number: this.profileForm.value.mobileNumber
    };
    console.log("number", phonePayload);
    phone.push(phonePayload);
    pancard.push(pancardPayload);
    const id = this.ls.get("userId");
    const payload = {
      name: name,
      DOB: this.birthDate,
      email: this.profileForm.value.emailAddress,
      address: address,
      socialIds: pancard,
      phone: phone
      // userName: this.profileForm.value.userName
    };

    console.log("[Validators.required", this.profileForm.value);
    if (this.profileForm.valid) {
      this.service
        .editProfile(id, payload, this.profileForm.value.otp)
        .subscribe(
          res => {
            if (res.message === "success") {
              this.clearTimer();
              this.profileForm.patchValue({ otp: "" });
              console.log("res", res);
              this.profileData();
              this.service.snackbar(
                "Profile has been edited successfully",
                "close"
              );
              this.showOTPField = false;
              this.showEdit = false;
              this.showClose = false;
              console.log("edited successfully");
            }
          },
          err => {
            this.service.snackbar("Incorrect OTP", "close");
          }
        );
    } else {
      this.service.snackbar("Please fill the fields", "close");
      console.log("fill the required form edit");
    }
  }

  handleEvent(e) {
    console.log("expired", e);
    if (e.text === "00:00") {
      this.countdownExpired = true;
    } else {
      this.countdownExpired = false;
    }
  }
}
