import { Component, OnInit } from "@angular/core";
import { scorocketService } from "src/app/providers/scorocket.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit {
  toggle = false;
  constructor(public service: scorocketService, public router: Router) {}

  ngOnInit() {}
  register() {
    this.router.navigate(["/register"]);
  }
}
