import { Component, OnInit } from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { scorocketService } from "src/app/providers/scorocket.service";
import { Router, ActivatedRoute } from "@angular/router";
import { LocalStorageService } from "ngx-localstorage";
import { JwtHelperService } from "@auth0/angular-jwt";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loginFormOTP: FormGroup;
  formOTP: FormGroup;
  hide = true;
  loginPassword: boolean = true;
  showPhone: boolean = false;
  showOTP: boolean = false;
  userRef;
  countdownExpired: boolean;
  disableLogin: boolean = false;
  role: string;
  plans: any;
  constructor(
    public fb: FormBuilder,
    private service: scorocketService,
    private router: Router,
    private ls: LocalStorageService,
    private activatedRoute: ActivatedRoute
  ) {}

  myTime;

  ngOnInit() {
    this.loginForm = this.fb.group({
      userReference: [""],
      password: [""]
    });
    this.loginFormOTP = this.fb.group({
      userReference: [""]
    });
    this.formOTP = this.fb.group({
      otp: [""]
    });
    this.ls.set("token", "");
    this.ls.set("userName", "");
    this.ls.set("userId", "");
    this.service.isLogged = false;
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.otpExpiration) {
        if (new Date(params.otpExpiration) > new Date()) {
          this.resetTimer(params.otpExpiration);
          this.showOTP = true;
          this.loginPassword = false;
          this.showPhone = false;
        } else {
          this.clearTimer();
        }
      } else {
        this.showOTP = false;
        this.loginPassword = true;
        this.showPhone = false;
      }
      if (params.userRef) {
        this.userRef = params.userRef;
      }
      if (params.plan){
        this.plans = params.plan
        console.log('plan',this.plans)
      }
    });
    this.ls.remove("token")
    this.ls.remove("userName")
    this.ls.remove("userId")
    // this.ls.clear();
    this.redirect()
  }

  clearTimer = () => {
    this.myTime = {
      left: 0,
      format: "mm:ss"
    };
  };

  loginOTP() {
    // this.showOTP = false;
    // this.loginPassword = false;
    // this.showPhone = true;
    this.router.navigate(["/register"])
  }

  loginWthPassword() {
    this.router.navigate(["/login"]);
    this.showOTP = false;
    this.loginPassword = true;
    this.showPhone = false;
  }

  handleEvent(e) {
    console.log("expired", e);
    if (e.text === "00:00") {
      this.countdownExpired = true;
    }
  }
  navigateToComponent1() {
    this.router.navigate(['/user']);
  }

  login() {
    if (this.loginForm.value.userReference && this.loginForm.value.password) {
      const data = this.loginForm.value;
      this.disableLogin = true;
      this.service.login(data).subscribe(
        res => {
          if (res.message == "success") {
            this.disableLogin = false;
            console.log("res.data.jwt", res.data.jwt);
            if (res.data.jwt) {
              this.ls.set("token", res.data.jwt);
              const helper = new JwtHelperService();
              const decoded = helper.decodeToken(res.data.jwt);
              console.log("decoded", decoded);
              this.service.isLogged = true;
              this.ls.set("userName", decoded.name.first);
              this.ls.set("userId", decoded.userId);
              this.ls.set("userEmail", decoded.email);
              this.ls.set("referralCode", decoded.referralCode);
              this.ls.set("role", decoded.role);
              console.log("useId", decoded.userId);
              console.log("role", decoded.role);
              this.role = this.ls.get("role");
              console.log("useId", decoded.userId);
              console.log("role", decoded.role);
              if (decoded.role == "advisor") {
                this.router.navigate(["/advisory"]);
              } else if (decoded.role == "customer") {
                this.navigateToComponent1();
              } else if (decoded.role == "admin") {
                this.router.navigate(["/admin"]);
              } else if (this.role == "analyst") {
                this.router.navigate(["/analyst"]);
              }
            }
          }
        },
        err => {
          this.disableLogin = false;
          console.log("err", err);
          if (err.error.message.includes("Invalid Username")) {
            this.service.snackbar("Invalid Username!", "close");
            return;
          }
          if (err.error.message.includes("Invalid Password")) {
            this.service.snackbar("Invalid Password!", "close");
          }
          if (err.status == 408) {
            this.service.snackbar(
              "Connection timed out! Try again later",
              "close"
            );
          }
          if (err.status == 403) {
            this.service.snackbar(err.error.message, "close");
          } else if (err.status == 0) {
            this.service.snackbar("ERR_CONNECTION_TIMED_OUT");
          }
        }
      );
    } else {
      this.service.snackbar("Please fill the required fields!", "close");
    }
  }

  redirect(){
    this.activatedRoute.queryParams.subscribe(params => {
      if(params.token){
        const token = params.token;
        this.ls.set("token",token)
        const helper = new JwtHelperService();
        const decoded = helper.decodeToken(token);
        this.service.isLogged = true;
        this.ls.set("userName", decoded.name.first);
        this.ls.set("userId", decoded.userId);
        this.ls.set("userEmail", decoded.email);
        this.ls.set("referralCode", decoded.referralCode);
        this.ls.set("role", decoded.role);
        this.role = this.ls.get("role");
        if (decoded.role == "customer") {
          this.router.navigate(["/user"]);
        }
      }
    })
  }

  resetTimer = date => {
    this.myTime = {
      stopTime: new Date(date).getTime(),
      format: "mm:ss"
    };
  };

  getOTP() {
    if (this.loginFormOTP.value.userReference) {
      const payload = {
        userReference: "+91" + this.loginFormOTP.value.userReference,
        purpose: "login_otp"
      };
      this.service.getOTP(payload).subscribe(
        res => {
          if (res.message === "success") {
            this.router.navigate(["/login"], {
              queryParams: {
                replaceUrl: true,
                otpExpiration: res.data.expiration,
                userRef: this.loginFormOTP.value.userReference
              }
            });
            this.resetTimer(res.data.expiration);
            this.showOTP = true;
            this.loginPassword = false;
            this.showPhone = false;
          }
        },
        err => {
          if (err.status === 500) {
            this.service.snackbar("Invalid credential!", "close");
          } else if (err.status == 0) {
            this.service.snackbar("ERR_CONNECTION_TIMED_OUT!", "close");
          }
        }
      );
    } else {
      this.service.snackbar("Please fill the required fields!", "close");
    }
  }

  loginwithOTP() {
    if (this.formOTP.value.otp) {
      const payload = {
        loginType: "login_otp",
        userReference: "+91" + this.userRef,
        otp: this.formOTP.value.otp
      };
      this.service.login(payload).subscribe(
        res => {
          if (res.message == "success") {
            this.router.navigate(["/user"]);
            console.log("res.data.jwt", res.data.jwt);
            if (res.data.jwt) {
              this.ls.set("token", res.data.jwt);
              const helper = new JwtHelperService();
              const decoded = helper.decodeToken(res.data.jwt);
              console.log("decoded", decoded);
              this.service.isLogged = true;
              this.ls.set("userName", decoded.name.first);
              this.ls.set("userId", decoded.userId);
              this.ls.set("userEmail", decoded.email);
              this.ls.set("referralCode", decoded.referralCode);
              this.ls.set("role", decoded.role);
              console.log("useId", decoded.userId);
              console.log("role", decoded.role);
              this.role = this.ls.get("role");
              console.log("useId", decoded.userId);
              console.log("role", decoded.role);
              if (this.role == "advisor") {
                this.router.navigate(["/advisory"]);
              } else if (this.role == "customer") {
                this.navigateToComponent1();
              } else if (this.role == "admin") {
                this.router.navigate(["/admin"]);
              } else if (this.role == "analyst") {
                this.router.navigate(["/analyst"]);
              }
            }
          }
        },
        err => {
          if (err.error.message.includes("Invalid Username")) {
            this.service.snackbar("Invalid Username!", "close");
            return;
          }
          if (err.error.message.includes("Invalid Password")) {
            this.service.snackbar("Invalid Password!", "close");
          }
          if (err.status == 403) {
            this.service.snackbar("Invalid or Expired Login OTP!", "close");
          }
        }
      );
    } else {
      this.service.snackbar("Please fill the required fields!", "close");
    }
  }
  resendOTP() {
    const payload = {
      userReference: "+91" + this.userRef,
      purpose: "set_password"
    };
    this.service.getOTP(payload).subscribe(res => {
      if (res.message == "success") {
        console.log("res.data.expiration", res.data.expiration);
        this.router.navigate(["/login"], {
          replaceUrl: true,
          queryParams: {
            otpExpiration: res.data.expiration
          }
        });
        this.showOTP = true;
        this.loginPassword = false;
        this.showPhone = false;
      }
    });
  }
}
