import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { scorocketService } from "src/app/providers/scorocket.service";
import { LocalStorageService } from "ngx-localstorage";

@Component({
  selector: 'app-total-account',
  templateUrl: './total-account.component.html',
  styleUrls: ['./total-account.component.scss']
})
export class TotalAccountComponent implements OnInit {
  myDate = new Date();
  accountTotal: string;
  successaccountTotal: string;
  dangeraccountTotal: string;
  warningaccountTotal: string;
  noinfoaccountTotal: string;
  noCreditReport: boolean;
  messageNoCustomer: string;
  showCreditReport: boolean;
  accounts: any;
  userId: string;

  constructor(public router:Router, public ls: LocalStorageService,  public service: scorocketService) { }

  ngOnInit() {
    this.userId = this.ls.get("userId");
    this.getCreditScore(this.userId);
    const scorefactor  = JSON.parse(this.ls.get('scoredata'));
    console.log ('14222',scorefactor.data1),
    this.accountTotal = scorefactor.data1
    this.validateCreditScore(scorefactor.data1)
  }

  goBack(){
    this.router.navigateByUrl('user/(page:scorefactor)');
  }

  validateCreditScore(e) {
    if(e == "" || e == undefined)
    {
      this.dangeraccountTotal = "state-danger";
      this.warningaccountTotal = "state-warning";
      this.successaccountTotal = "state-success";
      this.noinfoaccountTotal = "state-dark-selected";
    }else if (e >= 1 && e <= 2) {
      this.dangeraccountTotal = "state-danger-selected";
      this.warningaccountTotal = "state-warning";
      this.successaccountTotal = "state-success";
      this.noinfoaccountTotal = "state-dark";
    } else if (e >= 3 && e <= 5) {
      this.dangeraccountTotal = "state-danger";
      this.warningaccountTotal = "state-warning-selected";
      this.successaccountTotal = "state-success";
      this.noinfoaccountTotal = "state-dark";
    } else if (e >= 6) {
      this.dangeraccountTotal = "state-danger";
      this.warningaccountTotal = "state-warning";
      this.successaccountTotal = "state-success-selected";
      this.noinfoaccountTotal = "state-dark";
    }
  }

  getCreditScore(userId) {
    this.service.getProductId(userId).subscribe(res => {
      if (res.message == "success") {
        if (res.data.length > 0 && res.data[0].productId) {
          this.loadCreditScore(userId, res.data[0].productId);
        } else {
          this.noCreditReport = true;
          this.noCreditReport = false;
          this.messageNoCustomer =
            "No Data Available! Please purchase your plan";
        }
      }
    });
  }

  loadCreditScore(userId, productId) {
    this.service.getCreditReport(userId, productId).subscribe(
      res => {
        if (res.message == "success") {
          this.noCreditReport = false;
          this.showCreditReport = true;
          if (res.data.reports[0].report.finalReport[0].reportDetails.GetCustomerAssetsResponse.GetCustomerAssetsSuccess.Asset.TrueLinkCreditReport.TradeLinePartition) {
            this.accounts = res.data.reports[0].report.finalReport[0].reportDetails.GetCustomerAssetsResponse.GetCustomerAssetsSuccess.Asset.TrueLinkCreditReport.TradeLinePartition      
          } else {
          }
        } else {
          this.messageNoCustomer = res.data[0].errorString;
          this.noCreditReport = true;
          this.showCreditReport = false;
          this.service.snackbar(res.data[0].errorString);
        }
      },
      err => { }
    );
  }

}
