import { Component, OnInit } from "@angular/core";
import { scorocketService } from "src/app/providers/scorocket.service";
import { LocalStorageService } from "ngx-localstorage";
import { cloneDeep } from "lodash";
import {PipeTransform,Pipe} from '@angular/core';

@Component({
  selector: "app-loan-account",
  templateUrl: "./loan-account.component.html",
  styleUrls: ["./loan-account.component.scss"]
})

export class LoanAccountComponent implements OnInit {
  userId: any;
  noCreditReport: boolean;
  showCreditReport: boolean;
  messageNoCustomer: string;
  loanList: any;
  fileToUpload: any = null;
  showFileUpload: boolean = false;
  fileUploadErrorMessage: string;
  report: any;
  editableloanData = [];
  monthlyPayStatusArray = []
  loanDetails: any;
  accountType: any;
  // creditscore = creditscore;
  account = [13, 15, 17, 32, 33, 34];
  loanData: any;
  paymentHistory: any;
  reportDetails: any;
  month_dict ={
    "01":"JAN"
  }
  
  months = [
    {
      key: "JAN",
      value: "01"
    },
    {
      key: "FEB",
      value: "02"
    },
    {
      key: "MAR",
      value: "03"
    },
    {
      key: "APR",
      value: "04"
    },
    {
      key: "MAY",
      value: "05"
    },
    {
      key: "JUN",
      value: "06"
    },
    {
      key: "JUL",
      value: "07"
    },
    {
      key: "AUG",
      value: "08"
    },
    {
      key: "SEP",
      value: "09"
    },
    {
      key: "OCT",
      value: "10",
      data: ""
    },
    {
      key: "NOV",
      value: "11",
      data: ""
    },
    {
      key: "DEC",
      value: "12"
    }
  ];
  productid: any;
  fullName: any;
  openedDate: any;
  reportDate: string;
  constructor(public ls: LocalStorageService, public service: scorocketService) {}

  ngOnInit() {
    this.userId = this.ls.get("userId");
    this.getCreditScore(this.userId);
    // console.log('afasfas',this.creditscore.report.finalReport)
    // this.loanData = this.creditscore.report.finalReport;
  }

  getCreditScore(userId) {

    this.service.getProductId(userId).subscribe(res => {
      if (res.message == "success") {
        if (res.data) {
          this.productid = res.data[0].productId;
          this.loadCreditScore(userId);
        } else {
          this.noCreditReport = true;
          this.showCreditReport = false;
          this.messageNoCustomer =
            "No Data Available! Please purchase your plan";
        }
      }
    });
  }
  isArray(value: any): boolean {
    return Array.isArray(value);
  }
   formatDate(inputDate) {
    if (!inputDate) {
      return '';
    }
  
    // Parse the input date string (considering the provided format)
    const date = new Date(inputDate.replace(/(\d{4}-\d{2}-\d{2}).*/, '$1'));
  
    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }
  
    // Format the date as dd-MM-yyyy
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
  
    return `${day}-${month}-${year}`;
  }

  changeDisputeState = i => {
    this.loanData[i].Tradeline["dispute"] = !this.loanData[i].Tradeline["dispute"];
    if (!this.loanData[i].Tradeline["dispute"]) {
      this.loanData[i].Tradeline["edit"] = false;
      this.fileToUpload = "";
      this.loanData[i].Tradeline["diputeType"] = "";
      var ele = document.getElementsByName("choice" + i);
      for (var j = 0; j < ele.length; j++)
        (<HTMLInputElement>ele[j]).checked = false;
    }
  };

  changeFieldsToEditable = (i, value, selectedDispute, showUpload) => {
    console.log(this.loanData[i],'ttt')
    this.showFileUpload = showUpload;
    this.loanData[i].Tradeline["edit"] = value;
    this.loanData[i].Tradeline["diputeType"] = selectedDispute.target.value;
  };

  handleFileInput(files: FileList) {
    console.log(files);
    if (files.item(0).size > 1e6) {
      this.fileUploadErrorMessage = "File Size Should Not Be Greater Than 2 MB";
    } else if (files.item(0).size < 1e6) {
      this.fileUploadErrorMessage = "";
      this.fileToUpload = files.item(0);
    }
  }

  removeFile() {
    this.fileToUpload = "";
  }

  //deep clone
  clone = arr =>
    Array.from(arr, item => (Array.isArray(item) ? this.clone(item) : item));
  RowClicked() {
    console.log("row clicked");
  }
  transform(value: string): string {
    const date = new Date(value);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  sendDispute = i => {
    console.log('dissp')
    let hasEmptyFiled = false;
    let conflicFileds = {};
    let oldValues = {};
    let keys = Object.keys(this.editableloanData[i].Tradeline);
    let disputeObj = {
      emailId: localStorage.getItem("userEmail"),
      disputeType: this.loanData[i].Tradeline["diputeType"],
      bankName: this.loanData[i].Tradeline.creditorName,
      accountNumber: this.loanData[i].Tradeline.accountNumber,
      stage1Id: "1574545",
      stage2Id: "1534392",
      productId: this.productid
    };
    if (this.loanData[i].Tradeline.edit) {
      keys.forEach(key => {
        if (
          typeof this.editableloanData[i].Tradeline[key] == "string" ||
          typeof this.editableloanData[i].Tradeline[key] == "number"
        ) {
          if (
            this.editableloanData[i].Tradeline[key] !==
            this.loanData[i].Tradeline[key]
          ) {
            if (this.editableloanData[i].Tradeline[key] == "") {
              hasEmptyFiled = true;
            }
            conflicFileds[key] = this.editableloanData[i].Tradeline[key];
            oldValues[key] = this.loanData[i].Tradeline[key];
          }
        }
      });
      disputeObj["currentData"] = oldValues;
      disputeObj["disputedData"] = conflicFileds;
      console.log("disputeObj", disputeObj);
    }

    if (!hasEmptyFiled) {
      this.loanData[i].buttonText = "Sending...";
      this.service.sendDispute( disputeObj,localStorage.getItem("userId"),this.fileToUpload)
        .subscribe(
          res => {
            if (res.message == "success") {
              this.loanData[i]["buttonText"] = "Send Dispute";
              this.changeDisputeState(i);
              this.service.snackbar("Dispute Sent Successfully");
            }
          },
          err => {
            if (err) {
              this.service.snackbar(
                "An Error Occurred While Sending The Dispute"
              );
              this.loanData[i]["buttonText"] = "Send Dispute";
            }
          }
        );
    } else if (hasEmptyFiled) {
      this.service.snackbar("Edited Fileds Cannot Be Empty");
    }
  };
  borrowerDetails:any;
  loadCreditScore(userId) {
    this.service.getCreditReport(userId,this.productid).subscribe(
      res => {
        if (res.message == "success") {
          this.noCreditReport = false;
          this.showCreditReport = true;
          this.loanData = res.data.reports[0].report.finalReport[0].reportDetails.GetCustomerAssetsResponse.GetCustomerAssetsSuccess.Asset.TrueLinkCreditReport.TradeLinePartition;
          this.borrowerDetails = res.data.reports[0].report.finalReport[0].reportDetails.GetCustomerAssetsResponse.GetCustomerAssetsSuccess.Asset.TrueLinkCreditReport.Borrower;

          for (var call of this.loanData) {
            console.log(call);
            var a = call.accountTypeSymbol;
            if (a == "07") {
              console.log('work1')
              this.noCreditReport = false;
              this.showCreditReport = true;
              break;
            } else {
              this.noCreditReport = true;
              this.showCreditReport = false;
              this.messageNoCustomer = "NO LOAN ACCOUNT AVAILABLE";
            }
          }

          this.loanData.forEach((res, index) => {
            this.reportDetails = res.Tradeline;
            this.paymentHistory = res.Tradeline.GrantedTrade.PayStatusHistory.MonthlyPayStatus
              if(!this.isArray(this.paymentHistory) && res.accountTypeSymbol == "07"){
                this.monthlyPayStatusArray.push(this.paymentHistory)
                this.monthlyPayStatusArray.forEach(res => {
                  res['Year'] = res.date.slice(0,4);
                  res['Month'] = res.date.slice(5,7)
                })
                let y;
              const sample = this.monthlyPayStatusArray.reduce((r, { Year }) => {
                const year = Year;
                if (!r.some(o => o.group == Year)) {
                  if (y != year) {
                    r.push({
                      Year,
                      groupItem: this.monthlyPayStatusArray.filter(v => v.Year == Year)
                    });
                  }
                  y = year;
                }
                return r;
              }, []);
              this.loanData[index]["PaymentYears"] = sample;
              }
               if(this.isArray(this.paymentHistory) && res.accountTypeSymbol == "07") {
                this.monthlyPayStatusArray = this.paymentHistory;
                this.monthlyPayStatusArray.forEach(res => {
                  res['Year'] = res.date.slice(0,4);
                  res['Month'] = res.date.slice(5,7)
                })
                let y;
              const sample = this.monthlyPayStatusArray.reduce((r, { Year }) => {
                const year = Year;
                if (!r.some(o => o.group == Year)) {
                  if (y != year) {
                    r.push({
                      Year,
                      groupItem: this.monthlyPayStatusArray.filter(v => v.Year == Year)
                    });
                  }
                  y = year;
                }
                return r;
              }, []);
              this.loanData[index]["PaymentYears"] = sample;      
              }
            this.loanData.forEach(credit => {
              credit["dipute"] = false;
              credit["edit"] = false;
              credit["diputeType"] = "";
              credit["buttonText"] = "Send Dispute";
              const opendate = this.formatDate(credit.Tradeline.dateOpened)
              this.reportDate = this.formatDate(credit.Tradeline.dateReported)
              this.openedDate = opendate
            });
            
            this.editableloanData = cloneDeep(this.loanData);
          });
        } else {
          this.messageNoCustomer = res.data[0].errorString;
          this.noCreditReport = true;
          this.showCreditReport = false;
          this.service.snackbar(res.data[0].errorString);
        }
      },
      err => {}
    );
  }

  checkIfArray = data => {
    return Array.isArray(data);
  };

  chkValue(val, data) {
    let retrunValue = "";
    data.groupItem.some(el => {
      if (JSON.stringify(el.Month) == JSON.stringify(val)) {
        retrunValue = '1';
      }
    });
    return retrunValue;
  }
}
