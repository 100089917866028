import { Component, OnInit } from "@angular/core";
import { ExternalLibraryService } from "../../providers/util";
import { never } from "rxjs";
import { LocalStorageService } from "ngx-localstorage";
import { scorocketService } from "src/app/providers/scorocket.service";
declare let Razorpay: any;
@Component({
  selector: "app-plan",
  templateUrl: "./plan.component.html",
  styleUrls: ["./plan.component.scss"]
})
export class PlanComponent implements OnInit {
  plans: {
    id: number;
    name: string;
    features: string[];
    price: string;
    btnTitle: string;
    disabled: boolean;
  }[];
  response;
  razorpayResponse;
  showModal = false;
  RAZORPAY_OPTIONS = {
    key: "",
    amount: "",
    name: "scorocket",
    order_id: "",
    description: "Pro plan",
    image:
      "https://livestatic.novopay.in/resources/img/nodeapp/img/Logo_NP.jpg",
    prefill: {
      name: "",
      email: "abhilash@comorin.co",
      contact: "",
      method: ""
    },
    modal: {},
    theme: {
      color: "#44a0de"
    }
  };
  userInfo: { userName: any; userId: any };

  constructor(
    private razorpayService: ExternalLibraryService,
    private ls: LocalStorageService,
    private service: scorocketService
  ) {}

  ngOnInit() {
    this.plans = [
      {
        id: 0,
        name: "Pre",
        features: [
          "Online support",
          "Full Credit Report",
          "Credit advise",
          "90 days dashboard access"
        ],
        price: "",
        btnTitle: "Coming Soon",
        disabled: true
      },
      {
        id: 1,
        name: "Pro",
        features: [
          "Toll Free Support",
          "Personalised Credit Advise",
          "12 Months Credit Repair Program",
          "Raise Dispute"
        ],
        price: "",
        btnTitle: " Buy Now",
        disabled: false
      },
      {
        id: 2,
        name: "Plus",
        features: [
          "Dedicated Credit Advisor",
          "Lifetime Credit Repair Program",
          "Credit Payment Negotiation",
          "Score Improvement Gurantee"
        ],
        price: "",
        btnTitle: "Coming Soon",
        disabled: true
      }
    ];
    this.razorpayService
      .lazyLoadLibrary("https://checkout.razorpay.com/v1/checkout.js")
      .subscribe();
  }

  public proceed(dsiabled) {
    if (!dsiabled) {
      this.service.snackbar("connecting to razorpay...", "close", 60000);
      this.userInfo = {
        userName: this.ls.get("userName"),
        userId: this.ls.get("userId")
      };
      if (!this.userInfo.userId) {
        this.service.snackbar("Please login to purchase!");
        return;
      }
      const payload = {
        userId: this.userInfo.userId,
        productId: "5e1dee30b88f99188e79523f",
        paymentPartner: "razorpay"
      };
      this.service.buyNow(payload).subscribe(
        res => {
          if (res.message == "success") {
            this.service.dismissSnackbar();
            if (res.data.orderId && res.data.razorpay_order_id) {
              this.RAZORPAY_OPTIONS.order_id = res.data.razorpay_order_id;
              this.RAZORPAY_OPTIONS.prefill.email = this.ls.get("userEmail");
              this.RAZORPAY_OPTIONS.prefill.name = this.ls.get("userName");
              this.RAZORPAY_OPTIONS.amount = 0 + "00";

              // binding this object to both success and dismiss handler
              this.RAZORPAY_OPTIONS[
                "handler"
              ] = this.razorPaySuccessHandler.bind(this);

              // this.showPopup();
              let razorpay = new Razorpay(this.RAZORPAY_OPTIONS);
              razorpay.open();
            }
          }
        },
        err => {
          this.service.dismissSnackbar();
          this.service.snackbar(
            "Failed to process, please try after sometime!"
          );
        }
      );
    }
  }

  public razorPaySuccessHandler(response) {
    console.log(response);
    this.razorpayResponse = `Razorpay Response`;
    this.showModal = true;
    // document.getElementById('razorpay-response').style.display = 'block';
  }
}
