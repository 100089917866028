import { Component, HostListener } from '@angular/core';
import { Router,NavigationEnd  } from '@angular/router';
import { scorocketService } from './providers/scorocket.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'scorocket';
  charts: Chart[];
  isHomePage: boolean = false;
  constructor(public router: Router, public service: scorocketService) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isHomePage = this.router.url === '/home';
      }
    });
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (window.pageYOffset && window.pageYOffset > 10) {
      this.service.scrollActive = true;
    } else {
      this.service.scrollActive = false;
    }
  }
  

}
