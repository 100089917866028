import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { scorocketService } from "src/app/providers/scorocket.service";
import { DatePipe } from "@angular/common";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { LocalStorageService } from "ngx-localstorage";
import { JwtHelperService } from "@auth0/angular-jwt";

export interface IDialogData {
  dates: {
    start: Date;
    end: Date;
  };
}
@Component({
  selector: "app-admin-all-customers",
  templateUrl: "./admin-all-customers.component.html",
  styleUrls: ["./admin-all-customers.component.scss"]
})
export class AdminAllCllCustomersComponent implements OnInit {
  nonEditableFields = false;
  search: FormGroup;
  // searchUsers;
  productId: any;
  payload: { state: string; reason: string };
  nodataMessage: string;
  showUsers: boolean;
  reloading: boolean;
  show: boolean;
  sortOrder = "asc";
  showasc: boolean;
  showdsc: boolean;
  customerData: any;
  score: any;
  constructor(public service: scorocketService) {}

  ngOnInit() {
    this.getUsers();
    this.checkRole();
  }

  checkRole = () => {
    const helper = new JwtHelperService();
    const jwt = localStorage.getItem("token");
    const decoded = helper.decodeToken(jwt);
    const localRole = decoded.role;
    if (localRole == "advisor") {
      this.nonEditableFields = true;
    }
  };

  getUsers() {
    this.showasc = false;
    this.showdsc = false;
    this.sortOrder = "asc";
    let role = "customer";
    const searchText = "";
    this.service.getUsers(searchText, role).subscribe( res => {
      console.log("response for get user",res)
        if (res.message == "success") {
          this.showUsers = true;
          this.customerData = res.data.users;
          console.log("this.customerData",this.customerData.rat)
          this.customerData.forEach((customer)=>{
            const ratings =  customer.ratings_doc;
            if(ratings.length){
              ratings[ratings.length-1].reports.forEach(rating=>{
                this.score = rating.score
                customer['showScore'] = this.score
                console.log("res",customer)
              })
            }
          })
        }
      },
      err => {
        if ((err.message = "Search did not match any")) {
          this.showUsers = false;
          this.nodataMessage = "No data record matching name";
        }
      }
    );
  }

  getusers() {
    // this.upArrow = true;
    this.reloading = true;
    this.showasc = false;
    this.showdsc = false;
    this.sortOrder = "asc";
    let role = "customer";
    let searchText = "";
    this.service.getUsers(searchText, role).subscribe(
      res => {
        if (res.message == "success") {
          this.showUsers = true;
          this.reloading = false;
          this.customerData = res.data.users;
        }
      },
      err => {
        this.showUsers = false;
        this.reloading = false;
        this.nodataMessage = "No data record";
      }
    );
  }

  searchUsers(searchValue) {
    this.showasc = false;
    this.showdsc = false;
    this.sortOrder = "asc";
    if (searchValue == "" || searchValue == undefined || searchValue == null) {
      this.getusers();
    } else {
      this.service.getUsers(searchValue, "customer").subscribe(
        res => {
          if (res.message == "success") {
            this.showUsers = true;
            this.customerData = res.data.users;
          }
        },
        err => {
          if ((err.message = "Search did not match any")) {
            this.showUsers = false;
            this.nodataMessage = "No data record matching name";
          }
        }
      );
    }
  }
}
